/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useField } from "react-form";
import {
  Typeahead as ReactTypeahead,
  AsyncTypeahead as AsyncReactTypeahead
} from "react-bootstrap-typeahead";
import ReactRangeSlider from "react-bootstrap-range-slider";
import "./form.scss";
import { Button, Input } from "@axxes/design-system";
import { Subject } from "rxjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBold,
  faCode,
  faHeading,
  faItalic,
  faLink
} from "@fortawesome/free-solid-svg-icons";
import Markdown from "../Markdown";

interface FieldProps {
  name: string;
  label: string;
  type?: string;
  required?: boolean;
  validate?: (value: any) => any;
  validatePristine?: boolean;
  onChangeHandler?: (value: any) => void;
  placeholder?: string;
  defaultValue?: string;
  disabled?: boolean;
  multiline?: boolean;
  changeValue?: Subject<string>;
  className?: string;
}

const Field: React.FunctionComponent<FieldProps> = ({
  name,
  label,
  type = "text",
  required = false,
  validate = () => {},
  validatePristine = false,
  onChangeHandler = (value) => value,
  defaultValue = null,
  disabled = false,
  multiline = false,
  className = "",
  changeValue = new Subject()
}) => {
  const {
    meta: { error, isTouched },
    getInputProps
  } = useField(name, { validate, validatePristine });

  const { onChange } = getInputProps();
  const [value, updateValue] = useState<string>("");

  useEffect(() => {
    const subscription = changeValue.subscribe((value: string) => {
      const e = { target: { value } };
      onChange(e);
      onChangeHandler(e);
      updateValue(value);
    });
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  });

  useEffect(() => {
    if (defaultValue) {
      updateValue(defaultValue);
      const e = { target: { value: defaultValue } };
      onChange(e);
      onChangeHandler(e);
    }
  }, [defaultValue]);

  return (
    <div className={`form__input-group ${className}`}>
      <Input
        title={label}
        warning={isTouched && error}
        required={required}
        name={name}
        value={value}
        type={type}
        disabled={disabled}
        multiline={multiline}
        onChange={(event) => {
          onChangeHandler(event);
          onChange(event);
          updateValue(event.target.value);
        }}
      />
      <div className="form__input-error">{isTouched && error}</div>
    </div>
  );
};

interface CheckboxProps {
  name: string;
  label: string;
  required?: boolean;
  validate?: (value: any) => boolean;
  validatePristine?: boolean;
  onChangeHandler?: (value: any) => void;
  defaultValue?: any;
  disabled?: boolean;
}

const Checkbox: React.FunctionComponent<CheckboxProps> = ({
  name,
  label,
  required = false,
  validate = () => {},
  validatePristine = false,
  onChangeHandler = (value) => value,
  defaultValue = "",
  disabled = false
}) => {
  const {
    meta: { error, isTouched },
    getInputProps
  } = useField(name, { validate, validatePristine });
  const { onChange } = getInputProps();
  return (
    <div className="form__input-group form__inline checkbox">
      <label htmlFor={name} className={`${disabled ? "disabled" : ""}`}>
        {label}{" "}
        <span className="form__input-required">{required ? "*" : ""}</span>
        <input
          className={`axxes-input__field__checkbox ${
            isTouched && error ? "invalid" : ""
          } ${disabled ? "disabled" : ""}`}
          type="checkbox"
          id={name}
          onChange={(event) => {
            onChangeHandler(event);
            onChange(event);
          }}
          checked={!!defaultValue}
          defaultValue={defaultValue}
          disabled={disabled}
        />
      </label>
      <div className="form__input-error">{isTouched && error}</div>
    </div>
  );
};

interface RangeSliderProps {
  name: string;
  label: string;
  required?: boolean;
  validate?: (value: any) => boolean;
  validatePristine?: boolean;
  onChangeHandler?: (value: any) => void;
  defaultValue?: number;
  max?: number;
  min?: number;
  disabled?: boolean;
}

const RangeSlider: React.FunctionComponent<RangeSliderProps> = ({
  name,
  label,
  required = false,
  validate = () => {},
  validatePristine = false,
  onChangeHandler = (value) => value,
  defaultValue = 0,
  max = 100,
  min = 0,
  disabled = false
}) => {
  const [value, setValue] = useState<number>(defaultValue);
  const {
    meta: { error, isTouched },
    getInputProps
  } = useField(name, { validate, validatePristine });
  const { onChange } = getInputProps();
  return (
    <div className="form__input-group form__inline range-slider">
      <label htmlFor={name} className={`${disabled ? "disabled" : ""}`}>
        {label}{" "}
        <span className="form__input-required">{required ? "*" : ""}</span>
        <ReactRangeSlider
          value={value}
          min={min}
          max={max}
          onChange={(event: any) => {
            onChange(event);
            onChangeHandler(event);
            setValue(event.target.value);
          }}
        />
      </label>
      <div className="form__input-error">{isTouched && error}</div>
    </div>
  );
};

interface Option {
  text: string;
  value: any;
}

interface TypeSelectorProps {
  name: string;
  label?: string;
  required?: boolean;
  validate?: (value: any) => boolean;
  validatePristine?: boolean;
  options: Option[];
  disabled?: boolean;
  defaultSelected?: string;
  onChangeHandler?: (value: any) => void;
}

const TypeSelector: React.FunctionComponent<TypeSelectorProps> = ({
  name,
  label,
  options,
  required,
  validate,
  validatePristine,
  defaultSelected,
  disabled,
  onChangeHandler = () => {}
}) => {
  const {
    meta: { error, isTouched },
    getInputProps
  } = useField(name, { validate, validatePristine });
  const [value, setValue] = useState(defaultSelected);

  const { onChange } = getInputProps();
  return (
    <div className="form__input-group">
      {label ? (
        <label htmlFor={name}>
          {label}{" "}
          <span className="form__input-required">{required ? "*" : ""}</span>
        </label>
      ) : null}
      <div
        className={`form__input-type-selector-options ${
          disabled ? "disabled" : ""
        }`}
      >
        {options.map((option) => (
          <button
            type="button"
            key={option.value}
            className={`axxes-button --color-accent ${
              value === option.value ? "selected" : ""
            }`}
            onClick={() => {
              if (!disabled) {
                onChange({ target: { value: option.value } });
                onChangeHandler(option.value);
                setValue(option.value);
              }
            }}
            disabled={disabled}
          >
            {option.text}
          </button>
        ))}
      </div>
      <div className="form__input-error">{isTouched && error}</div>
    </div>
  );
};

interface TextAreaProps {
  name: string;
  label: string;
  required?: boolean;
  validate?: (value: any) => boolean;
  validatePristine?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  placeholder?: string;
  onChangeHandler?: (value: any) => void;
}

const TextArea: React.FunctionComponent<TextAreaProps> = (props) => {
  return <Field {...props} multiline={true} />;
};

const markdownActions = [
  {
    label: <FontAwesomeIcon icon={faHeading} />,
    action: (value: string) => `${value ? value + "\n" : ""}# <H1>\n`
  },
  {
    label: <FontAwesomeIcon icon={faBold} />,
    action: (value: string) => `${value || ""} **bold_text** `
  },
  {
    label: <FontAwesomeIcon icon={faItalic} />,
    action: (value: string) => `${value || ""} *italic_text* `
  },
  {
    label: <FontAwesomeIcon icon={faLink} />,
    action: (value: string) =>
      `${value || ""} [LinkTextHere](http://fep.axxes.com) `
  },
  {
    label: <FontAwesomeIcon icon={faCode} />,
    action: (value: string) => " " + (value || "") + " `Highlight here`"
  }
];

const MarkdownArea: React.FunctionComponent<TextAreaProps> = ({
  defaultValue,
  onChangeHandler = () => {},
  ...props
}) => {
  const changeValue = new Subject<string>();
  const [value, setValue] = useState<string>(defaultValue || "");
  const [preview, togglePreview] = useState(false);
  return (
    <div className={`markdown-textarea ${preview ? "show-preview" : ""}`}>
      <div className="markdown-api">
        Check markdown api{" "}
        <a rel="noreferrer" target="_blank" href="https://commonmark.org/help/">
          here
        </a>
        .{" "}
        <code>
          If you want a separate paragraph the text should contain 2 new lines.
        </code>
      </div>
      <div className="markdown-header">
        <div className="markdown-actions">
          {markdownActions.map((action, index) => (
            <Button
              accent={true}
              key={`markdownaction_${index}`}
              customClasses={`markdownaction_${index} ${
                index === markdownActions.length - 1 ? "last" : ""
              }`}
              onClick={(event: any) => {
                event.preventDefault();
                changeValue.next(action.action(value));
              }}
            >
              {action.label}
            </Button>
          ))}
        </div>
        <div className="markdown-help">
          <a href="#" onClick={togglePreview.bind(this, !preview)}>
            {preview ? "editor" : "preview"}
          </a>
        </div>
      </div>
      {preview ? <Markdown>{value}</Markdown> : null}
      <Field
        className="markdown-field"
        defaultValue={value}
        changeValue={changeValue}
        multiline={true}
        onChangeHandler={({ target }) => {
          setValue(target.value);
          onChangeHandler({ target });
        }}
        {...props}
      />
    </div>
  );
};

interface DatePickerProps {
  name: string;
  label: string;
  required?: boolean;
  validate?: (value: any) => boolean;
  validatePristine?: boolean;
  selected?: Date | string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  onChangeHandler?: (value: any) => void;
}

const DatePicker: React.FunctionComponent<DatePickerProps> = ({
  name,
  label,
  required,
  validate,
  validatePristine,
  selected,
  minDate,
  maxDate,
  disabled = false,
  onChangeHandler = () => {}
}) => {
  const {
    meta: { error, isTouched },
    getInputProps
  } = useField(name, { validate, validatePristine });
  const [value, setValue] = useState<any>();

  const { onChange } = getInputProps();

  return (
    <div className="form__input-group axxes-input__container">
      <label htmlFor={name} className={`${disabled ? "disabled" : ""}`}>
        {label}{" "}
        <span className="form__input-required">{required ? "*" : ""}</span>
      </label>
      <div className="form__input-datepicker axxes-input__field">
        <ReactDatePicker
          dateFormat="dd/MM/yyyy"
          selected={value ?? selected}
          disabled={disabled}
          showMonthDropdown
          showYearDropdown
          minDate={minDate}
          maxDate={maxDate}
          onChange={(date) => {
            setValue(date);
            onChangeHandler(date);
            onChange({ target: { value: date } });
          }}
        />
      </div>
      <div className="form__input-error">{isTouched && error}</div>
    </div>
  );
};

interface TypeaheadOption {
  label: string;
  value: string;
}

interface TypeaheadProps {
  options?: TypeaheadOption[] | string[];
  maxVisible?: number;
  name: string;
  label?: string;
  renderMenu?: any;
  renderToken?: any;
  renderMenuItemChildren?: any;
  placeholder?: string;
  required?: boolean;
  multiple?: boolean;
  validate?: (value: any) => boolean;
  validatePristine?: boolean;
  clearButton?: boolean;
  allowNew?: boolean;
  paginate?: boolean;
  disabled?: boolean;
  labelKey?: string | ((department: any) => string);
  defaultSelected?: any[];
  mapper?: (value: any) => string;
  onChangeHandler?: (value: any) => void;
}

const Typeahead: React.FunctionComponent<TypeaheadProps> = ({
  options = [],
  maxVisible = 5,
  name,
  label,
  renderMenu,
  renderToken,
  renderMenuItemChildren,
  validate = () => {},
  required = false,
  placeholder = "Type",
  multiple = false,
  clearButton = false,
  allowNew = false,
  paginate = false,
  labelKey = "label",
  validatePristine = false,
  onChangeHandler = (v: any) => v,
  mapper = (v: any) => v,
  defaultSelected = [],
  disabled = false
}) => {
  const {
    meta: { error, isTouched },
    getInputProps
  } = useField(name, { validate, validatePristine });
  const [value, setValue] = useState(defaultSelected);
  const { onChange } = getInputProps();

  useEffect(() => {
    if (defaultSelected.length) {
      const result = defaultSelected.map(mapper);
      setValue(result);
      onChangeHandler(result);
      onChange({
        target: {
          value: result
        }
      });
    }
  }, []);

  return (
    <div className="form__input-group">
      <label htmlFor={name} className="app-label">
        {label}{" "}
        <span className="form__input-required">{required ? "*" : ""}</span>
      </label>
      <div
        className={`form__input-typeahead typeahead ${
          disabled ? "disabled" : ""
        }`}
      >
        <ReactTypeahead
          onChange={(value: any) => {
            const result = multiple
              ? value
                  .map((option: any) =>
                    option.customOption ? option.label : option
                  )
                  .map(mapper)
              : mapper(value && value[0]);
            onChangeHandler(result);
            onChange({
              target: {
                value: result
              }
            });
          }}
          options={options.slice(0, maxVisible)}
          placeholder={placeholder}
          multiple={multiple}
          id="typeahead"
          clearButton={clearButton}
          allowNew={allowNew}
          labelKey={labelKey}
          defaultSelected={value}
          disabled={disabled}
          paginate={paginate}
          renderMenu={renderMenu}
          renderToken={renderToken}
          renderMenuItemChildren={renderMenuItemChildren}
        />
      </div>
      <div className="form__input-error">
        {!value.length && isTouched && error}
      </div>
    </div>
  );
};

interface AsyncTypeaheadProps extends TypeaheadProps {
  onSearch: (query: string) => Promise<any[]>;
  filterBy?: (options?: any, props?: any) => boolean;
}

const AsyncTypeahead: React.FunctionComponent<AsyncTypeaheadProps> = ({
  maxVisible = 10,
  name,
  label,
  onSearch,
  renderMenu,
  validate = () => {},
  required = false,
  placeholder = "Type",
  multiple = false,
  clearButton = false,
  allowNew = false,
  paginate = false,
  labelKey = "label",
  validatePristine = false,
  onChangeHandler = (value) => value,
  mapper = (value) => value,
  disabled = false,
  defaultSelected = [],
  filterBy
}) => {
  const {
    meta: { error, isTouched },
    getInputProps
  } = useField(name, { validate, validatePristine });
  const { onChange } = getInputProps();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<any[]>([]);

  const search = (query: string) => {
    setLoading(true);
    onSearch(query).then((response: any[]) => {
      setOptions(response.map(mapper));
      setLoading(false);
    });
  };

  return (
    <div className="form__input-group">
      <label htmlFor={name} className="app-label">
        {label}{" "}
        <span className="form__input-required">{required ? "*" : ""}</span>
      </label>
      <div
        className={`form__input-typeahead typeahead ${
          disabled ? "disabled" : ""
        }`}
      >
        <AsyncReactTypeahead
          onChange={(value: any) => {
            const result = multiple
              ? value
                  .map((option: any) =>
                    option.customOption ? option.label : option
                  )
                  .map(mapper)
              : mapper(value && value[0]);
            onChangeHandler(result);
            onChange({
              target: {
                value: result
              }
            });
          }}
          isLoading={loading}
          onSearch={search}
          options={options.slice(0, maxVisible)}
          placeholder={placeholder}
          multiple={multiple}
          id="typeahead"
          prompText="Type to search..."
          clearButton={clearButton}
          allowNew={allowNew}
          labelKey={labelKey}
          disabled={disabled}
          paginate={paginate}
          defaultSelected={defaultSelected}
          renderMenu={renderMenu}
          filterBy={filterBy}
        />
      </div>
      <div className="form__input-error">{isTouched && error}</div>
    </div>
  );
};

interface FileUploadProps {
  name: string;
  label: string;
  required?: boolean;
  onChange: (value: any) => void;
}

const FileUpload: React.FunctionComponent<FileUploadProps> = ({
  name,
  label,
  required,
  onChange
}) => {
  const onChangeHandler = (event: any) => {
    onChange(event.target.files[0]);
  };
  return (
    <div className="form__input-group">
      <label htmlFor={name}>
        {label}{" "}
        <span className="form__input-required">{required ? "*" : ""}</span>
      </label>
      <div className="files">
        <input type="file" id={name} onChange={onChangeHandler} />
      </div>
    </div>
  );
};

export {
  Field,
  TextArea,
  TypeSelector,
  DatePicker,
  Typeahead,
  AsyncTypeahead,
  FileUpload,
  Checkbox,
  RangeSlider,
  MarkdownArea
};
