import {
  faTicketAlt,
  faBed,
  faPlane,
  faBuilding,
  faEllipsisH,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BookingTicketState, getBookingTypeLabel } from "../../domain/booking";
import { EducationTicketOptionRequirementType } from "../../domain/education";
import formatDate from "../../utils/date";

import "./ticket-administration-icon.scss";

interface TicketAdministrationIconProps {
  value: EducationTicketOptionRequirementType;
  state?: BookingTicketState;
  bookBefore?: Date;
}

const TicketAdministrationIcon: React.FunctionComponent<
  TicketAdministrationIconProps
> = ({ value, state, bookBefore }) => {
  const getIcon = () => {
    switch (value) {
      case "ENTRY":
        return <FontAwesomeIcon icon={faTicketAlt} />;
      case "ACCOMMODATION":
        return <FontAwesomeIcon icon={faBed} />;
      case "TRANSPORT":
        return <FontAwesomeIcon icon={faPlane} />;
      case "VENUE":
        return <FontAwesomeIcon icon={faBuilding} />;
      default:
        return <FontAwesomeIcon icon={faEllipsisH} />;
    }
  };

  return (
    <span
      className={`ticket-administration-icon ${state}`}
      title={`${getBookingTypeLabel(value)} ${state || ""} ${
        bookBefore ? formatDate(bookBefore) : ""
      }`}
    >
      {state === "ORDERED" ? <FontAwesomeIcon icon={faCheckCircle} /> : null}
      {getIcon()}
    </span>
  );
};

export default TicketAdministrationIcon;
