import {
  faCaretLeft,
  faCaretRight,
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Education } from "../../domain/education";
import { EducationTileItem } from "../EducationList";

import "./education-slider.scss";

const SLIDER_OFFSET = 4;

interface Props {
  educations: Education[];
}

const EducationSlider: React.FunctionComponent<Props> = ({ educations }) => {
  const [activePage, setActivePage] = useState(0);
  const pages = Array.from(
    Array(Math.ceil(educations.length / SLIDER_OFFSET)).keys()
  );

  const getEducations = () => {
    const offset = SLIDER_OFFSET * activePage;
    return educations.slice(offset, offset + SLIDER_OFFSET);
  };

  return (
    <div
      className={`education-slider ${
        educations.length > SLIDER_OFFSET ? "overflow" : ""
      }`}
    >
      <div className="educations">
        {pages.length > 1 ? (
          <div className="controls">
            <div
              className={`left-control ${activePage === 0 ? "disabled" : ""}`}
              onClick={setActivePage.bind(this, activePage - 1)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            <div
              className={`right-control ${
                activePage === pages.length - 1 ? "disabled" : ""
              }`}
              onClick={setActivePage.bind(this, activePage + 1)}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </div>
          </div>
        ) : null}
        {getEducations().map((education) => (
          <EducationTileItem key={education.uuid} education={education} />
        ))}
      </div>
      {pages.length > 1 ? (
        <div className="pages">
          {pages.map((page: number) => (
            <div
              key={page}
              onClick={setActivePage.bind(this, page)}
              className={`page ${activePage === page ? "active" : ""}`}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default EducationSlider;
