import React, { ReactElement } from "react";

import "./page-header.scss";
import headerImage from "./itisaboutpeople.jpg";

interface Props {
  children: ReactElement | ReactElement[];
  background?: any;
}

const PageHeader: React.FunctionComponent<Props> = ({
  children,
  background = headerImage
}) => {
  return (
    <header
      className="page-header"
      style={{
        backgroundImage: `url("${background || headerImage}")`
      }}
    >
      <div>{children}</div>
    </header>
  );
};

export default PageHeader;
