import React from "react";
import getChartBackgroundColors from "../../utils/charts";
import { Doughnut } from "react-chartjs-2";
import { EducationPlan, EducationPlanEducation } from "../../domain/education";

import "./education-chart.scss";

const COLORS = [
  "#E9531D",
  "#a93910",
  "#035D67",
  "#4B848E",
  "#90B6BB",
  "#1D242B",
  "#313D47",
  "#5B6770"
];

interface Props {
  educationPlan: EducationPlan;
}

const EducationChart: React.FunctionComponent<Props> = ({ educationPlan }) => {
  const credits = educationPlan.assignedCredits;
  const getChartData = (ep: EducationPlan) => {
    const totalSpendCredits = ep?.tickets
      .map((ec: EducationPlanEducation) => ec.credits)
      .reduce((prev, curr) => prev + curr, 0);

    return {
      labels: [...ep?.tickets.map((ec) => ec.educationName), "Not Spent"],
      datasets: [
        {
          label: "Axxes points",
          data: [
            ...ep?.tickets.map((ec) => ec.credits),
            credits - totalSpendCredits
          ],
          backgroundColor: getChartBackgroundColors(ep.tickets.length + 1),
          borderColor: []
        }
      ]
    };
  };

  return (
    <div className="current-education-plan__chart">
      <Doughnut
        width={500}
        height={500}
        options={{ maintainAspectRatio: false }}
        data={getChartData(educationPlan)}
      />
    </div>
  );
};

const EducationBarChart: React.FunctionComponent<Props> = ({
  educationPlan
}) => {
  const sum = educationPlan.tickets.reduce((prev, x) => prev + x.credits, 0);
  return (
    <div className="education-plan__barchart">
      <div className="chart">
        <span className="costzero">0</span>
        {sum < educationPlan.assignedCredits && (
          <span className="costfull">{educationPlan.assignedCredits}</span>
        )}
        {educationPlan.tickets.map((education, index) => (
          <div
            key={`${education.educationId}_${index}`}
            className="education"
            style={{
              width: `${
                (education.credits /
                  (sum > educationPlan.assignedCredits
                    ? sum
                    : educationPlan.assignedCredits)) *
                100
              }%`,
              backgroundColor: COLORS[index] ? COLORS[index] : "#FAE1D6",
              borderRadius:
                sum > educationPlan.assignedCredits &&
                index === educationPlan.tickets.length - 1
                  ? "0 10px 10px 0"
                  : undefined
            }}
          >
            <span className="cost">{education.credits}</span>
          </div>
        ))}
      </div>
      <div className="education-plan__barchart-legend">
        {educationPlan.tickets.map((education, index) => (
          <div className="education-legend">
            <div
              className="color"
              key={education.educationId}
              style={{
                backgroundColor: COLORS[index] ? COLORS[index] : "#FAE1D6"
              }}
            ></div>
            {education.educationName}
          </div>
        ))}
      </div>
    </div>
  );
};

export default EducationChart;
export { EducationBarChart };
