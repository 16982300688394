import { useEffect, useState } from "react";
import { useForm } from "react-form";
import { Booking } from "../../../domain/booking";
import { Field } from "../../Form";
import Modal from "../../Modal";

interface CloseModalProps {
  open: boolean;
  booking?: Booking;
  onClose: () => void;
  onComplete: (booking: Booking, poNumber?: string) => void;
}

const CloseModal: React.FC<CloseModalProps> = ({
  open,
  booking,
  onClose,
  onComplete
}) => {
  const [poNumber, setPoNumber] = useState<string>();

  useEffect(() => {
    setPoNumber(undefined);
  }, [booking?.id]);

  const closingForm = useForm({
    validatePristine: true,
    onSubmit: () => {}
  });

  return booking ? (
    <Modal
      open={open}
      title={`Close ${booking?.educationName}: ${booking?.ticketOption}`}
      confirmText="Close ticket"
      onClose={onClose}
      onSubmit={onComplete.bind(this, booking, poNumber)}
      completeDisabled={!poNumber}
    >
      <p>
        You are about to close the ticket option for{" "}
        <b>
          {booking?.educationName}: {booking?.ticketOption}
        </b>
      </p>
      <closingForm.Form>
        <Field
          name="poNumber"
          label="PO Number"
          required={true}
          onChangeHandler={({ target }) => setPoNumber(target.value)}
        />
      </closingForm.Form>
      <p>
        After closing, all stakeholders will be notified. <b>Are you sure?</b>
      </p>
    </Modal>
  ) : null;
};

export default CloseModal;
