import React from "react";
import ReactMarkdown from "react-markdown";

interface MarkdownProps {
  children: any;
  className?: string;
}

const Markdown: React.FunctionComponent<MarkdownProps> = ({
  children,
  className = ""
}) => (
  <ReactMarkdown className={`markdown-preview ${className}`}>
    {children}
  </ReactMarkdown>
);

export default Markdown;
