import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { ValidationError } from "yup";
import moment from "moment";

import { getEducationCost } from "../../services/education";
import { DatePicker, Field, TextArea } from "../Form";

import { EducationTicketOption, EducationTicketOptionRequirement } from "../../domain/education";
import { TicketOptionRequirements } from "../EducationRequirements";
import { set } from "lodash";

import "./ticket-option.scss";

const URL_REGEX = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?%#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/;
const positiveNumberValidation = yup.string().test("num", "Value must be a positive number", (val) => {
  if (!val) {
    return true;
  }
  return !Number.isNaN(val) && Number(val) >= 0;
});

const validation = yup.object().shape({
  name: yup.string().required("Name is required"),
  educationType: yup
    .mixed()
    .oneOf(["ONLINE_COURSE", "COURSE", "INTERNAL_COURSE", "CONFERENCE", "LICENCE"], "Education type is required")
    .required("Education type is required"),
  organisingDepartment: yup
    .string()
    .oneOf(
      ["DATA", "DOTNET", "HR", "INFRA", "JAVA", "OPENSOURCE", "PM", "TESTING", "FRONTEND"],
      "At least one department is required"
    )
    .required("At least one department is required"),
  departments: yup
    .array()
    .min(1, "At least one department is required")
    .required("At least one department is required")
    .of(
      yup
        .mixed()
        .oneOf(
          ["DATA", "DOTNET", "HR", "INFRA", "JAVA", "OPENSOURCE", "PM", "TESTING", "FRONTEND"],
          "At least one department is required"
        )
    ),
  description: yup.string().required("Description is required"),
  startDate: yup.date().nullable(),
  endDate: yup.date().nullable(),
  latestEnrollmentDate: yup
    .date()
    .nullable()
    .test("test_last_enrollment_date_is_correct", "", function (value) {
      // @ts-ignore
      const educationType = this.options.context.educationType;
      if (educationType === undefined) {
        return new ValidationError("Please select an education type", null, "latestEnrollmentDate");
      }
      if (["COURSE", "INTERNAL_COURSE", "CONFERENCE"].includes(educationType)) {
        return value === undefined
          ? new ValidationError(
              "Last enrollment date is required with this type of education",
              null,
              "latestEnrollmentDate"
            )
          : true;
      }
      return false;
    }),
  halfDays: positiveNumberValidation.required("Number of half days is required"),
  website: yup.string().test("match", "Not a valid url", (val) => {
    if (!val) return true;
    return !!val.match(URL_REGEX);
  }),
  cost: positiveNumberValidation.required("The base cost is required"),
  additionalCosts: positiveNumberValidation.nullable(),
  overruledApPrice: positiveNumberValidation,
  maxAttendees: positiveNumberValidation,
  image: yup.string().required("Image is required"),
  year: yup
    .number()
    .required("Year is required")
    .test("len", "Must be exactly 4 numbers", (val) => !!val && val.toString().length === 4)
    .test(
      "greaterThanOrEqualToYearNow",
      "Must be greater than or equal to " + new Date().getFullYear(),
      (val) => !!val && val >= new Date().getFullYear()
    )
});

const validate = (field: string, overrideValidation?: any, context?: object): ((value: any) => boolean) => {
  return (value: any): boolean => {
    try {
      if (overrideValidation) {
        overrideValidation.validateSync(value, { context });
      } else {
        yup.reach(validation, field).validateSync(value, { context });
      }
      return false;
    } catch (e) {
      return e && e.errors[0];
    }
  };
};

export const hasEntryRequirement = (tickets: string[] = []): boolean => {
  return !!tickets.find((requirement) => requirement === "ENTRY");
};

const TicketOption: React.FunctionComponent<{
  disableName?: boolean;
  update: (variant: any) => void;
  remove?: () => void;
  baseEducation: any;
  index: number;
  disableRemove?: boolean;
  usedNames?: string[];
  editingTicketOption?: EducationTicketOption;
  ticketOption?: EducationTicketOption;
}> = ({
  disableName = true,
  update,
  remove,
  baseEducation,
  disableRemove = false,
  index,
  usedNames = [],
  editingTicketOption,
  ticketOption
}) => {
  const [totalCost, setTotalCost] = useState(0);
  const [overruledAPAmount, setOverruledAP] = useState(ticketOption?.price.overruledApPrice);
  const [cost, setCost] = useState(ticketOption?.price?.cost);
  const [additionalCosts, setAdditionalCosts] = useState(ticketOption?.price?.additionalCosts || 0);
  const [halfDays, setHalfDays] = useState(ticketOption?.price?.halfDays);
  const [variantName, setVariantName] = useState(ticketOption?.name || "Standard");
  const [initial, setInitial] = useState<boolean>(true);
  const [startDate, setStartDate] = useState(ticketOption?.start);
  const [endDate, setEndDate] = useState(ticketOption?.end);
  const [registrationDeadline, setRegistrationDeadline] = useState(ticketOption?.registrationDeadline);
  const [overrule, setOverrule] = useState<boolean>(false);
  const [extraInformation, setExtraInformation] = useState<string>(ticketOption?.bookingInfo?.extraInfo || "");
  const [requirements, setRequirements] = useState<string[]>(
    (ticketOption?.bookingInfo?.tickets || []).map((requirement: EducationTicketOptionRequirement) => requirement.type)
  );
  const [bookBefore, setBookBefore] = useState(
    ticketOption?.bookingInfo?.tickets.find((requirement) => requirement.type === "ENTRY")
      ? ticketOption?.bookingInfo?.tickets.find((requirement) => requirement.type === "ENTRY")?.bookBefore
      : undefined
  );

  useEffect(() => {
    if (editingTicketOption) {
      setCost(editingTicketOption.price.cost);
      setAdditionalCosts(editingTicketOption.price.additionalCosts);
      setHalfDays(editingTicketOption.price.halfDays);
      setOverruledAP(editingTicketOption.price.overruledApPrice);
      setStartDate(editingTicketOption.start ? moment(editingTicketOption.start).toDate() : undefined);
      setEndDate(editingTicketOption.end ? moment(editingTicketOption.end).toDate() : undefined);
      setRegistrationDeadline(
        editingTicketOption.registrationDeadline ? moment(editingTicketOption.registrationDeadline).toDate() : undefined
      );
      setVariantName(editingTicketOption.name);
      setExtraInformation(editingTicketOption?.bookingInfo?.extraInfo || "");
      setRequirements(
        (editingTicketOption?.bookingInfo?.tickets || []).map(
          (requirement: EducationTicketOptionRequirement) => requirement.type
        )
      );
      setBookBefore(
        hasEntryRequirement(editingTicketOption?.bookingInfo?.tickets.map(({ type }) => type)) &&
          editingTicketOption?.bookingInfo?.tickets.find((requirement) => requirement.type === "ENTRY")?.bookBefore
          ? moment(
              editingTicketOption?.bookingInfo?.tickets.find((requirement) => requirement.type === "ENTRY")?.bookBefore
            ).toDate()
          : undefined
      );
    }
  }, [editingTicketOption?.name]);

  useEffect(() => {
    if (baseEducation.educationType === "COMPETENCE_CENTER") {
      setCost(0);
      setHalfDays(0);
    }
  }, [baseEducation.educationType]);

  const mapRequirements = (requirements: string[], bookBefore?: Date) =>
    requirements.map((requirement: string) => ({
      type: requirement,
      bookBefore: requirement === "ENTRY" ? bookBefore : undefined
    }));

  const updateVariant = (field: string, value: string | number | Date | any[] | undefined) => {
    if (field === "name" && initial) {
      return;
    }
    const object = {
      name: variantName,
      start: startDate,
      end: endDate,
      registrationDeadline,
      bookingInfo: {
        tickets: mapRequirements(requirements, bookBefore),
        extraInfo: extraInformation
      },
      price: {
        halfDays,
        additionalCosts,
        cost,
        credits: totalCost,
        overruledApPrice: overruledAPAmount
      }
    };
    set(object, field, value);
    update(object);
  };

  const updateBothVariantDates = (start: Date, end: Date) => {
    const object = {
      name: variantName,
      start,
      end,
      registrationDeadline,
      bookingInfo: {
        tickets: mapRequirements(requirements, bookBefore),
        extraInfo: extraInformation
      },
      price: {
        halfDays,
        additionalCosts,
        cost,
        credits: totalCost,
        overruledApPrice: overruledAPAmount
      }
    };
    update(object);
  };

  useEffect(() => {
    async function calculateTotalCost() {
      if (cost && (halfDays !== undefined || halfDays !== null) && Number(cost) >= 0 && Number(halfDays) >= 0) {
        const total = await getEducationCost(cost, Number(halfDays), additionalCosts);
        updateVariant("price.credits", total);
        setTotalCost(total);
      }
    }

    if (initial) {
      setInitial(false);
    } else {
      calculateTotalCost();
    }
  }, [cost, halfDays, additionalCosts]);

  const nameValidation = yup
    .string()
    .notOneOf(usedNames, "Ticket option name must be unique")
    .required("Name is required");

  return (
    <div className="education-variant">
      {editingTicketOption ? <h3 style={{ marginTop: "-25px" }}>Update {editingTicketOption.name}</h3> : null}
      <div style={{ display: "flex", alignItems: "center", marginBottom: "15px" }}>
        <h5 style={{ margin: "0" }}>Total cost: {overrule ? overruledAPAmount : totalCost} AP</h5>
        {disableRemove ? null : (
          <button className="axxes-button --color-delete action-button" onClick={remove} style={{ marginLeft: "15px" }}>
            Remove
          </button>
        )}
      </div>
      <div className="variant-price">
        {!disableName && !editingTicketOption ? (
          <Field
            name={`variantName${index}`}
            label="Variant Name"
            required={true}
            validate={validate("name", nameValidation)}
            defaultValue={variantName}
            onChangeHandler={({ target }) => {
              setVariantName(target.value);
              updateVariant("name", target.value);
            }}
          />
        ) : null}
        {baseEducation.educationType !== "LICENCE" ? (
          <>
            <div className="fields-next dates">
              <div className="app-label">
                <DatePicker
                  name={`startDateVariant${index}`}
                  label="Start Date"
                  validate={validate("startDate")}
                  selected={startDate}
                  minDate={
                    baseEducation.year === new Date().getFullYear() ? undefined : new Date(baseEducation.year, 0, 1)
                  }
                  onChangeHandler={(date) => {
                    setStartDate(date);
                    if (date) {
                      const endDate = moment(date).add(1, "days").toDate();
                      setEndDate(endDate);
                      updateBothVariantDates(date, endDate);
                    } else {
                      updateVariant("start", date);
                    }
                  }}
                />
              </div>
              <div className="app-label">
                <DatePicker
                  name={`endDateVariant${index}`}
                  label="End Date"
                  validate={validate("endDate")}
                  onChangeHandler={(date) => {
                    setEndDate(date);
                    updateVariant("end", date);
                  }}
                  minDate={
                    startDate
                      ? startDate
                      : baseEducation.year === new Date().getFullYear()
                      ? undefined
                      : new Date(baseEducation.year, 0, 1)
                  }
                  selected={endDate}
                />
              </div>
            </div>
            <div className="app-label fields-next" style={{ paddingRight: "25px" }}>
              <DatePicker
                name={`latestEnrollmentDate${index}`}
                label="Last enrollment Date"
                validate={validate("latestEnrollmentDate", undefined, { educationType: baseEducation.educationType })}
                selected={registrationDeadline}
                maxDate={startDate && moment(startDate).subtract(1, "days").toDate()}
                required={["COURSE", "INTERNAL_COURSE", "CONFERENCE", undefined].includes(baseEducation.educationType)}
                onChangeHandler={(date) => {
                  if (date) {
                    const registrationDeadline = moment(date).endOf("day").toDate();
                    setRegistrationDeadline(registrationDeadline);
                    updateVariant("registrationDeadline", registrationDeadline);
                  } else {
                    updateVariant("registrationDeadline", undefined);
                    setRegistrationDeadline(undefined);
                  }
                }}
              />
            </div>
          </>
        ) : null}
        {baseEducation.educationType !== "COMPETENCE_CENTER" && (
          <>
            <div>
              <TicketOptionRequirements
                editing={!!editingTicketOption}
                requirementsChanged={(requirements: string[]) => {
                  setRequirements(requirements);
                  updateVariant("bookingInfo.tickets", mapRequirements(requirements, bookBefore));
                }}
                requirements={requirements}
              />
            </div>
            {hasEntryRequirement(requirements) ? (
              <div className="app-label">
                <DatePicker
                  name={`bookBeforeVariant${index}`}
                  label="Book Entry tickets before"
                  validate={validate("startDate")}
                  onChangeHandler={(date) => {
                    setBookBefore(date);
                    updateVariant("bookingInfo.tickets", mapRequirements(requirements, date));
                  }}
                  maxDate={startDate ? startDate : undefined}
                  selected={bookBefore}
                />
              </div>
            ) : null}
            <div className="fields-next">
              <Field
                name={`cost${index}`}
                label="Base cost €"
                type="number"
                required={true}
                validate={validate("cost")}
                defaultValue={`${editingTicketOption ? editingTicketOption.price.cost : cost || ""}`}
                onChangeHandler={({ target }) => {
                  setCost(target.value);
                  updateVariant("price.cost", target.value ? Number(target.value) : target.value);
                }}
              />
              <Field
                name={`halfDays${index}`}
                label="# of half days"
                type="number"
                required={true}
                validate={validate("halfDays")}
                defaultValue={`${editingTicketOption ? editingTicketOption.price.halfDays : halfDays || ""}`}
                onChangeHandler={({ target }) => {
                  setHalfDays(target.value);
                  updateVariant("price.halfDays", target.value ? Number(target.value) : target.value);
                }}
              />
            </div>

            <div className="fields-next">
              <Field
                name={`additionalCosts${index}`}
                label="Additional costs € (Travel costs, ...)"
                type="number"
                validate={validate("additionalCosts")}
                defaultValue={`${
                  editingTicketOption ? editingTicketOption.price.additionalCosts || "" : additionalCosts || ""
                }`}
                onChangeHandler={({ target }) => {
                  setAdditionalCosts(target.value);
                  updateVariant("price.additionalCosts", target.value ? Number(target.value) : target.value);
                }}
              />
            </div>

            <div>
              <div>
                <label>
                  <input
                    type="checkbox"
                    onClick={(e: any) => {
                      setOverrule(e.target.checked);
                    }}
                  />{" "}
                  Overrule Price
                </label>
                {overrule ? (
                  <div>
                    <Field
                      name={`overruledApPrice${index}`}
                      label="Overruled AP price"
                      type="number"
                      validate={validate("overruledApPrice")}
                      defaultValue={`${
                        editingTicketOption ? editingTicketOption.price.overruledApPrice : overruledAPAmount || ""
                      }`}
                      onChangeHandler={({ target }) => {
                        setOverruledAP(target.value);
                        updateVariant("price.overruledApPrice", target.value ? Number(target.value) : target.value);
                      }}
                    />
                    <div style={{ transform: "translateY(-20px)" }}>
                      <i>This field will override the total cost</i>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </>
        )}

        <div>
          <TextArea
            name="extraInformation"
            label="Extra Information"
            defaultValue={extraInformation}
            onChangeHandler={({ target }) => {
              setExtraInformation(target.value);
              updateVariant("bookingInfo.extraInfo", target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TicketOption;
