import React, { useState, useMemo, useEffect } from "react";
import { useForm } from "react-form";
import * as yup from "yup";

import {
  Education,
  EducationPricing,
  EducationTicketOption,
  URL_REGEX
} from "../../domain/education";
import {
  updateEducation,
  updateEducationImage
} from "../../services/education";
import {
  Checkbox,
  Field,
  FileUpload,
  TypeSelector,
  AsyncTypeahead,
  MarkdownArea
} from "../Form";
import Modal from "../Modal";

import "react-datepicker/dist/react-datepicker.min.css";
import Loader from "../Loader";
import "./editEducation.scss";
import messageService from "../../services/message.service";
import DepartmentsSelector from "../DepartmentsSelector";
import EducationRequirements from "../EducationRequirements";
import { searchTags } from "../../services/tag";
import { DepartmentType } from "../../domain/department";

interface Props {
  modalOpened?: boolean;
  onComplete?: () => void;
  onCancel?: () => void;
  baseEducation: Education;
}

interface EducationPricingProps {
  ticketOption: EducationTicketOption;
  validation: any;
  updatePricing: (pricing: EducationPricing) => void;
}

const EditEducation: React.FunctionComponent<Props> = ({
  modalOpened = false,
  onComplete = () => {},
  onCancel = () => {},
  baseEducation
}) => {
  const [submittingEducation, setSubmitEducation] = useState("INIT");
  const [image, setImage] = useState("INIT");
  const [educationType, setEducationType] = useState(
    baseEducation.educationType
  );
  const [changeImage, setChangeImage] = useState(false);
  const [requirements, setRequirements] = useState<string[]>(
    baseEducation.requirements || []
  );
  const [organisingDepartment, setOrganisingDepartment] = useState<
    DepartmentType | undefined
  >();
  const [tutor, setTutor] = useState<string>();
  const [pricing, setPricing] = useState<EducationPricing>();

  useEffect(() => {
    setTutor(baseEducation.tutor);
    if (organisingDepartment !== "UNKNOWN") {
      setOrganisingDepartment(baseEducation.organisingDepartment);
    }
  }, [baseEducation, modalOpened]);

  const form = useForm({
    defaultValues: useMemo(
      () => ({
        ...baseEducation
      }),
      [baseEducation]
    )
  });

  const saveEducation = (values: Education) => {
    const newEducation = {
      ...values,
      uuid: baseEducation.uuid,
      requirements,
      organisingDepartment,

      pricing
    };

    updateEducation(newEducation)
      .then(() => {
        if (image !== "INIT")
          updateEducationImage(baseEducation.uuid, image)
            .then(() => {
              setTimeout(() => complete(), 2000);
            })
            .catch(() => {
              messageService.error(
                "Something went wrong while saving the image. Please Try again later."
              );
              cancel();
            });
        else {
          setTimeout(() => complete(), 2000);
        }
      })
      .catch(() => {
        messageService.error(
          "Something went wrong while saving the education. Please Try again later."
        );
        cancel();
      });
  };

  const cancel = () => {
    form.reset();
    setImage("INIT");
    setSubmitEducation("INIT");
    setOrganisingDepartment(undefined);
    onCancel();
  };

  const complete = () => {
    form.reset();
    setImage("INIT");
    setSubmitEducation("INIT");
    setOrganisingDepartment(undefined);
    onComplete();
  };

  const submitEducation = async () => {
    setSubmitEducation("SUBMITTING");
    saveEducation(form.values);
    form.reset();
  };

  const otherValuesAreValid = () => {
    if (
      (educationType === "INTERNAL_COURSE" || educationType === "COURSE") &&
      !tutor
    ) {
      return false;
    }
    if (!organisingDepartment || organisingDepartment === "UNKNOWN") {
      return false;
    }
    return true;
  };

  const { Form } = form;

  const positiveNumberValidation = yup
    .string()
    .test("num", "Value must be a positive number", (val) => {
      if (!val) {
        return true;
      }
      return !Number.isNaN(val) && Number(val) >= 0;
    });

  const validation = yup.object().shape({
    name: yup.string().required("Name is required").default(baseEducation.name),
    description: yup
      .string()
      .required("Description is required")
      .default(baseEducation.description),
    organisingDepartment: yup
      .string()
      .oneOf(
        [
          "DATA",
          "DOTNET",
          "HR",
          "INFRA",
          "JAVA",
          "OPENSOURCE",
          "PM",
          "TESTING",
          "FRONTEND"
        ],
        "At least one valid department is required"
      ),
    departments: yup
      .array()
      .min(1, "At least one department is required")
      .required("At least one department is required")
      .of(
        yup
          .mixed()
          .oneOf(
            [
              "DATA",
              "DOTNET",
              "HR",
              "INFRA",
              "JAVA",
              "OPENSOURCE",
              "PM",
              "TESTING",
              "FRONTEND"
            ],
            "At least one valid department is required"
          )
      )
      .default(baseEducation.departments),
    startDate: yup.date().nullable(),
    endDate: yup.date().nullable(),
    latestEnrollmentDate: yup.date().nullable(),
    educationType: yup
      .mixed()
      .oneOf(
        ["ONLINE_COURSE", "COURSE", "INTERNAL_COURSE", "CONFERENCE", "LICENCE"],
        "Education type is required"
      )
      .required("Education type is required")
      .default(baseEducation.educationType),
    cost: positiveNumberValidation.required("The base cost is required"),
    additionalCosts: positiveNumberValidation.nullable(),
    overruledApPrice: positiveNumberValidation,
    maxAttendees: positiveNumberValidation,
    halfDays: positiveNumberValidation.required(
      "Number of half days is required"
    ),
    website: yup
      .string()
      .test("match", "Not a valid url", (val) => {
        if (!val) return true;
        return !!val.match(URL_REGEX);
      })
      .default(baseEducation.website),
    year: yup
      .number()
      .required("Year is required")
      .test(
        "len",
        "Must be exactly 4 numbers",
        (val) => !!val && val.toString().length === 4
      )
      .test(
        "greaterThanOrEqualToYearNow",
        "Must be greater than or equal to " + new Date().getFullYear(),
        (val) => !!val && val >= new Date().getFullYear()
      )
      .default(baseEducation.year),
    image: yup.string().nullable().default(baseEducation.imageUrl)
  });

  const validate = (field: string) => {
    return (value: any): boolean => {
      try {
        yup.reach(validation, field).validateSync(value);
        return false;
      } catch ({ errors }) {
        return errors[0];
      }
    };
  };

  return (
    <div className="edit-education">
      <Modal
        open={modalOpened}
        hideComplete={true}
        hideCancel={true}
        overflow={true}
        onClose={onCancel}
        disableScroll={submittingEducation === "SUBMITTING"}
      >
        {submittingEducation === "INIT" ? <h1>Edit education</h1> : ""}
        <Form>
          {modalOpened && submittingEducation === "INIT" ? (
            <>
              <div className="form__content">
                <div className="edit-category name">
                  <Field
                    name="name"
                    label="Name"
                    required={true}
                    defaultValue={baseEducation.name}
                    validate={validate("name")}
                  />
                  <div className="app-label organising-department">
                    <DepartmentsSelector
                      name="organisingDepartment"
                      label="Organising Department"
                      required={true}
                      validate={validate("organisingDepartment")}
                      defaultSelected={
                        organisingDepartment ? [organisingDepartment] : []
                      }
                      onChange={(dep) => setOrganisingDepartment(dep)}
                      mapper={(value) => value.value}
                      exclusions={["ADMIN"]}
                      multiple={false}
                    />
                  </div>
                  <div className="app-label">
                    <DepartmentsSelector
                      label="Departments"
                      required={true}
                      validate={validate("departments")}
                      defaultSelected={baseEducation.departments}
                      exclusions={["ADMIN"]}
                      mapper={(value) => value.value}
                    />
                  </div>
                  <div className="form-input__small">
                    <Field
                      name="year"
                      label="Year"
                      type="number"
                      defaultValue={`${baseEducation.year}`}
                      required={true}
                      validate={validate("year")}
                    />
                  </div>
                </div>
                <div className="edit-category extra">
                  <h3>Extra Information</h3>
                  <MarkdownArea
                    name="description"
                    label="Description"
                    required={true}
                    defaultValue={baseEducation.description}
                    validate={validate("description")}
                  />
                  <div className="app-label">
                    <AsyncTypeahead
                      label="Tags"
                      name="tags"
                      onSearch={searchTags}
                      multiple={true}
                      clearButton={true}
                      allowNew={true}
                      defaultSelected={baseEducation.tags}
                    />
                  </div>
                  <Field
                    name="website"
                    label="Website"
                    defaultValue={baseEducation.website}
                    validate={validate("website")}
                  />
                  <Field
                    name="location"
                    label="Location"
                    defaultValue={baseEducation.location}
                  />
                </div>
                <div className="edit-category format">
                  <h3>Format</h3>
                  <div className="app-label">
                    <TypeSelector
                      name="educationType"
                      label="Education Type"
                      options={[
                        { text: "Axxes Course", value: "INTERNAL_COURSE" },
                        { text: "Online Course", value: "ONLINE_COURSE" },
                        { text: "Classroom Course", value: "COURSE" },
                        { text: "Conference", value: "CONFERENCE" },
                        { text: "Licence", value: "LICENCE" }
                      ]}
                      required={true}
                      onChangeHandler={setEducationType}
                      validate={validate("educationType")}
                      defaultSelected={baseEducation.educationType}
                    />
                  </div>
                  <Field
                    name="tutor"
                    label="Tutor"
                    defaultValue={baseEducation.tutor}
                    required={
                      educationType === "COURSE" ||
                      educationType === "INTERNAL_COURSE"
                    }
                    onChangeHandler={({ target }) => setTutor(target.value)}
                  />
                  <div className="max-attendees">
                    <Field
                      name="maxAttendees"
                      label="Maximum Attendees"
                      type="number"
                      validate={validate("maxAttendees")}
                      defaultValue={`${baseEducation.maxAttendees || ""}`}
                    />
                  </div>
                </div>
                <div className="edit-category requirements">
                  <h3>Requirements</h3>
                  <div className="form__input-group app-label">
                    <EducationRequirements
                      requirements={requirements}
                      requirementsChanged={setRequirements}
                    />
                  </div>
                </div>
                <div className="edit-category image">
                  <Checkbox
                    name="changeImage"
                    label="Change education image"
                    onChangeHandler={() => setChangeImage(!changeImage)}
                  />

                  {changeImage ? (
                    <FileUpload
                      name="image"
                      label="Education Image"
                      onChange={(i) => {
                        setImage(i);
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="modal__footer">
                <button
                  className="axxes-button --color-primary"
                  type="button"
                  onClick={() => cancel()}
                >
                  Cancel
                </button>
                {form.meta.error}
                <button
                  className="axxes-button --color-accent"
                  type="button"
                  onClick={() => submitEducation()}
                  style={{ marginLeft: "20px" }}
                  disabled={!form.meta.isValid || !otherValuesAreValid()}
                >
                  Save
                </button>
              </div>
            </>
          ) : (
            ""
          )}
          <div className="create-education-finish">
            {submittingEducation === "SUBMITTING" ? (
              <div className="submitting">
                <h2>Please wait...</h2>
                <Loader loading />
              </div>
            ) : (
              ""
            )}
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default EditEducation;
