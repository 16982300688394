import React from "react";
import User from "../../domain/user";

import "./profile-picture.scss";

interface Props {
  user?: User;
  size?: "small" | "big" | "medium";
}

const ProfilePicture: React.FunctionComponent<Props> = ({ user, size }) => {
  return user ? (
    <span className={`profile-picture ${size}`}>
      {user.profilePhoto ? (
        <img src={user.profilePhoto} alt="Profile" />
      ) : (
        <span className="initials">
          {user.firstName.substring(0, 1)}
          {user.lastName.substring(0, 1)}
        </span>
      )}
    </span>
  ) : null;
};

export default ProfilePicture;
