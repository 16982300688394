import axios from "axios";
import messageService from "./message.service";

const http = axios.create({
  baseURL: "/api/"
});

const getRole = () => {
  const userId = localStorage.getItem("dev_userId");
  if (userId === "javacoach") {
    return "COACH,CONSULTANT";
  }
  if (userId === "javaconsultant" || userId === "dotnetconsultant") {
    return "CONSULTANT";
  }
  if (userId === "hrperson") {
    return "HR";
  }
  if (userId === "admin") {
    return "ADMIN";
  }
  return "";
};

http.interceptors.request.use((config) => {
  if (process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line no-param-reassign
    config.headers.common.Authorization = `Bearer validToken ${getRole()} ${localStorage.getItem(
      "dev_userId"
    )}`;
  }
  return config;
});

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 500) {
      messageService.error(
        "Something went wrong while processing your request."
      );
    }
    if (error.response.status === 401) {
      localStorage.setItem("fep-redirect-url", location.href);
      messageService.warning(
        "You are automatically logged out because your token is expired."
      );
      location.href = "/login";
    }

    return Promise.reject(error);
  }
);

export default http;
