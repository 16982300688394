import React, { useEffect, useState } from "react";

import Loader from "../../components/Loader";
import { getEducationPlan } from "../../services/user";
import User from "../../domain/user";
import AxxesDropdown from "../../components/AxxesDropdown";

import "./education-plan.scss";
import { Education, EducationPlanEducation } from "../../domain/education";
import AxxesPointLogo from "../../components/AxxesPointLogo";

interface Props {
  consultant?: User;
}

interface Credits {
  assignedCredits: number;
  availableCredits: number;
}

const EducationPlan: React.FunctionComponent<Props> = ({ consultant }) => {
  const currentYear = new Date().getFullYear();
  const selecteableYears = [currentYear - 1, currentYear, currentYear + 1, currentYear + 2];
  const [selectedYear, selectYear] = useState(currentYear);
  const [loading, setLoading] = useState(false);
  const [educations, setEducations] = useState<EducationPlanEducation[]>([]);
  const [credits, setCredits] = useState<Credits>();

  useEffect(() => {
    async function fetchEducationPlan() {
      if (consultant) {
        setLoading(true);
        try {
          const educationPlan = await getEducationPlan(consultant, selectedYear);
          setCredits({
            assignedCredits: educationPlan.assignedCredits,
            availableCredits: educationPlan.availableCredits
          });
          setEducations(educationPlan.tickets);
        } catch (e) {
          setEducations([]);
          setCredits(undefined);
        }
        setLoading(false);
      }
    }
    fetchEducationPlan();
  }, [selectedYear, consultant]);

  return (
    <div className="education-plan">
      <label>
        Year
        <AxxesDropdown
          options={selecteableYears.map((year) => ({
            label: `${year}`,
            value: year
          }))}
          selectedItem={currentYear}
          onChange={(year) => selectYear(year)}
        />
      </label>
      <div className="axxes-card">
        <div className="axxes-card__content">
          {credits && !loading && (
            <div className="available-credits">
              <b>
                {credits.availableCredits}/{credits.assignedCredits}
              </b>
            </div>
          )}
          <Loader loading={loading} />
          {!loading && (
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Cost</th>
                </tr>
              </thead>
              <tbody>
                {educations.map((education: EducationPlanEducation) => (
                  <tr key={education.educationId}>
                    <td>{education.educationName}</td>
                    <td>
                      {education.credits}
                      <AxxesPointLogo height="small" />
                    </td>
                  </tr>
                ))}
                {!educations.length && (
                  <tr>
                    <td colSpan={2}>No Educations yet planned.</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default EducationPlan;
