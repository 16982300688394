import { Button } from "@axxes/design-system";
import React, { useState } from "react";
import { Booking, BookingUser, flattenUsers } from "../../../domain/booking";
import Modal from "../../Modal";

interface ApproveModalProps {
  open: boolean;
  bookings: Booking[];
  educationName: string;
  onClose: () => void;
  onComplete: (bookings: Booking[]) => void;
  onApprove: (booking: Booking, user: BookingUser) => void;
  onReject: (booking: Booking, user: BookingUser) => void;
}

const ApproveModal: React.FC<ApproveModalProps> = ({
  open,
  bookings = [],
  educationName,
  onClose,
  onComplete,
  onApprove,
  onReject
}) => {
  const [confirmApproval, setConfirmApproval] =
    useState<{ state: "APPROVE" | "REJECT"; user: BookingUser }>();

  const confirm = (booking: Booking, user: BookingUser) => {
    if (confirmApproval?.state === "APPROVE") {
      onApprove(booking, user);
    }
    if (confirmApproval?.state === "REJECT") {
      onReject(booking, user);
    }
  };

  return (
    <Modal
      open={open}
      title={`Approve ${educationName}`}
      confirmText={
        flattenUsers(bookings).filter((user) => !user.approved).length
          ? `Approve All and Confirm (${
              flattenUsers(bookings).filter((user) => !user.approved).length
            })`
          : "Complete"
      }
      onClose={onClose}
      onSubmit={onComplete.bind(this, bookings)}
    >
      <p>
        You are about to approve the education{" "}
        <b>{bookings[0]?.educationName}</b> for{" "}
        <b>{flattenUsers(bookings).length}</b> person
        {(flattenUsers(bookings).length || 0) > 1 ? "s" : ""}
      </p>
      <div>
        Rejecting the consultant will remove the consultant from the list
      </div>
      <b>Consultants ({flattenUsers(bookings).length})</b>
      {bookings.map((booking: Booking) =>
        booking.users.map((user: BookingUser) => (
          <div className="approval-booking-user" key={user.id}>
            <div className="approval-booking-user-info">
              {user.photoUrl ? (
                <img src={user.photoUrl} alt="profile picture" />
              ) : null}
              <div>
                <b>{user.name}</b>
                <div>
                  <i className="approval-booking-user-ticket-option">
                    {booking.ticketOption}
                  </i>
                </div>
              </div>
              <div className="axxes-badge">{user.department}</div>
            </div>

            {user.approved ? (
              <div className="axxes-badge APPROVED">APPROVED</div>
            ) : (
              <div className="approval-booking-user__actions">
                {confirmApproval && confirmApproval.user?.id === user.id ? (
                  <div>
                    <Button
                      variant="ghost"
                      accent={true}
                      customClasses="cancel"
                      onClick={setConfirmApproval.bind(this, undefined)}
                    >
                      Cancel
                    </Button>
                    <Button
                      accent={true}
                      customClasses="confirm"
                      onClick={confirm.bind(this, booking, user)}
                    >
                      Confirm
                    </Button>
                  </div>
                ) : (
                  <div>
                    <Button
                      accent={true}
                      customClasses="approve"
                      onClick={setConfirmApproval.bind(this, {
                        state: "APPROVE",
                        user
                      })}
                    >
                      Approve
                    </Button>
                    <Button
                      accent={true}
                      customClasses="reject"
                      onClick={setConfirmApproval.bind(this, {
                        state: "REJECT",
                        user
                      })}
                    >
                      Reject
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        ))
      )}
      <p>
        After approval, administration will take over in order to handle al your
        necessary requirements. <b>Are you sure?</b>
      </p>
    </Modal>
  );
};

export default ApproveModal;
