import * as yup from "yup";
import { ValidationError } from "yup";

const URL_REGEX = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?%#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/;
const positiveNumberValidation = yup.string().test("num", "Value must be a positive number", (val) => {
  if (!val) {
    return true;
  }
  return !Number.isNaN(val) && Number(val) >= 0;
});

const validation = yup.object().shape({
  name: yup.string().required("Name is required"),
  educationType: yup
    .mixed()
    .oneOf(
      ["ONLINE_COURSE", "COURSE", "INTERNAL_COURSE", "CONFERENCE", "LICENCE", "COMPETENCE_CENTER"],
      "Education type is required"
    )
    .required("Education type is required"),
  organisingDepartment: yup
    .string()
    .oneOf(
      ["DATA", "DOTNET", "HR", "INFRA", "JAVA", "OPENSOURCE", "PM", "TESTING", "FRONTEND"],
      "At least one department is required"
    )
    .required("At least one department is required"),
  departments: yup
    .array()
    .min(1, "At least one department is required")
    .required("At least one department is required")
    .of(
      yup
        .mixed()
        .oneOf(
          ["DATA", "DOTNET", "HR", "INFRA", "JAVA", "OPENSOURCE", "PM", "TESTING", "FRONTEND"],
          "At least one department is required"
        )
    ),
  description: yup.string().required("Description is required"),
  startDate: yup.date().nullable(),
  endDate: yup.date().nullable(),
  latestEnrollmentDate: yup
    .date()
    .nullable()
    .test("", "", function (value) {
      // @ts-ignore
      const educationType = this.options.context.educationType;
      if (educationType === undefined) {
        return new ValidationError("An education type is required", null, "latestEnrollmentDate");
      }
      if (["COURSE", "INTERNAL_COURSE", "CONFERENCE"].includes(educationType)) {
        return value === undefined
          ? new ValidationError(
              "Last enrollment date is required with this type of education",
              null,
              "latestEnrollmentDate"
            )
          : true;
      }
      return false;
    }),
  halfDays: positiveNumberValidation.required("Number of half days is required"),
  website: yup.string().test("match", "Not a valid url", (val) => {
    if (!val) return true;
    return !!val.match(URL_REGEX);
  }),
  cost: positiveNumberValidation.required("The base cost is required"),
  additionalCosts: positiveNumberValidation.nullable(),
  overruledApPrice: positiveNumberValidation,
  maxAttendees: positiveNumberValidation,
  image: yup.string().required("Image is required"),
  year: yup
    .number()
    .required("Year is required")
    .test("len", "Must be exactly 4 numbers", (val) => !!val && val.toString().length === 4)
    .test(
      "greaterThanOrEqualToYearNow",
      "Must be greater than or equal to " + new Date().getFullYear(),
      (val) => !!val && val >= new Date().getFullYear()
    )
});

const validate = (field: string, overrideValidation?: any, context?: object): ((value: any) => boolean) => {
  return (value: any): boolean => {
    try {
      if (overrideValidation) {
        overrideValidation.validateSync(value, { context });
      } else {
        yup.reach(validation, field).validateSync(value, { context });
      }
      return false;
    } catch (e) {
      return e && e.errors[0];
    }
  };
};

export { validation, validate, positiveNumberValidation };
