import React, { useEffect, useState } from "react";

import "./modal.scss";

const ESCAPE_KEY = "Escape";

interface Props {
  title?: string;
  open: boolean;
  children: any;
  hideComplete?: boolean;
  hideCancel?: boolean;
  completeDisabled?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  confirmText?: string;
  overflow?: boolean;
  size?: "compact" | "wider" | "super" | "superwide";
  disableScroll?: boolean;
  customClass?: string;
}

const Modal: React.FunctionComponent<Props> = ({
  children,
  title = null,
  open = false,
  hideComplete = false,
  completeDisabled = false,
  hideCancel = false,
  onClose = () => {},
  onSubmit = () => {},
  confirmText = "Confirm",
  overflow = false,
  size = "compact",
  disableScroll = false,
  customClass = ""
}) => {
  const [modalOpened, setModal] = useState(open);

  const close = () => {
    setModal(false);
    onClose();
  };

  const closeEvent = (event: KeyboardEvent) => {
    if (event.key === ESCAPE_KEY) {
      close();
    }
  };

  useEffect(() => {
    setModal(open);
  }, [open]);

  useEffect(() => {
    document.addEventListener("keyup", closeEvent);

    return () => {
      document.removeEventListener("keyup", close);
    };
  }, []);

  if (modalOpened) {
    return (
      <div className={`modal ${modalOpened ? "opened" : ""} ${customClass}`}>
        <div className="modal__backdrop" onClick={close} />
        <div className={`modal__content ${size}`}>
          {title && (
            <div className="modal__header">
              <h2>{title}</h2>
            </div>
          )}
          <div className={`modal__body ${overflow ? "overflow" : ""} ${disableScroll ? "disable-scroll" : ""}`}>
            {children}
          </div>
          <div className="modal__footer">
            {!hideCancel ? (
              <button className="axxes-button--subtle" type="button" onClick={close}>
                Close
              </button>
            ) : (
              ""
            )}
            {!hideComplete ? (
              <button
                className="axxes-button --color-accent"
                type="button"
                disabled={completeDisabled}
                onClick={onSubmit}
              >
                {confirmText}
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
  return null;
};

export default Modal;
