import React, { useState, useContext } from "react";
import UserContext from "../../context/user";
import { Education, EducationPlanEducation } from "../../domain/education";
import messageService from "../../services/message.service";
import {
  deleteEducationFromEducationPlan,
  getUserCredits
} from "../../services/user";
import Loader from "../Loader";
import Modal from "../Modal";

interface UnsubscribeProps {
  education: EducationPlanEducation;
  disabled?: boolean;
  onUnsubscribe?: () => void;
}

const EducationUnsubscribe: React.FunctionComponent<UnsubscribeProps> = ({
  education,
  disabled = false,
  onUnsubscribe = () => {}
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user, updateUserContext } = useContext(UserContext);

  const unsubscribe = async (education: EducationPlanEducation) => {
    if (user !== undefined) {
      try {
        await deleteEducationFromEducationPlan(
          user.uuid,
          education.year,
          education.educationId,
          education.ticketOption
        ).then(() => {
          setLoading(true);
          getUserCredits(user).then((data) => {
            updateUserContext({
              ...user,
              availableCredits: !!data
                ? data.availableCredits
                : user.availableCredits
            });
          });
          setLoading(false);
        });
        messageService.success(
          `Education ${education.educationName} successfully deleted from Education Plan ${education.year}.`
        );
        onUnsubscribe();
        setOpen(false);
      } catch ({ response }) {
        setOpen(false);

        messageService.error(
          response?.data?.message ||
            `Unable to delete education from Education Plan. Please try again later.`,
          {
            timeOut: 15000
          }
        );
      }
    }
  };

  return (
    <>
      {loading ? (
        <Loader loading={true} />
      ) : (
        <button
          type="button"
          disabled={disabled}
          className="axxes-button --color-delete action-button"
          onClick={() => setOpen(true)}
        >
          Unsubscribe
        </button>
      )}
      <Modal
        open={open}
        title={"Unsubscribe from " + education.educationName + "?"}
        confirmText={"Confirm"}
        onClose={() => setOpen(false)}
        onSubmit={() => unsubscribe(education)}
      >
        <p>
          You subscribed for <b>{education.ticketOption}</b>
        </p>
        <p>Are you sure you want to unsubscribe?</p>
      </Modal>
    </>
  );
};

export default EducationUnsubscribe;
