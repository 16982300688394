export type FunctionCategoryValue = "GRADUATE" | "TECHNICAL" | "PROFESSIONAL" | "SENIOR" | "EXPERT" | "PRINCIPAL";

export interface FunctionCategory {
  label: string;
  value: FunctionCategoryValue;
}

export const FUNCTION_LEVELS: FunctionCategory[] = [
  {
    label: "Graduate",
    value: "GRADUATE"
  },
  {
    label: "Technical",
    value: "TECHNICAL"
  },
  {
    label: "Professional",
    value: "PROFESSIONAL"
  },
  {
    label: "Senior",
    value: "SENIOR"
  },
  {
    label: "Expert",
    value: "EXPERT"
  },
  {
    label: "Principal",
    value: "PRINCIPAL"
  }
];

export const getFunctionCategoryLabel = (functionCategory?: FunctionCategoryValue) => {
  const fc = FUNCTION_LEVELS.find(({ value }) => value === functionCategory);
  return fc?.label || "-";
};
