import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-form";
import moment from "moment";
import { Helmet } from "react-helmet";

import * as yup from "yup";
import { getEducation, subscribeOnWaitingList } from "../../../services/education";
import {
  bookmark,
  enroll,
  getEducationPlan,
  getUserCredits,
  hasRole,
  removeBookmark,
  ROLES
} from "../../../services/user";
import PageHeader from "../../../components/PageHeader";

import educationImage from "../overview/education.jpg";
import Loader from "../../../components/Loader";
import Breadcrumbs from "../../../components/Breadcrumbs";
import EducationDetailComponent from "../../../components/EducationDetail";

import UserContext from "../../../context/user";

import "./education-detail.scss";
import Modal from "../../../components/Modal";
import AxxesPointLogo from "../../../components/AxxesPointLogo";
import messageService from "../../../services/message.service";
import { Field } from "../../../components/Form";
import { Education, EducationPlan, EducationPlanEducation, EducationTicketOption } from "../../../domain/education";
import EducationUnsubscribe from "../../../components/EducationUnsubscribe";
import { EducationBookmark } from "../../../domain/user";
import { useParams } from "react-router-dom";

const EducationDetail: React.FunctionComponent<{}> = () => {
  const educationId = `${useParams().educationId}`;
  const { user, updateUserContext } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [education, setEducation] = useState<any>({});
  const [enrollConfirmation, toggleEnrollConfirmation] = useState(false);
  const [waitingListConfirmation, toggleWaitingListConfirmation] = useState(false);
  const [enrolling, setEnrolling] = useState("CONFIRMING");
  const [recupChecked, setRecupChecked] = useState(false);
  const [recupDays, setRecupDays] = useState(0);
  const [educationPlan, setEducationPlan] = useState<any>({});
  const [enrolled, setEnrolled] = useState<boolean>(false);
  const [requirements, setRequirements] = useState<any[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [recupAcknowledged, setRecupAcknowledged] = useState<boolean>(false);
  const [ticketOption, selectTicketOption] = useState<EducationTicketOption | undefined>();

  async function fetchEducationPlan(e: Education) {
    if (user) {
      const plan = await getEducationPlan(user, e.year);
      setEducationPlan(plan);
      return plan;
    }
  }

  async function fetchEducation() {
    setLoading(true);
    setError(false);
    try {
      const e = await getEducation(educationId);
      setEducation(e);
      resetRequirements(e);
      if (e.ticketOptions.length === 1) {
        selectTicketOption(e.ticketOptions[0]);
      }
      if (user) {
        const plan = await fetchEducationPlan(e);
        if (plan && !isNotAbleToEnroll(e, plan) && location.search.includes("enroll=true")) {
          toggleEnrollConfirmation(true);
        }
      }
    } catch (e) {
      setError(true);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchEducation();
    reset();
  }, [educationId]);

  const resetRequirements = (e: Education) => {
    setRequirements(
      (e.requirements || []).map((requirement) => ({
        requirement,
        checked: false
      }))
    );
  };

  const enrollEducation = async () => {
    if (education && user) {
      setEnrolling("ENROLLING");
      enroll(user, education, recupDays, ticketOption)
        .then(() => {
          toggleEnrollConfirmation(false);
          setEnrolling("CONFIRMING");
          setEnrolled(true);
          resetRequirements(education);
          if (
            ticketOption &&
            ticketOption.price.credits - recupDays * (ticketOption.price.discountPerRecupUnit || 0) >
              educationPlan.availableCredits
          ) {
            messageService.warning(
              `Thanks for enrolling for ${education.name}! You exceeded your availale credits so your coach will get notified.`,
              { timeOut: 60000 }
            );
          } else {
            messageService.success(
              `Thanks for enrolling for ${education.name} ${education.year}! Keep an eye on your mailbox for more practical information.`,
              { timeOut: 60000 }
            );
          }
          if (education.year === new Date().getFullYear()) {
            getUserCredits(user).then((data) => {
              updateUserContext({
                ...user,
                availableCredits: !!data ? data.availableCredits : user.availableCredits
              });
            });
          }
          fetchEducation();
        })
        .catch((error) => {
          toggleEnrollConfirmation(false);
          reset();
          const message =
            error?.response?.message || `You do not have enough credits for enrolling to ${education.name}`;
          messageService.error(message);
        });
    }
  };

  const form = useForm({
    onSubmit: () => {}
  });

  const { Form } = form;

  const reset = () => {
    form.reset();
    setRecupDays(0);
    setEnrolling("CONFIRMING");
    resetRequirements(education);
    if (education.ticketOptions?.length === 1) {
      selectTicketOption(education.ticketOptions[0]);
    } else {
      selectTicketOption(undefined);
    }
  };

  const toggleRecup = () => {
    if (recupChecked) {
      setRecupDays(0);
      form.reset();
      setRecupChecked(false);
    } else {
      setRecupChecked(true);
    }
  };

  const toggleRecupAcknowledged = () => {
    if (recupAcknowledged) {
      setRecupAcknowledged(false);
    } else {
      setRecupAcknowledged(true);
    }
  };

  const validation = yup.object().shape({
    recupDays: yup
      .number()
      .integer("Only integer values are permitted!")
      .moreThan(-1, "Only positive values are Allowed!")
      .test(
        "validateRecupDays",
        `This ticket option is only ${ticketOption?.price?.halfDays} half days long.`,
        (value = 0) => (value || 0) <= (ticketOption?.price?.halfDays || 0)
      )
  });

  const validate = (field: string) => {
    return (value: number) => {
      try {
        yup.reach(validation, field).validateSync(value);
        return false;
      } catch ({ errors }) {
        if (errors) {
          const error = errors[0];
          if (error.includes("NaN")) {
            return false;
          }
          return error;
        }
      }
    };
  };

  const recupDaysValid = () => {
    try {
      return yup.reach(validation, "recupDays").validateSync(recupDays || 0);
    } catch (e) {
      return false;
    }
  };

  const allTicketOptionsPassed = (ticketOptions: EducationTicketOption[]) => {
    return (
      ticketOptions.filter((ticketOption: EducationTicketOption) => {
        return moment(ticketOption.registrationDeadline).hours(23).minutes(59).isBefore(new Date());
      }).length === ticketOptions.length
    );
  };

  const isPassedEnrollmentDate = (ed: Education) => {
    if (!ed) {
      return true;
    } else {
      allTicketOptionsPassed(ed.ticketOptions);
    }
  };

  const isAlreadyEnrolled = (ed: Education, edp: EducationPlan) => {
    if (!ed || enrolled) {
      return true;
    } else {
      return (
        !!edp.tickets &&
        edp.tickets.filter((educationEntry: EducationPlanEducation) => educationEntry.educationId === ed.uuid).length >
          0
      );
    }
  };

  const areAllRequirementsChecked = (): boolean => {
    return !requirements.find(({ checked }) => !checked);
  };

  const markRequirement = (indexToChange: number) => {
    setRequirements(
      requirements.map(({ requirement, checked }, index) => ({
        requirement,
        checked: index === indexToChange ? !checked : checked
      }))
    );
  };

  const canUnsubscribe = () => {
    const foundTicket = getTicketOption(education, educationPlan);
    if (foundTicket) {
      return (
        !foundTicket.registrationDeadline ||
        moment(foundTicket.registrationDeadline).hours(23).minutes(59).isAfter(new Date())
      );
    }
    return true;
  };

  const subscribeForWaitingList = async (education: Education) => {
    await subscribeOnWaitingList(education.uuid, user?.uuid || "");
    toggleWaitingListConfirmation(false);
    messageService.success(`Successfully subscribed on the waiting list for ${education.name}`);
  };

  const canUserEnroll = () => {
    if (!areAllRequirementsChecked()) {
      return false;
    }
    if (!ticketOption) {
      return false;
    }
    if (educationPlan) {
      if (
        ticketOption &&
        ticketOption.price?.credits - (recupDays || 0) * (ticketOption.price?.discountPerRecupUnit || 0) >
          educationPlan.availableCredits
      ) {
        return recupAcknowledged;
      }
      return !recupChecked || recupDaysValid();
    }
    return false;
  };

  const isMaxAttendeesReached = () => {
    return education.maxAttendees ? education.attendees >= education.maxAttendees : false;
  };

  const isNotAbleToEnroll = (ed: Education, edp: EducationPlan) => {
    return (
      isAlreadyEnrolled(ed, edp) ||
      isPassedEnrollmentDate(ed) ||
      isMaxAttendeesReached() ||
      ed.ticketOptions.length === 0
    );
  };

  const toggleBookmark = async (ed: Education, value: boolean) => {
    if (user) {
      if (value) {
        await bookmark(user.uuid, ed.uuid);
        updateUserContext({
          ...user,
          bookmarks: [...user.bookmarks, { name: ed.name, educationId: ed.uuid }]
        });
        messageService.success(`Successfully bookmarked ${ed.name}`);
      } else {
        await removeBookmark(user.uuid, ed.uuid);
        updateUserContext({
          ...user,
          bookmarks: user.bookmarks.filter((bm: EducationBookmark) => bm.educationId !== ed.uuid)
        });
        messageService.success(`Successfully removed bookmark for ${ed.name}`);
      }
    }
  };

  const getTicketOption = (ed: Education, edp: EducationPlan) => {
    const foundTickets =
      (edp &&
        edp.tickets &&
        edp.tickets.filter((educationEntry: EducationPlanEducation) => educationEntry.educationId === ed.uuid)) ||
      [];
    return foundTickets[0];
  };

  return (
    <div className="page education-detail-page page-padding">
      <Helmet>
        <title>FEP: {education?.name || "Education"}</title>
      </Helmet>
      <PageHeader background={loading || !education || !education.image ? educationImage : education.imageUrl}>
        <h1>
          {education.name} {education.year}{" "}
        </h1>
        {!error ? (
          <Breadcrumbs
            crumbs={[
              { link: "/educations", text: "Educations" },
              { link: "", text: education.name }
            ]}
          />
        ) : (
          <span />
        )}
      </PageHeader>
      <div className="education-detail-container">
        <Loader loading={loading} />
        {!loading && !error && education && (
          <EducationDetailComponent
            enrolled={isAlreadyEnrolled(education, educationPlan)}
            education={education}
            enableStar={true}
            onStar={(value) => toggleBookmark(education, value)}
          />
        )}
        {error && <h2>The education you want to retrieve was not found.</h2>}
        <div className="education-detail-action">
          {!isAlreadyEnrolled(education, educationPlan) &&
          hasRole(user, [ROLES.CONSULTANT]) &&
          education.state === "PUBLISHED" ? (
            <button
              className="axxes-button --color-accent action-button"
              type="button"
              disabled={isNotAbleToEnroll(education, educationPlan)}
              onClick={() => toggleEnrollConfirmation(true)}
            >
              Enroll
            </button>
          ) : null}
          {!isAlreadyEnrolled(education, educationPlan) &&
          hasRole(user, [ROLES.CONSULTANT]) &&
          education.maxAttendees &&
          education.attendees >= education.maxAttendees &&
          education.state === "PUBLISHED" ? (
            <button
              className="axxes-button action-button waiting-list"
              type="button"
              onClick={() => toggleWaitingListConfirmation(true)}
            >
              Subscribe on waitinglist
            </button>
          ) : null}
          {getTicketOption(education, educationPlan) && isAlreadyEnrolled(education, educationPlan) ? (
            <EducationUnsubscribe
              disabled={
                education.educationType === "LICENSE" || education.ecuationType === "ONLINE_COURSE" || !canUnsubscribe()
              }
              education={getTicketOption(education, educationPlan)}
              onUnsubscribe={() => {
                fetchEducation();
                setEnrolled(false);
                reset();
                setRecupAcknowledged(false);
                setRecupChecked(false);
              }}
            />
          ) : null}
        </div>
        {!isAlreadyEnrolled(education, educationPlan) &&
        education.maxAttendees &&
        education.attendees === education.maxAttendees ? (
          <p className="education-filled">
            <i>
              The maximum attendees has been reached. Please contact your coach to see if something can be arranged or
              subscribe on the{" "}
              <b className="waiting-list" onClick={() => toggleWaitingListConfirmation(true)}>
                Waiting list
              </b>
              .
            </i>
          </p>
        ) : null}
        <Modal
          title={education.name || "Education"}
          open={waitingListConfirmation}
          onClose={() => toggleWaitingListConfirmation(false)}
          onSubmit={() => subscribeForWaitingList(education)}
        >
          <p>
            Are you sure you want to subscribe on the waiting list for: <b>{education?.name}</b>?
          </p>
        </Modal>
        <Modal
          open={enrollConfirmation}
          onClose={() => {
            toggleEnrollConfirmation(false);
            setRecupChecked(false);
            setRecupAcknowledged(false);
            reset();
          }}
          onSubmit={() => enrollEducation()}
          completeDisabled={!canUserEnroll()}
          hideCancel={enrolling === "ENROLLED"}
          hideComplete={enrolling === "ENROLLED"}
          disableScroll={enrolling === "ENROLLING"}
        >
          {enrolling === "CONFIRMING" && (
            <div className="enroll-modal">
              <h1>
                Enrollment {education.name} {education.year}
              </h1>
              <p className="confirmation-text">You are about to enroll for this education.</p>
              {education.ticketOptions?.length > 1 ? (
                <div className="ticket-options">
                  <b>Choose your ticket option:</b>
                  {education?.ticketOptions?.map((ticketOption: EducationTicketOption, index: number) => (
                    <div
                      key={`${index}_ticketoption`}
                      className={`ticket-option ${
                        ticketOption.registrationDeadline &&
                        moment(ticketOption.registrationDeadline).isBefore(moment())
                          ? "disabled"
                          : ""
                      }`}
                    >
                      <label>
                        <input
                          type="radio"
                          name="ticketoption"
                          value={`${index}_ticketoption`}
                          onChange={() => selectTicketOption(ticketOption)}
                          disabled={
                            ticketOption.registrationDeadline &&
                            moment(ticketOption.registrationDeadline).isBefore(moment())
                          }
                        />{" "}
                        <span className="ticket-option__name">{ticketOption.name}</span>
                      </label>
                      <b className="registration-deadline">(passed registration deadline)</b>
                    </div>
                  ))}
                </div>
              ) : null}
              {education.requirements?.length ? (
                <div className="requirements">
                  <p>
                    <b>
                      This education has prerequisites to enroll. To enroll please mark every prerequisite as complete.
                    </b>
                  </p>
                  {requirements.map((requirement, index: number) => (
                    <div key={`${index}_requirement`} className={`requirement ${requirement.checked ? "checked" : ""}`}>
                      <label>
                        <input type="checkbox" onClick={() => markRequirement(index)} /> {requirement.requirement}
                      </label>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
              <Form>
                <p>
                  You are about to spend{" "}
                  <span
                    className={`education-detail-credits ${recupDays > 0 && recupDaysValid() ? "crossed-out" : ""}`}
                  >
                    <b>{ticketOption?.price.credits !== undefined ? ticketOption?.price.credits : ""}</b>
                    {"-"}
                    <AxxesPointLogo height="small" />
                  </span>
                  {ticketOption?.price.credits && education.year !== new Date().getFullYear() ? (
                    <>
                      of your budget of <b>{education.year}!</b>
                    </>
                  ) : (
                    ""
                  )}
                  <div
                    className={`education-detail-credits-wrapper ${recupDays > 0 && recupDaysValid() ? "" : "hidden"}`}
                  >
                    With recup you are about to spend
                    <b className="education-detail-credits">
                      {(ticketOption?.price.credits || 0) - recupDays * (ticketOption?.price.discountPerRecupUnit || 0)}
                    </b>{" "}
                    <AxxesPointLogo height="small" />
                  </div>
                  {educationPlan &&
                  (ticketOption?.price.credits || 0) - recupDays * (ticketOption?.price.discountPerRecupUnit || 0) >
                    educationPlan.availableCredits ? (
                    <>
                      <p className="exceeded-credits">
                        !! You are about to spend more credits than you have. This is not recommended.{" "}
                        {education.year === new Date().getFullYear()
                          ? "Please be aware that your coach will get notified if you do. You could always use recup days instead"
                          : "Since this is an education of " +
                            education.year +
                            " your coach probably did not give you any credits yet for that year. Don't worry they will come and your credits will be recalculated."}{" "}
                        !!
                      </p>
                      <label>
                        <input type="checkbox" onClick={toggleRecupAcknowledged} />{" "}
                        <b>Are you sure you want to spend more credits than you have available?</b>
                      </label>
                    </>
                  ) : null}
                </p>
                {(ticketOption?.price.halfDays || 0) > 0 ? (
                  <p>
                    <b>You have the possibility to spend a portion of your recup in exchange for a credit discount.</b>
                  </p>
                ) : null}
                {(ticketOption?.price.halfDays || 0) > 0 ? (
                  <p>
                    <label>
                      <input type="checkbox" onClick={toggleRecup} disabled={!ticketOption} /> Use Recup
                    </label>
                  </p>
                ) : null}

                <span className={recupChecked ? "" : "hidden"}>
                  <Field
                    label="Number of half days in recup: "
                    type="number"
                    name="recupDays"
                    placeholder="# of recup days"
                    defaultValue={`${recupDays}`}
                    onChangeHandler={(event) =>
                      setRecupDays(event.target.value !== "" ? parseInt(event.target.value, 10) : 0)
                    }
                    validate={validate("recupDays")}
                  />
                </span>
              </Form>
            </div>
          )}
          <div className="enroll-feedback">
            {enrolling === "ENROLLING" && (
              <div className="enrolling">
                <h2>Please wait...</h2>
                <Loader loading={true} />
              </div>
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default EducationDetail;
