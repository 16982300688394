import { Card, CardContent, CardHeader } from "@axxes/design-system";

import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import LoadingBlock from "../../components/LoadingBlock";
import { Booking, BookingTicketState, BookingUser, groupByEducation } from "../../domain/booking";
import {
  getBookings,
  approveBooking as approveBookingById,
  updateBookingTicket,
  closeBooking,
  approveUser,
  rejectUser
} from "../../services/booking";
import messageService from "../../services/message.service";

import "./ticket-administration.scss";
import AdministrationDoneTicket from "../../components/TicketAdministration/done";
import AdministrationHandlingTicket from "../../components/TicketAdministration/handling";
import AdministrationApprovalTicket from "../../components/TicketAdministration/approval";
import HandlingBookingModal from "../../components/TicketAdministration/handling/modal";
import ApproveModal from "../../components/TicketAdministration/approval/modal";
import CloseModal from "../../components/TicketAdministration/done/modal";
import TicketAdministrationIcon from "../../components/TicketAdministrationIcon";
import {
  REQUIREMENT_ACCOMMODATION,
  REQUIREMENT_ENTRY,
  REQUIREMENT_OTHER,
  REQUIREMENT_TRANSPORT,
  REQUIREMENT_VENUE
} from "../../domain/education";
import Toggle from "../../components/Toggle";
import UserContext from "../../context/user";
import { hasRole, ROLES } from "../../services/user";
import AxxesDropdown from "../../components/AxxesDropdown";

const startYear = 2022;
const selecteableYears: (number | undefined)[] = [
  undefined,
  ...Array.from({ length: new Date().getFullYear() - startYear + 1 }, (_, i) => startYear + i)
];

const TicketAdministration: React.FunctionComponent<{}> = ({}) => {
  const [loading, setLoading] = useState(false);
  const [approval, openApproval] = useState<Booking[]>([]);
  const [handling, openHandling] = useState<Booking>();
  const [closing, openClosing] = useState<Booking>();
  const [inApproval, setInApporval] = useState<Booking[]>([]);
  const [ticketHandling, setTicketHandling] = useState<Booking[]>([]);
  const [done, setDone] = useState<Booking[]>([]);
  const [departmentToggle, setDepartmentToggle] = useState<boolean>(false);
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const { user } = useContext(UserContext);

  useEffect(() => {
    fetchBookings(departmentToggle);
  }, [departmentToggle, year]);

  async function fetchBookings(departmentToggle = false) {
    setLoading(true);
    const response = await getBookings(departmentToggle ? user?.departments[0] : undefined, year);
    setInApporval(response?.PENDING_APPROVAL);
    setTicketHandling(response?.TICKET_HANDLING);
    setDone(response?.DONE);
    setLoading(false);
  }

  const openApprove = (bookings: Booking[]) => {
    openApproval(bookings);
  };

  const openClose = (booking: Booking) => {
    openClosing(booking);
  };

  const openHandle = (booking: Booking) => {
    openHandling(booking);
  };

  const approveBookings = (bookings: Booking[] = []) => {
    Promise.all(bookings.map((booking: Booking) => approveBookingById(booking.id)))
      .then(() => {
        openApproval([]);
        messageService.success(`Successfully approved education ${bookings[0].educationName}`);
        setTimeout(() => {
          fetchBookings();
        }, 1000);
      })
      .catch(() => messageService.error(`Something went wrong while approving education ${bookings[0].educationName}`));
  };

  const handleBooking = (
    values: {
      [field: string]: BookingTicketState;
    },
    booking?: Booking
  ) => {
    if (booking) {
      const entries = Object.entries(values);
      Promise.all(
        entries.map(([type, state]: any) => {
          return updateBookingTicket(booking.id, type, state);
        })
      )
        .then(fetchBookings.bind(this, departmentToggle))
        .then(() => messageService.success("Successfully updated the state of the ticket option actions."))
        .catch(() => messageService.error("Something went wrong while updating one of the actions"));
      openHandling(undefined);
    }
  };

  const closeTicketOption = (booking?: Booking, poNumber?: string) => {
    if (booking && poNumber) {
      closeBooking(booking.id, poNumber)
        .then(fetchBookings.bind(this, departmentToggle))
        .then(() => {
          openClosing(undefined);
          messageService.success(
            `Successfully closed administration for ${booking.educationName}: ${booking.ticketOption}`
          );
        });
    }
  };

  const approveUserTicket = (booking: Booking, user: BookingUser) => {
    if (booking) {
      approveUser(booking.id, user.id)
        .then(() => {
          if (approval) {
            const newApproval = approval.map((approve: Booking) =>
              approve.id === booking.id
                ? {
                    ...approve,
                    users: approve.users.map((bookingUser: BookingUser) =>
                      bookingUser.id === user.id
                        ? {
                            ...bookingUser,
                            approved: true
                          }
                        : bookingUser
                    )
                  }
                : approve
            );
            openApproval(newApproval);
            setInApporval(
              inApproval.map((bookingTicket: Booking) =>
                bookingTicket.id === booking.id
                  ? {
                      ...bookingTicket,
                      users: bookingTicket.users.map((bookingUser: BookingUser) =>
                        bookingUser.id === user.id
                          ? {
                              ...bookingUser,
                              approved: true
                            }
                          : bookingUser
                      )
                    }
                  : bookingTicket
              )
            );
          }
          messageService.success(`Successfully approved ${booking.educationName} for ${user.name}`);
        })
        .catch(() =>
          messageService.error(`Something went wrong while approving ${booking.educationName} for ${user.name}`)
        );
    }
  };

  const rejectUserTicket = (booking: Booking, user: BookingUser) => {
    if (booking) {
      rejectUser(booking.id, user.id)
        .then(() => {
          if (approval) {
            const newApproval = approval.map((reject: Booking) =>
              reject.id === booking.id
                ? {
                    ...reject,
                    users: reject.users.filter((bookingUser: BookingUser) => bookingUser.id !== user.id)
                  }
                : reject
            );
            openApproval(newApproval);
          }
          messageService.warning(`Successfully rejected ${booking.educationName} for ${user.name}`);
        })
        .catch(() =>
          messageService.error(`Something went wrong while rejecting ${booking.educationName} for ${user.name}`)
        );
    }
  };

  return (
    <div className="page page-padding ticket-administration">
      <Helmet>
        <title>FEP: Ticket Administration</title>
      </Helmet>
      <div className="page-container">
        <Card>
          <CardHeader>
            <h2>Ticket Administration</h2>
          </CardHeader>
          <CardContent>
            {" "}
            <div className="toggles">
              {hasRole(user, [ROLES.COACH]) ? (
                <div className="department-toggle">
                  <span>All</span>
                  <Toggle enabled={departmentToggle} onStateChange={(state) => setDepartmentToggle(state)} />
                  <span>My Department</span>
                </div>
              ) : null}
              <label>
                <AxxesDropdown
                  options={selecteableYears.map((year) => ({
                    label: `${year || "All"}`,
                    value: year
                  }))}
                  selectedItem={year}
                  onChange={(year) => setYear(year)}
                />
              </label>
            </div>
            <LoadingBlock loading={loading}>
              <div className="ticket-administration-container">
                <div className="ticket-administration-lanes">
                  <div className="needs-approval">
                    <h3>Needs approval</h3>
                    <div className="tickets">
                      {inApproval.length ? (
                        groupByEducation(inApproval).map(([educationName, bookings]) => (
                          <AdministrationApprovalTicket
                            key={educationName}
                            educationName={educationName}
                            educationBookings={bookings}
                            approveEducation={openApprove}
                          />
                        ))
                      ) : (
                        <p>No Actions available</p>
                      )}
                    </div>
                  </div>
                  <div className="ticket-handling">
                    <h3>Ticket Handling</h3>
                    <div className="tickets">
                      {ticketHandling.length ? (
                        groupByEducation(ticketHandling).map(([educationName, bookings]) => (
                          <AdministrationHandlingTicket
                            key={educationName}
                            educationName={educationName}
                            educationBookings={bookings}
                            handle={openHandle}
                            close={openClose}
                          />
                        ))
                      ) : (
                        <p>No Actions available</p>
                      )}
                    </div>
                  </div>
                  <div className="done">
                    <h3>Done</h3>
                    <div className="tickets">
                      {done.length ? (
                        groupByEducation(done).map(([educationName, bookings]) => (
                          <AdministrationDoneTicket
                            key={educationName}
                            educationName={educationName}
                            bookings={bookings}
                          />
                        ))
                      ) : (
                        <p>No Actions available</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </LoadingBlock>
            <div className="legend">
              <div>
                <TicketAdministrationIcon value={REQUIREMENT_ENTRY} />
                Tickets have to be booked for this ticket option.
              </div>
              <div>
                <TicketAdministrationIcon value={REQUIREMENT_TRANSPORT} />
                Transport has to be arranged to get to the specific education venue.
              </div>
              <div>
                <TicketAdministrationIcon value={REQUIREMENT_ACCOMMODATION} />
                Because the education lasts longer than a day in a foreign location, a hotel needs to be booked.
              </div>
              <div>
                <TicketAdministrationIcon value={REQUIREMENT_VENUE} />A venue has to be arranged for this specific
                ticket option (eg. Entrepot 9 or external conference hall).
              </div>
              <div>
                <TicketAdministrationIcon value={REQUIREMENT_OTHER} />
                Everything that does not fall under the other categories. The actual requirement will be mentioned down
                under the extra information section.
              </div>

              <div style={{ marginBottom: "24px" }}>
                <b>Book before: </b>
                Some of the entry tickets must be ordered within a limited time frame so that we can enjoy a discount or
                other benefits (eg. Early bird tickets). It is best to order those tickets before a certain date in
                order to not miss the attached benefits.
              </div>
              <div>
                <b>Needs Approval: </b>
                In this step the participation of the attendees must be approved. This approval will most likely be done
                by coaches and/or captains.
              </div>
              <div>
                <b>Ticket Handling: </b>
                Here each ticket option must be treated separately. All necessary requirements for each ticket option
                need to be fulfilled in order to complete the ticket. Possible ticket options:{" "}
                <u>Buying entry tickets, Booking a hotel, Arrange transport, etc.</u> Very basic ticket options can be
                handled by coaches and/or captains (eg Booking meeting room or Entrepot 9) but mostly Administration
                will handle the requirements for each ticket option.
              </div>
              <div>
                <b>Done: </b>
                This step means that all necessary requirements <u>have been fulfilled and booked</u>. This also means
                all stakeholders (coaches/captains, consultants and admin) have been notified of the status of their
                ticket for that specific education.
              </div>
            </div>
          </CardContent>
        </Card>
        <ApproveModal
          open={!!approval.length}
          bookings={approval}
          educationName={approval[0]?.educationName}
          onClose={openApproval.bind(this, [])}
          onComplete={approveBookings}
          onApprove={approveUserTicket}
          onReject={rejectUserTicket}
        />
        <CloseModal
          open={!!closing}
          booking={closing}
          onClose={openClosing.bind(this, undefined)}
          onComplete={closeTicketOption}
        />
        <HandlingBookingModal
          booking={handling}
          open={!!handling}
          onClose={openHandling.bind(this, undefined)}
          onComplete={handleBooking}
        />
      </div>
    </div>
  );
};

export default TicketAdministration;
