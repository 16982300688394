import React from "react";
import { Menu, MenuItem } from "react-bootstrap-typeahead";
import User from "../../domain/user";
import { searchUsers } from "../../services/user";
import { AsyncTypeahead } from "../Form";
import ProfilePicture from "../ProfilePicture";

interface Props {
  multiple?: boolean;
  clearButton?: boolean;
  allowNew?: boolean;
  onChangeHandler: (value: User | User[]) => void;
  label?: string;
  placeholder?: string;
  required?: boolean;
}

const UserSearch: React.FunctionComponent<Props> = ({
  multiple = true,
  clearButton = true,
  allowNew = false,
  onChangeHandler,
  label,
  placeholder = "Type",
  required = true
}) => (
  <AsyncTypeahead
    label={label}
    placeholder={placeholder}
    name="user"
    onSearch={(query) => searchUsers(undefined, query)}
    clearButton={clearButton}
    multiple={multiple}
    allowNew={allowNew}
    required={required}
    onChangeHandler={(users: User[] | User) => onChangeHandler(users)}
    labelKey={(option) => `${option.firstName} ${option.lastName}`}
    filterBy={() => true}
    renderMenu={(results: User[], menuProps: any) => (
      <Menu {...menuProps}>
        {results.map((user: any, index: number) => {
          return (
            <MenuItem option={user} position={index} key={index}>
              {user.customOption ? (
                user.label
              ) : (
                <>
                  <ProfilePicture user={user} size="small" />
                  {user.firstName} {user.lastName}
                </>
              )}
            </MenuItem>
          );
        })}
      </Menu>
    )}
  />
);

export default UserSearch;
