import { AxiosResponse } from "axios";
import axios from "axios";
import { DepartmentType } from "../domain/department";
import {
  CreateEducation,
  Education,
  EducationTicketOption,
  EducationExpense,
  EducationType,
  UpdateEducation,
  EducationState,
  TicketOptionBookingInfo
} from "../domain/education";
import http from "./http";

const BASE_URL = "/educations";

const searchEducations = async (
  departments: DepartmentType[] = [],
  educationTypes: EducationType[] = [],
  name?: string,
  tags: string[] = [],
  maxApCost: number = 0,
  from?: Date,
  to?: Date
): Promise<{
  result?: Education[];
  cancelPrevQuery?: boolean;
}> => {
  try {
    let queryString = departments
      .map((department) => `departments=${department}&`)
      .reduce((acc, next) => acc + next, "");
    queryString = educationTypes
      .map((type) => `types=${type}&`)
      .reduce((acc, next) => acc + next, queryString);
    queryString = tags
      .map((type) => `tags=${type}&`)
      .reduce((acc, next) => acc + next, queryString);

    if (name) {
      queryString += `name=${name}&`;
    }
    if (maxApCost) {
      queryString += `maxApCost=${maxApCost}&`;
    }
    if (from) {
      queryString += `from=${from.toISOString()}&`;
    }
    if (to) {
      queryString += `to=${to.toISOString()}&`;
    }

    const result = await http
      .get<Education[]>(`${BASE_URL}/search?${queryString}`)
      .then(({ data }: AxiosResponse<Education[]>) => data);
    return { result };
  } catch (err) {
    if (axios.isCancel(err)) return { cancelPrevQuery: true };
    throw err;
  }
};

const getEducations = (
  department?: string,
  year?: number
): Promise<Education[]> => {
  const url = `${BASE_URL}?${year ? "year=" + year : ""}${department ? "&department=" + department : ""
    }`;

  return http
    .get<Education[]>(url)
    .then(({ data }: AxiosResponse<Education[]>) => data);
};

const getEducationCost = (
  baseCost: number,
  halfDays: number,
  additionalCost: number
) => {
  const queryString = `?cost=${baseCost}&halfDays=${halfDays}&additionalCost=${additionalCost || 0
    }`;

  return http
    .get<number>(`${BASE_URL}/cost${queryString}`)
    .then(({ data }: AxiosResponse<number>) => data);
};

const getMostRecentEducations = () => {
  return http
    .get<Education[]>(BASE_URL)
    .then(({ data }: AxiosResponse<Education[]>) => data)
    .then((educations: Education[]) => Promise.resolve(educations.slice(0, 3)));
};

const getEducation = (id: string) => {
  return http
    .get<Education>(`${BASE_URL}/${id}`)
    .then(({ data }: AxiosResponse<Education>) => data);
};

const createEducation = (education: CreateEducation) => {
  return http.post(`${BASE_URL}`, education);
};

const uploadImage = (id: string, image: any) => {
  const data = new FormData();
  data.append("image", image);
  return http.put(`${BASE_URL}/${id}/image`, data);
};

const createVariant = (id: string, variant: EducationTicketOption) => {
  return http.post(`${BASE_URL}/${id}/ticket-options`, {
    name: variant.name,
    start: variant.start,
    end: variant.end,
    registrationDeadline: variant.registrationDeadline,
    price: {
      overruledApPrice: variant.price.overruledApPrice,
      cost: variant.price.cost,
      additionalCosts: variant.price.additionalCosts,
      halfDays: variant.price.halfDays
    },
    bookingInfo: variant.bookingInfo
  });
};

const updateVariant = (id: string, variant: EducationTicketOption) => {
  const encodedVariant = encodeURIComponent(variant.name);
  return http.put(`${BASE_URL}/${id}/ticket-options/${encodedVariant}`, {
    name: variant.name,
    start: variant.start,
    end: variant.end,
    registrationDeadline: variant.registrationDeadline,
    price: {
      overruledApPrice: variant.price.overruledApPrice,
      cost: variant.price.cost,
      additionalCosts: variant.price.additionalCosts,
      halfDays: variant.price.halfDays
    },
    bookingInfo: variant.bookingInfo
  });
};

const updateBookingInfo = (
  id: string,
  ticketOption: string,
  bookingInfo: TicketOptionBookingInfo
) => {
  const encodedTicketOption = encodeURIComponent(ticketOption);
  return http.put(
    `${BASE_URL}/${id}/ticket-options/${encodedTicketOption}/booking/info`,
    bookingInfo
  );
};

const removeVariant = (id: string, variant: EducationTicketOption) => {
  const encodedVariantName = encodeURIComponent(variant.name);
  return http.delete(`${BASE_URL}/${id}/ticket-options/${encodedVariantName}`);
};

const createExpense = (id: string, expense: EducationExpense) => {
  return http.post(`${BASE_URL}/${id}/expenses`, expense);
};

const removeExpenseForTicketOption = (id: string, expense: string) => {
  return http.delete(`${BASE_URL}/${id}/expenses/${expense}`);
};

const cancelEducation = (id: string) => {
  return http.delete(`${BASE_URL}/${id}`);
};

const updateEducation = (education: UpdateEducation) => {
  return http.put(`${BASE_URL}/${education.uuid}`, education);
};

const updateEducationImage = (id: string, image: any) => {
  const data = new FormData();
  data.append("image", image);
  return http.put(`${BASE_URL}/${id}/image`, data);
};

const publishEducation = (id: string) => {
  return http.put(`${BASE_URL}/${id}/state`, {
    state: "PUBLISHED"
  });
};

const copyEducation = (id: string, year: number, state: EducationState) => {
  return http.post(`${BASE_URL}/${id}/copy`, {
    year,
    state
  });
};

const subscribeOnWaitingList = (id: string, userId: string) => {
  return http.post(`${BASE_URL}/${id}/waiting-list`, {
    userId
  });
};

export {
  searchEducations,
  getEducations,
  getEducation,
  createEducation,
  getMostRecentEducations,
  uploadImage,
  getEducationCost,
  cancelEducation,
  updateEducation,
  updateEducationImage,
  publishEducation,
  createVariant,
  updateVariant,
  removeVariant,
  subscribeOnWaitingList,
  createExpense,
  removeExpenseForTicketOption,
  copyEducation,
  updateBookingInfo
};
