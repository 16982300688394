import { EducationTicketOption } from "../../../domain/education";

const variantsReducer = (state: any, action: any) => {
  switch (action.type) {
    case "add":
      return { variants: [...(state?.variants || []), action.payload] };
    case "update":
      return {
        variants: state.variants.map(
          (variant: EducationTicketOption, index: number) => ({
            ...(index === action.payload.index
              ? { ...action.payload.variant }
              : variant)
          })
        )
      };
    case "load":
      return {
        variants: state.variants,
        load: true
      };
    case "remove":
      return {
        variants: [
          ...state.variants.filter(
            (_: EducationTicketOption, index: number) =>
              index !== action.payload.index
          )
        ],
        load: false
      };
  }
};

export { variantsReducer };
