export type DepartmentType =
  | "DOTNET"
  | "DATA"
  | "FRONTEND"
  | "HR"
  | "ADMIN"
  | "INFRA"
  | "JAVA"
  | "OPENSOURCE"
  | "PM"
  | "TESTING"
  | "UNKNOWN";

export interface Department {
  label: string;
  value: DepartmentType;
}

export const DEPARTMENTS: Department[] = [
  { label: ".NET", value: "DOTNET" },
  { label: "Frontend", value: "FRONTEND" },
  { label: "HR", value: "HR" },
  { label: "ADMIN", value: "ADMIN" },
  { label: "Infra", value: "INFRA" },
  { label: "Java", value: "JAVA" },
  { label: "Open Source", value: "OPENSOURCE" },
  { label: "PM/Analyse", value: "PM" },
  { label: "Data", value: "DATA" },
  { label: "QA", value: "TESTING" }
];

export const getDepartmentLabel = (department?: string): string => {
  if (!department) {
    return "";
  }
  const foundDepartment = DEPARTMENTS.find((dep) => dep.value === department);
  return foundDepartment ? foundDepartment.label : "";
};
