import { Button, Card, CardContent, CardTitle } from "@axxes/design-system";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-form";
import * as yup from "yup";

import Modal from "../../components/Modal";
import { downloadFullExport, downloadRecupUsage } from "../../services/report";
import moment from "moment";

import "./administration.scss";
import messageService from "../../services/message.service";
import Loader from "../../components/Loader";
import { DatePicker, Field } from "../../components/Form";

const validation = yup.object().shape({
  startDate: yup.date().required(),
  endDate: yup.date().required()
});

const validate = (field: string): ((value: any) => boolean) => {
  return (value: any): boolean => {
    try {
      yup.reach(validation, field).validateSync(value);
      return false;
    } catch ({ errors }) {
      return errors[0];
    }
  };
};

const Administration: React.FunctionComponent = () => {
  const currentYear = new Date().getFullYear();
  const [exportModalOpen, toggleExportModal] = useState(false);
  const [selectedYear, selectYear] = useState<number>(currentYear);
  const [startDate, selectStartDate] = useState<Date>(
    moment().startOf("year").toDate()
  );
  const [endDate, selectEndDate] = useState<Date>(
    moment().endOf("year").toDate()
  );
  const [exportType, setExportType] = useState<"full" | "recup">();
  const [fullExportRunning, setFullRunning] = useState(false);
  const [recupExportRunning, setRecupRunning] = useState(false);

  const exportFile = async (year: number, start: Date, end: Date) => {
    try {
      toggleExportModal(false);
      if (exportType === "full") {
        setFullRunning(true);
        await exportFull(start, end);
        setFullRunning(false);
      } else {
        setRecupRunning(true);
        await exportRecupUsage(year);
        setRecupRunning(false);
      }
    } catch (e) {
      setFullRunning(false);
      setRecupRunning(false);

      messageService.error("Something went wrong while generating the export.");
    }
  };

  const exportRecupUsage = async (year: number) => {
    await downloadRecupUsage(year);
  };

  const exportFull = async (start: Date, end: Date) => {
    await downloadFullExport(start, end);
  };

  const { Form } = useForm({});

  return (
    <div className="page-padding administration-page">
      <Helmet>
        <title>FEP: Reports</title>
      </Helmet>
      <h2>Reports</h2>
      <Card customClasses="export-section">
        <CardTitle>Education</CardTitle>
        <CardContent>
          {!fullExportRunning ? (
            <Button
              accent={true}
              onClick={() => {
                setExportType("full");
                toggleExportModal(true);
              }}
            >
              Full export
            </Button>
          ) : (
            <Loader loading={fullExportRunning} />
          )}
          {!recupExportRunning ? (
            <Button
              accent={true}
              onClick={() => {
                setExportType("recup");
                toggleExportModal(true);
              }}
            >
              Export Recup Usage
            </Button>
          ) : (
            <Loader loading={recupExportRunning} />
          )}
        </CardContent>
      </Card>
      <Modal
        title={
          exportType === "recup" ? "Export Recup Days" : "Export Full overview"
        }
        open={exportModalOpen}
        onClose={() => toggleExportModal(false)}
        onSubmit={() => exportFile(selectedYear, startDate, endDate)}
        completeDisabled={!selectedYear}
        customClass="overflow-visible"
      >
        <Form>
          {exportType === "recup" ? (
            <Field
              name="year"
              label="Year"
              type="number"
              defaultValue={`${currentYear}`}
              required={true}
              onChangeHandler={({ target: { value } }) => selectYear(value)}
            />
          ) : (
            <>
              <div className="app-label">
                <DatePicker
                  label="From"
                  name="from"
                  selected={startDate}
                  validate={validate("startDate")}
                  onChangeHandler={selectStartDate}
                />
              </div>
              <div className="app-label">
                <DatePicker
                  label="To"
                  name="to"
                  selected={endDate}
                  minDate={startDate}
                  onChangeHandler={selectEndDate}
                />
              </div>
            </>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default Administration;
