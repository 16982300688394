import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-form";
import {
  faBan,
  faEyeSlash,
  faListUl,
  faPencilAlt,
  faTicketAlt,
  faTimes,
  faUpload
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import * as yup from "yup";

import Loader from "../../../components/Loader";
import AxxesPointLogo from "../../../components/AxxesPointLogo";
import {
  cancelEducation,
  copyEducation,
  createExpense,
  createVariant,
  getEducations,
  publishEducation,
  removeExpenseForTicketOption,
  removeVariant,
  updateBookingInfo,
  updateVariant
} from "../../../services/education";
import EditEducation from "../../../components/EditEducation";
import Modal from "../../../components/Modal";
import messageService from "../../../services/message.service";
import compareValue from "../../../utils/compare";
import "./educationManagement.scss";
import { Education, EducationTicketOption, EducationExpense, EducationState } from "../../../domain/education";
import formatDate from "../../../utils/date";
import CreateEducation from "../../../components/CreateEducation";
import TicketOption from "../../../components/TicketOption";
import { Button, Input } from "@axxes/design-system";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { faUserShield } from "@fortawesome/free-solid-svg-icons";
import Toggle from "../../../components/Toggle";
import UserContext from "../../../context/user";
import { hasRole, ROLES } from "../../../services/user";
import AxxesDropdown from "../../../components/AxxesDropdown";
import { DEPARTMENTS, DepartmentType, getDepartmentLabel } from "../../../domain/department";
import OptionMenu from "../../../components/OptionMenu";
import { Field, TextArea } from "../../../components/Form";
import { faCopy, faEye, faClipboard } from "@fortawesome/free-regular-svg-icons";
import { REQUIREMENT_OPTIONS } from "../../../components/EducationRequirements";

const positiveNumberValidation = yup.string().test("num", "Value must be a positive number", (val) => {
  if (!val) {
    return true;
  }
  return !Number.isNaN(val) && Number(val) >= 0;
});

const NOW = new Date();

const EducationManagement: React.FunctionComponent<{}> = () => {
  const [loading, setLoading] = useState(false);
  const [educations, refreshEducations] = useState<Education[]>([]);
  const [filteredEducations, setFilteredEducations] = useState<Education[]>([]);
  const [selectedEducation, setSelectedEducation] = useState<Education>();
  const [editEducationOpened, toggleEditEducation] = useState(false);
  const [confirmCancellation, toggleConfirmCancellation] = useState(false);
  const [confirmPublish, toggleConfirmPublish] = useState(false);
  const [confirmRemoveTicketOption, setConfirmRemoveTicketOption] = useState<string>();
  const [confirmCopy, toggleConfirmCopy] = useState(false);
  const [copyYear, setCopyYear] = useState(new Date().getFullYear() + 1);
  const [viewTicketOptions, toggleTicketOptions] = useState(false);
  const [viewExpenses, toggleExpenses] = useState(false);
  const [t, toggleAll] = useState(true);
  const [q, setQuery] = useState<string>();
  const { user } = useContext(UserContext);
  const [organisingDepartment, setOrganisingDepartment] = useState<DepartmentType | undefined>(user?.departments[0]);
  const [newTicketOption, setNewTicketOption] = useState<EducationTicketOption>();
  const [editingTicketOption, setEditingTicketOption] = useState<EducationTicketOption>();
  const [updatingTicketOption, setUpdatingTicketOption] = useState<EducationTicketOption>();
  const [updating, setUpdating] = useState(false);
  const [addingTicketOption, setAddingTicketOption] = useState(false);
  const [filterYear, setFilterYear] = useState(NOW.getMonth() >= 10 ? NOW.getFullYear() + 1 : NOW.getFullYear());

  const { Form, meta, reset } = useForm();
  const expensesInstance = useForm();
  const selecteableYears = [NOW.getFullYear() - 1, NOW.getFullYear(), NOW.getFullYear() + 1, NOW.getFullYear() + 2];

  async function fetchEducations(
    toggle: boolean,
    organDep: DepartmentType | undefined = organisingDepartment,
    year = filterYear
  ) {
    setLoading(true);
    let department;
    if (hasRole(user, [ROLES.COACH])) {
      department = toggle ? user?.departments[0] : undefined;
    } else {
      department = organDep;
    }
    const e = await getEducations(department, year);
    sortEducations(e);
    setEducations(e);
    filterEducations(e, q);
    setLoading(false);
    return e;
  }

  useEffect(() => {
    setAddingTicketOption(true);
    setTimeout(() => {
      setAddingTicketOption(false);
    }, 500);
  }, [editingTicketOption?.name]);

  useEffect(() => {
    fetchEducations(t, organisingDepartment, filterYear);
  }, [t, organisingDepartment, filterYear]);

  const updateTicketOptionValues = (ticketOption: EducationTicketOption) => {
    if (!editingTicketOption) {
      setNewTicketOption(ticketOption);
    } else {
      setUpdatingTicketOption(ticketOption);
    }
  };

  const sortEducations = (educations: Education[]) => {
    educations.sort((a, b) => {
      if (allTicketOptionsFinished(a) && !allTicketOptionsFinished(b)) {
        return 1;
      } else if (!allTicketOptionsFinished(a) && allTicketOptionsFinished(b)) {
        return -1;
      }
      return compareValue(a.name.toLowerCase(), b.name.toLowerCase());
    });
  };

  const openEditModal = (education: Education) => {
    setSelectedEducation(education);
    toggleEditEducation(true);
  };

  const openPublishModal = (education: Education) => {
    setSelectedEducation(education);
    toggleConfirmPublish(true);
  };

  const openExpense = (education: Education) => {
    setSelectedEducation(education);
    toggleExpenses(true);
  };

  const openTicketOptions = (education: Education) => {
    setSelectedEducation(education);
    toggleTicketOptions(true);
  };

  const setEducations = (ed: Education[]) => {
    refreshEducations(ed);
    setFilteredEducations(ed);
  };

  const cancel = (id: string) => {
    toggleConfirmCancellation(false);
    cancelEducation(id)
      .then(() => {
        setEducations(educations.filter((ed: Education) => ed.uuid !== id));
        messageService.success("Education Cancelled");
      })
      .catch(() => {
        messageService.error("Could not cancel the education. Please try again.");
      });
  };

  const addTicketOption = async () => {
    if (newTicketOption && selectedEducation) {
      try {
        setAddingTicketOption(true);
        await createVariant(selectedEducation.uuid || "", newTicketOption);
        setNewTicketOption(undefined);
        const updatedEduation: Education = {
          ...selectedEducation,
          ticketOptions: [...(selectedEducation?.ticketOptions || []), newTicketOption]
        };
        setSelectedEducation(updatedEduation);
        setFilteredEducations(
          filteredEducations.map((education) =>
            education.uuid === selectedEducation.uuid ? updatedEduation : education
          )
        );
        setTimeout(() => setAddingTicketOption(false), 1000);
        messageService.success(`Successfully created ${newTicketOption.name} Ticket option`);
      } catch (e) {
        setAddingTicketOption(false);
        messageService.error(`Unable to create Ticket option`);
      }
    }
  };

  const updateTicketOption = async () => {
    if (updatingTicketOption && selectedEducation) {
      setUpdating(true);
      await updateVariant(selectedEducation.uuid || "", updatingTicketOption);
      const updatedEduation: Education = {
        ...selectedEducation,
        ticketOptions: (selectedEducation?.ticketOptions || []).map((to) =>
          to.name === updatingTicketOption.name ? updatingTicketOption : to
        )
      };
      updateBookingInfo(selectedEducation.uuid, updatingTicketOption.name, updatingTicketOption.bookingInfo).catch(() =>
        messageService.error(`Something went wrong while updating the booking info for ${updatingTicketOption.name}`)
      );
      messageService.success(`Successfully updated ${updatingTicketOption.name} Ticket option`);
      setSelectedEducation(updatedEduation);
      setFilteredEducations(
        filteredEducations.map((education) => (education.uuid === selectedEducation.uuid ? updatedEduation : education))
      );
      setUpdatingTicketOption(undefined);
      setEditingTicketOption(undefined);
      setUpdating(false);
    }
  };

  const addExpense = async () => {
    try {
      if (selectedEducation) {
        await createExpense(selectedEducation.uuid, expensesInstance.values);
        const updatedEduation: Education = {
          ...selectedEducation,
          expenses: selectedEducation.expenses.find((expense) => expense.expense === expensesInstance.values.expense)
            ? selectedEducation.expenses.map((expense) =>
                expense.expense === expensesInstance.values.expense ? expensesInstance.values : expense
              )
            : [...selectedEducation.expenses, expensesInstance.values]
        };
        setSelectedEducation(updatedEduation);
        setFilteredEducations(
          filteredEducations.map((education) =>
            education.uuid === selectedEducation.uuid ? updatedEduation : education
          )
        );

        messageService.success(`Successfully created expense for ${selectedEducation.name}`);
      }
    } catch (e) {
      messageService.error(`Something went wrong while creating an expense for ${selectedEducation?.name}`);
    }
  };

  const removeTicketOption = async (education: Education, ticketOption: EducationTicketOption) => {
    await removeVariant(education.uuid, ticketOption);
    const updatedEduation: Education = {
      ...education,
      ticketOptions: [...(education?.ticketOptions || []).filter((to) => to.name !== ticketOption.name)]
    };
    setSelectedEducation(updatedEduation);
    setConfirmRemoveTicketOption(undefined);
    reset();
    setFilteredEducations(filteredEducations.map((ed) => (ed.uuid === education.uuid ? updatedEduation : ed)));
    messageService.success(`Successfully removed ${ticketOption.name} Ticket option`);
  };

  const removeExpense = async (education: Education, expense: EducationExpense) => {
    await removeExpenseForTicketOption(education.uuid, expense.expense);
    const updatedEduation: Education = {
      ...education,
      expenses: education.expenses.filter((es) => es.expense !== expense.expense)
    };
    setSelectedEducation(updatedEduation);
    setFilteredEducations(filteredEducations.map((ed) => (ed.uuid === education.uuid ? updatedEduation : ed)));
    messageService.success(`Successfully removed expense for ${education.name}`);
  };

  const publish = (education?: Education) => {
    toggleConfirmPublish(false);
    if (education) {
      publishEducation(education.uuid)
        .then(() => {
          setEducations(
            educations.map((ed: Education) => ({
              ...ed,
              state: ed.uuid === education.uuid ? "PUBLISHED" : ed.state
            }))
          );
          messageService.success(`${education.name} Successfully published`);
        })
        .catch(() => {
          messageService.error("Could not publish the education. Please try again later.");
        });
    }
  };

  const copy = (newYear: number, state: EducationState, education?: Education) => {
    toggleConfirmCopy(false);
    if (education) {
      copyEducation(education.uuid, newYear, state)
        .then(async ({ headers }) => {
          const newEducations = await fetchEducations(t, organisingDepartment);
          const newEducationId = headers.location.match(/\/educations\/(.*)$/)[1];
          const newEducation = newEducations.find((ed) => ed.uuid === newEducationId);
          if (newEducation) {
            openTicketOptions(newEducation);
          }
          messageService.success(`${education.name} Successfully Copied to ${copyYear}`);
        })
        .catch(() => {
          messageService.error(
            "Could not copy the education. Education probably already exists for the selected year."
          );
        });
    }
  };

  const openCancelConfirmation = (ed: Education) => {
    setSelectedEducation(ed);
    toggleConfirmCancellation(true);
  };

  const filterEducations = (educations: Education[], searchTerm?: string) => {
    setQuery(searchTerm);
    sortEducations(educations);
    setFilteredEducations(
      educations.filter((education: Education) =>
        searchTerm ? education.name.toLowerCase().includes(searchTerm.toLowerCase()) : true
      )
    );
  };

  const allTicketOptionsFinished = (education: Education) => {
    return (
      education.ticketOptions.length &&
      education.ticketOptions.filter((ticketOption) => moment().isAfter(ticketOption.end)).length ===
        education.ticketOptions.length
    );
  };

  const getEducationActions = (education: Education) => {
    const actions = [
      {
        label: (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end"
            }}
          >
            Modify ticket options
            <FontAwesomeIcon
              icon={faTicketAlt}
              style={{
                marginLeft: "5px",
                fontSize: "1.1em"
              }}
            />
          </span>
        ),
        action: () => openTicketOptions(education)
      },
      {
        label: (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end"
            }}
          >
            Edit Education
            <FontAwesomeIcon
              icon={faPencilAlt}
              style={{
                marginLeft: "5px",
                fontSize: "1.1em"
              }}
            />
          </span>
        ),
        action: () => openEditModal(education)
      },
      {
        label: (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end"
            }}
          >
            Duplicate Education
            <FontAwesomeIcon
              icon={faCopy}
              style={{
                marginLeft: "5px",
                fontSize: "1.1em"
              }}
            />
          </span>
        ),
        action: () => {
          setSelectedEducation(education);
          setCopyYear(NOW.getFullYear() + 1);
          toggleConfirmCopy(true);
        }
      }
    ];

    if (education.state === "UNPUBLISHED") {
      actions.push({
        label: (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end"
            }}
          >
            Publish Education
            <FontAwesomeIcon
              icon={faUpload}
              style={{
                marginLeft: "5px",
                fontSize: "1.1em"
              }}
            />
          </span>
        ),
        action: () => openPublishModal(education)
      });
    } else {
      actions.push({
        label: (
          <span
            title="enrollments"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end"
            }}
          >
            View Enrollments
            <FontAwesomeIcon
              className="education-action"
              icon={faListUl}
              style={{ marginLeft: "5px", fontSize: "1.1em" }}
            />
          </span>
        ),
        action: () => (location.href = `/educations/${education.uuid}/enrollments`)
      });

      /*
      Hidden to check with admin first
      actions.push({
        label: (
          <span
            title="expenses"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end"
            }}
          >
            View Expenses
            <FontAwesomeIcon
              icon={faCoins}
              style={{ marginLeft: "5px", fontSize: "1.1em" }}
            />
          </span>
        ),
        action: () => openExpense(education)
      });*/
    }
    actions.push({
      label: (
        <span
          title="Edit"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end"
          }}
        >
          View Education
          <FontAwesomeIcon className="education-action" icon={faEye} style={{ marginLeft: "5px", fontSize: "1.1em" }} />
        </span>
      ),
      action: () => (location.href = `/educations/${education.uuid}`)
    });
    if (navigator.clipboard) {
      actions.push({
        label: (
          <span
            title="Copy link"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end"
            }}
          >
            Copy link
            <FontAwesomeIcon
              className="education-action"
              icon={faClipboard}
              style={{ marginLeft: "5px", fontSize: "1.3em" }}
            />
          </span>
        ),
        action: () => {
          navigator.clipboard.writeText(`${window.location.origin}/educations/${education.uuid}`);
          messageService.success("Copied link to clipboard.");
        }
      });
    }
    actions.push({
      label: (
        <span
          title="Cancel"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end"
          }}
        >
          Cancel
          <FontAwesomeIcon className="education-action" icon={faBan} style={{ marginLeft: "5px", fontSize: "1.3em" }} />
        </span>
      ),
      action: () => openCancelConfirmation(education)
    });
    return actions;
  };

  const validate = (field: string, overrideValidation?: any): ((value: any) => boolean) => {
    return (value: any): boolean => {
      try {
        if (overrideValidation) {
          overrideValidation.validateSync(value);
        }
        return false;
      } catch ({ errors }) {
        return errors[0];
      }
    };
  };

  return (
    <div className="page page-padding">
      <Helmet>
        <title>FEP: Education management</title>
      </Helmet>
      <div className="page-container">
        <div className="education-management axxes-card">
          <div className="axxes-card__header">
            <h1>Educations</h1>
            <CreateEducation />
          </div>
          <div className="axxes-card__content">
            <div className="educations-filter">
              <Input placeholder="Search name" onChange={({ target }) => filterEducations(educations, target.value)} />
              <AxxesDropdown
                options={selecteableYears.map((year) => ({
                  label: `${year}`,
                  value: year
                }))}
                selectedItem={filterYear}
                onChange={(year) => {
                  setFilterYear(year);
                }}
              />
              {hasRole(user, [ROLES.COACH]) ? (
                <div className="education-toggle">
                  <span>All</span>
                  <Toggle
                    enabled={true}
                    onStateChange={(state) => {
                      toggleAll(state);
                    }}
                  />
                  <span>My Department</span>
                </div>
              ) : (
                <AxxesDropdown
                  options={[
                    {
                      label: "All",
                      value: null
                    },
                    ...DEPARTMENTS.filter((department) => department.value !== "ADMIN")
                  ]}
                  selectedItem={user?.departments[0]}
                  onChange={(dep) => {
                    setOrganisingDepartment(dep);
                  }}
                />
              )}
            </div>

            <Loader loading={loading} />
            {!loading && (
              <div className="educations-container">
                <div>
                  Total: <b>{educations.length}</b>
                </div>
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>State</th>
                      <th>Organiser</th>
                      <th>Enrollments</th>
                      <th>Ticket options</th>
                      <th className="actions-column" />
                    </tr>
                  </thead>
                  <tbody>
                    {filteredEducations.map((education) => {
                      return (
                        <tr
                          key={education.uuid}
                          className={`education ${education.state} ${
                            education.maxAttendees && education.attendees >= education.maxAttendees
                              ? "attendees-reached"
                              : ""
                          }`}
                        >
                          <td className="education__name">
                            <a onClick={openEditModal.bind(this, education)} style={{ cursor: "pointer" }}>
                              {education.name}
                              {education.state === "UNPUBLISHED" ? (
                                <FontAwesomeIcon className="education-list__item-visibility" icon={faEyeSlash} />
                              ) : null}
                              {education.state === "PRIVATE" ? (
                                <FontAwesomeIcon className="education-list__item-visibility" icon={faUserShield} />
                              ) : null}
                              {allTicketOptionsFinished(education) ? (
                                <FontAwesomeIcon
                                  title="education finished"
                                  icon={faCheckSquare}
                                  className="education--finished"
                                />
                              ) : null}
                            </a>
                          </td>
                          <td className="education-state">
                            <div className={`axxes-badge ${education.state}`}>{education.state}</div>
                          </td>
                          <td>
                            {education.organisingDepartment && education.organisingDepartment !== "UNKNOWN" && (
                              <div className="axxes-badge">{getDepartmentLabel(education.organisingDepartment)}</div>
                            )}
                          </td>
                          <td className="attendees">
                            <a style={{ cursor: "pointer" }} href={`/educations/${education.uuid}/enrollments`}>
                              {education.attendees}
                              {education.maxAttendees ? `/${education.maxAttendees}` : ""}
                            </a>
                          </td>
                          <td>
                            <a style={{ cursor: "pointer" }} onClick={openTicketOptions.bind(this, education)}>
                              {education.ticketOptions.length}
                            </a>
                          </td>
                          <td className="actions-column">
                            <OptionMenu options={getEducationActions(education)} />
                          </td>
                        </tr>
                      );
                    })}
                    {!filteredEducations.length ? (
                      <tr>
                        <td colSpan={5}>No educations found</td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <Modal
            title={selectedEducation?.name || "Education"}
            open={confirmCancellation}
            onClose={() => toggleConfirmCancellation(false)}
            onSubmit={() => cancel(selectedEducation?.uuid || "")}
          >
            <p>
              Are you sure you want to cancel <b>{selectedEducation?.name}</b>?
            </p>
          </Modal>
          <Modal
            title={(selectedEducation?.name || "Education") + " Expenses"}
            open={viewExpenses}
            onClose={() => toggleExpenses(false)}
            hideComplete={true}
            size="superwide"
            confirmText="Complete"
          >
            <div className="expenses-modal">
              {selectedEducation ? (
                <div className="split-content">
                  <div className="expenses-form">
                    <expensesInstance.Form>
                      <Field
                        label="Expense"
                        name="expense"
                        required={true}
                        validate={validate("expense", yup.string().required("Expense is required"))}
                      />
                      <Field
                        label="Amount in €"
                        type="number"
                        name="cost"
                        required={true}
                        validate={validate("amount", positiveNumberValidation.required("Amount is required"))}
                      />
                      <TextArea label="Description" name="description" />
                    </expensesInstance.Form>
                    <Button disabled={!expensesInstance.meta.isValid} onClick={addExpense}>
                      Add Expense
                    </Button>
                  </div>
                  <div className="all-expenses">
                    {selectedEducation.expenses.length === 0 ? <p>No expenses configured</p> : null}
                    {selectedEducation.expenses.map((expense: EducationExpense, index: number) => (
                      <div className="education-expense" key={`expense_${index}`}>
                        <div className="body">
                          <h4>{expense.expense}</h4>
                          <b>€ {expense.cost}</b>
                          <p>{expense.description}</p>
                        </div>
                        {selectedEducation ? (
                          <button
                            className="axxes-button --color-delete action-button"
                            title="remove"
                            onClick={removeExpense.bind(this, selectedEducation, expense)}
                            style={{ marginLeft: "15px" }}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </button>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </Modal>
          <Modal
            title={(selectedEducation?.name || "Education") + " Ticket options"}
            open={viewTicketOptions}
            onClose={() => {
              toggleTicketOptions(false);
              setEditingTicketOption(undefined);
              setConfirmRemoveTicketOption(undefined);
            }}
            hideComplete={true}
            size="superwide"
            confirmText="Complete"
          >
            <div className="ticket-options-modal">
              <div className="ticket-option-form">
                <div className="ticket-options">
                  {updating || addingTicketOption ? (
                    <div className="loader-skeleton">
                      <Loader loading={true} />
                    </div>
                  ) : (
                    <Form>
                      <TicketOption
                        update={updateTicketOptionValues}
                        disableRemove={true}
                        remove={() => {}}
                        baseEducation={selectedEducation}
                        disableName={false}
                        index={(selectedEducation?.ticketOptions || []).length}
                        usedNames={(selectedEducation?.ticketOptions || []).map((to) => to.name)}
                        editingTicketOption={editingTicketOption}
                      />
                    </Form>
                  )}
                </div>
                {editingTicketOption ? (
                  <div className="edit-buttons">
                    <Button
                      disabled={
                        !meta.isValid || (!updatingTicketOption?.price?.cost && !updatingTicketOption?.price?.halfDays)
                      }
                      onClick={updateTicketOption}
                    >
                      Update Ticket Option
                    </Button>
                    <Button
                      variant="ghost"
                      onClick={() => {
                        setEditingTicketOption(undefined);
                        setUpdatingTicketOption(undefined);
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                ) : addingTicketOption ? null : (
                  <Button
                    disabled={
                      !newTicketOption ||
                      !!selectedEducation?.ticketOptions.find((to) => to.name === newTicketOption.name) ||
                      !meta.isValid ||
                      !newTicketOption?.price?.cost ||
                      !newTicketOption?.price?.halfDays
                    }
                    onClick={addTicketOption}
                  >
                    Add Ticket Option
                  </Button>
                )}
              </div>
              <div className="all-ticket-options">
                {selectedEducation && selectedEducation.ticketOptions.length === 0 ? (
                  <i>No ticket options available</i>
                ) : null}
                {selectedEducation &&
                  selectedEducation.ticketOptions.map((ticketOption, index) => (
                    <div className="ticket-option" key={`${ticketOption.name}_${index}`}>
                      <div className="body">
                        <div className="content">
                          <h4>{ticketOption.name}</h4>
                          <div>
                            {ticketOption.start
                              ? formatDate(ticketOption.start) +
                                (ticketOption.start !== ticketOption.end && ticketOption.end
                                  ? " - " + formatDate(ticketOption.end)
                                  : "-")
                              : null}
                            <b>
                              {ticketOption.start ? " " : ""}({ticketOption.price.halfDays} Half days)
                            </b>
                          </div>

                          {ticketOption.registrationDeadline ? (
                            <div className="ticket-option__duedate">
                              <b>Registration Deadline: </b>
                              {formatDate(ticketOption.registrationDeadline)}
                            </div>
                          ) : null}
                        </div>
                        <div className="credits">
                          <b style={{ marginRight: "5px" }}>
                            {ticketOption.price.overruledApPrice || ticketOption.price.credits}
                            {ticketOption.price.overruledApPrice ? (
                              <i style={{ marginLeft: "5px" }}>Overruled</i>
                            ) : null}
                          </b>
                          <AxxesPointLogo height="small" />
                        </div>
                        <div>
                          Costs:{" "}
                          <i style={{ fontSize: "" }}>
                            (Cost: &euro;{ticketOption.price.cost} + Additional Costs: &euro;
                            {ticketOption.price.additionalCosts})
                          </i>
                        </div>
                        <div>
                          Requirements:{" "}
                          <b>
                            {(ticketOption.bookingInfo?.tickets || [])
                              .map((x) => x.type)
                              .map((x: string) => REQUIREMENT_OPTIONS.find((option: any) => option.value === x))
                              .map((option: any) => option.label)
                              .join(", ")}
                          </b>
                          {!ticketOption?.bookingInfo?.tickets && <b>-</b>}
                        </div>
                      </div>
                      <div className="ticket-option-actions" style={{ display: "flex", alignItems: "center" }}>
                        <Button
                          onClick={() => {
                            setConfirmRemoveTicketOption(undefined);
                            setEditingTicketOption(ticketOption);
                          }}
                        >
                          <FontAwesomeIcon icon={faPencilAlt} />
                        </Button>

                        {confirmRemoveTicketOption === ticketOption.name ? (
                          <button
                            className="axxes-button --color-delete action-button"
                            title="remove"
                            onClick={removeTicketOption.bind(this, selectedEducation, ticketOption)}
                            style={{ marginLeft: "15px" }}
                            disabled={
                              ticketOption.registrationDeadline &&
                              moment(ticketOption.registrationDeadline).isBefore(new Date())
                            }
                          >
                            Confirm
                          </button>
                        ) : (
                          <button
                            className="axxes-button --color-delete action-button"
                            title="remove"
                            onClick={setConfirmRemoveTicketOption.bind(this, ticketOption.name)}
                            style={{ marginLeft: "15px" }}
                            disabled={
                              ticketOption.name === editingTicketOption?.name ||
                              (ticketOption.registrationDeadline &&
                                moment(ticketOption.registrationDeadline).isBefore(new Date()))
                            }
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </Modal>
          <Modal
            title={`Publish ${selectedEducation?.name || "Education"}`}
            open={confirmPublish}
            onClose={() => toggleConfirmPublish(false)}
            onSubmit={() => publish(selectedEducation)}
          >
            <p>
              Are you sure you want to publish <b>{selectedEducation?.name}</b>?
            </p>
          </Modal>
          <Modal
            title={`Copy ${selectedEducation?.name || "Education"}`}
            open={confirmCopy}
            hideCancel={true}
            hideComplete={true}
            onClose={() => toggleConfirmCopy(false)}
          >
            <div className="copy-education">
              <label>Copy To</label>
              <AxxesDropdown
                options={[
                  (selectedEducation?.year || NOW.getFullYear()) + 1,
                  (selectedEducation?.year || NOW.getFullYear()) + 2
                ].map((year) => ({
                  label: `${year}`,
                  value: year
                }))}
                selectedItem={copyYear}
                onChange={(year) => setCopyYear(year)}
              />
              <p>
                Are you sure you want to copy <b>{selectedEducation?.name}</b> to <b>{copyYear}</b>?
              </p>
              <footer>
                <Button onClick={() => copy(copyYear, "UNPUBLISHED", selectedEducation)}>Copy as draft</Button>
                <Button onClick={() => copy(copyYear, "PUBLISHED", selectedEducation)} accent={true}>
                  Copy and publish
                </Button>
              </footer>
            </div>
          </Modal>
          {selectedEducation ? (
            <EditEducation
              modalOpened={editEducationOpened}
              onCancel={() => {
                toggleEditEducation(false);
                setSelectedEducation(undefined);
              }}
              onComplete={() => {
                toggleAll(t);
                toggleEditEducation(false);
                setSelectedEducation(undefined);
                messageService.success(`You successfully edited ${selectedEducation.name}`);
              }}
              baseEducation={selectedEducation}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default EducationManagement;
