import { AxiosResponse } from "axios";
import { EducationEnrollments } from "../domain/education";
import http from "./http";

const BASE_URL = "/enrollments";

const getEducationEnrollments = (educationId: string) => {
  return http
    .get<EducationEnrollments>(`${BASE_URL}/${educationId}`)
    .then(({ data }: AxiosResponse<EducationEnrollments>) => data);
};

export { getEducationEnrollments };
