import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { Token } from "react-bootstrap-typeahead";

import "./education-requirements.scss";
import { Typeahead } from "../Form";
import TicketAdministrationIcon from "../TicketAdministrationIcon";

interface Props {
  requirements?: string[];
  editing?: boolean;
  requirementsChanged: (requirements: string[]) => void;
}

const EducationRequirements: React.FunctionComponent<Props> = ({
  requirements = [],
  requirementsChanged
}) => {
  const [newRequirement, setNewRequirement] = useState<string>();
  let requirement: HTMLInputElement | null;

  const addNewRequirement = (event: any) => {
    event.preventDefault();
    if (requirement && newRequirement) {
      requirementsChanged([...requirements, newRequirement]);
      setNewRequirement(undefined);
      requirement.value = "";
    }
  };

  const removeRequirement = (indexToRemove: number) => {
    requirementsChanged(
      requirements.filter((_, index) => index !== indexToRemove)
    );
  };

  return (
    <div className="education-requirements axxes-input__container">
      <div className="form__input-group">
        <label>Requirements ({requirements.length})</label>
        <div className="new-requirement axxes-input__field">
          <input
            className="axxes-input__field"
            type="text"
            placeholder="Type requirement"
            onChange={({ target: { value } }) => setNewRequirement(value)}
            ref={(el) => (requirement = el)}
          />
          <button
            className={`axxes-button --color-accent ${
              requirements.length > 0 ? "requirements-present" : ""
            }`}
            onClick={addNewRequirement}
            disabled={!newRequirement}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      </div>
      <div className="education-requirements-container">
        {!requirements.length ? (
          <div className="no-requirements">No Requirements given</div>
        ) : null}
        {requirements.map((req, index) => (
          <div className="education-requirement" key={index}>
            <span>{req}</span>
            <FontAwesomeIcon
              icon={faTrashAlt}
              onClick={() => removeRequirement(index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const REQUIREMENT_OPTION_OTHER = {
  label: "Other",
  value: "OTHER"
};

const REQUIREMENT_OPTIONS = [
  {
    label: "Venue",
    value: "VENUE"
  },
  {
    label: "Transport",
    value: "TRANSPORT"
  },
  {
    label: "Tickets",
    value: "ENTRY"
  },
  {
    label: "Hotel",
    value: "ACCOMMODATION"
  },
  REQUIREMENT_OPTION_OTHER
];

const TicketOptionRequirements: React.FunctionComponent<Props> = ({
  requirements = [],
  editing = false,
  requirementsChanged
}) => {
  const [usedRequirements, setRequirements] = useState<any[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [init, setInit] = useState<boolean>(true);

  useEffect(() => {
    if (editing && init) {
      if (requirements.length) {
        setInit(false);
      }
      setLoading(true);
      setRequirements(mappedRequirements(requirements));
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  }, [requirements.length]);

  const mappedRequirements = (req: string[]) =>
    req.map(
      (r: string) =>
        REQUIREMENT_OPTIONS.find((option) => option.value === r) ||
        REQUIREMENT_OPTION_OTHER
    );

  return (
    <div className="ticket-option-requirements axxes-input__container">
      <div className="form__input-group">
        <div className="new-requirement axxes-input__field">
          {!loading && (
            <Typeahead
              name="ticketOptionRequirements"
              label={`Requirements (${requirements.length})`}
              multiple={true}
              clearButton={true}
              options={REQUIREMENT_OPTIONS}
              paginate={false}
              allowNew={false}
              placeholder=""
              validate={() => false}
              defaultSelected={usedRequirements}
              onChangeHandler={(requirements) =>
                requirementsChanged(
                  requirements.map((option: any) => option.value)
                )
              }
              mapper={(option: any) => option}
              renderMenuItemChildren={(option: any) => {
                return (
                  <span>
                    <TicketAdministrationIcon value={option.value} />
                    <span className="label">{option.label}</span>
                  </span>
                );
              }}
              renderToken={(option: any, { onRemove }: any, index: number) => {
                return (
                  <Token key={index} onRemove={onRemove} option={option}>
                    <TicketAdministrationIcon value={option.value} />
                    <span className="label">{option.label}</span>
                  </Token>
                );
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export { TicketOptionRequirements, REQUIREMENT_OPTIONS };
export default EducationRequirements;
