import { useState, useEffect, useContext } from "react";
import { faListUl, faTh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EducationList from "../../components/EducationList";
import { searchEducations } from "../../services/education";
import { debounce, intersection } from "lodash";
import { useForm } from "react-form";
import moment from "moment";

import "./education-container.scss";
import EducationFilter, {
  DEPARTMENT_FILTER,
  TYPE_FILTER
} from "../../components/EducationFilter";
import Loader from "../../components/Loader";
import {
  Education,
  EducationType,
  parseEducationType
} from "../../domain/education";
import { DepartmentType } from "../../domain/department";
import { Button } from "@axxes/design-system";
import UserContext from "../../context/user";
import { DatePicker, RangeSlider } from "../../components/Form";
import Toggle from "../../components/Toggle";

const EducationListContainer = () => {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [educations, setEducations] = useState<Education[]>([]);
  const [filteredEducations, setFilteredEducations] = useState<Education[]>([]);
  const [listView, toggleList] = useState(true);
  const [maxCost, setMaxCost] = useState(0);
  const [from, setFrom] = useState<Date>();
  const [to, setTo] = useState<Date>();
  const [query, setQuery] = useState({});
  const [filters, setFilters] = useState<any>({});
  const formInstance = useForm();

  const { Form } = formInstance;

  const educationContainsTags = (education: Education, tags: string[]) => {
    if (intersection(education.tags, tags).length > 0) {
      return true;
    }
    return false;
  };

  const fetchEducations = async (
    departments: DepartmentType[] = [],
    educationTypes: EducationType[] = [],
    name?: string,
    tags: string[] = [],
    cost: number = 0,
    fromDate?: Date,
    toDate?: Date
  ) => {
    setLoading(true);
    try {
      const { result, cancelPrevQuery } = await searchEducations(
        departments,
        educationTypes,
        name,
        tags,
        cost,
        fromDate,
        toDate
      );
      if (cancelPrevQuery) return;

      const eds = result ? [...result] : [];
      const userPreferredTags = user?.preferences.preferredTags || [];

      eds.sort((ed1, ed2) => {
        if (
          educationContainsTags(ed1, userPreferredTags) &&
          !educationContainsTags(ed2, userPreferredTags)
        ) {
          return -1;
        }
        if (
          !educationContainsTags(ed1, userPreferredTags) &&
          educationContainsTags(ed2, userPreferredTags)
        ) {
          return 1;
        }
        return ed1.name.localeCompare(ed2.name);
      });

      setTimeout(() => {
        setEducations(eds);
        setFilteredEducations(eds);
        setLoading(false);
      }, 1000);
    } catch {
      setLoading(false);
    }
  };

  const onChange = (
    departments: DepartmentType[] = [],
    educationTypes: EducationType[] = [],
    name?: string,
    tags: string[] = [],
    cost: number = 0,
    fromDate?: Date,
    toDate?: Date
  ) => {
    const search = debounce(fetchEducations, 300);
    setFilters({
      departments,
      educationTypes,
      name,
      tags,
      maxCost: cost,
      from: fromDate,
      to: toDate
    });

    setQuery((prevSearch: any) => {
      if (prevSearch.cancel) {
        prevSearch.cancel();
      }
      return search;
    });

    search(departments, educationTypes, name, tags, cost, fromDate, toDate);
  };

  useEffect(() => {
    const departments = JSON.parse(
      localStorage.getItem(DEPARTMENT_FILTER) || "[]"
    );
    const types = JSON.parse(localStorage.getItem(TYPE_FILTER) || "[]");
    let educationTypes = [];
    if (types.length) {
      educationTypes = types.map(parseEducationType);
    }
    fetchEducations(departments, educationTypes);
  }, []);

  useEffect(() => {
    onChange(
      filters.departments,
      filters.educationTypes,
      filters.name,
      filters.tags,
      maxCost,
      filters.from,
      filters.to
    );
  }, [maxCost]);

  useEffect(() => {
    if (from && !to) {
      setTo(moment(from).add(6, "months").toDate());
    } else {
      onChange(
        filters.departments,
        filters.educationTypes,
        filters.name,
        filters.tags,
        filters.maxCost,
        from,
        filters.to
      );
    }
  }, [from]);

  useEffect(() => {
    onChange(
      filters.departments,
      filters.educationTypes,
      filters.name,
      filters.tags,
      filters.maxCost,
      filters.from || from,
      to
    );
  }, [to]);

  const onChangeTypes = (
    departments: DepartmentType[] = [],
    educationTypes: EducationType[] = [],
    name?: string,
    tags: string[] = []
  ) => {
    onChange(
      departments,
      educationTypes,
      name,
      tags,
      filters.maxCost,
      filters.from,
      filters.to
    );
  };

  return (
    <div className="education-list-container">
      <div className="extra-filters-total">
        <div className="education-list-container-toggle-amount">
          found <b>{educations.length}</b> Education
          {educations.length > 1 || educations.length === 0 ? "s" : ""}
        </div>
        <div className="education-extra-filters">
          <Form>
            <RangeSlider
              name="cost"
              label="Maximum Cost"
              defaultValue={250}
              max={250}
              onChangeHandler={({ target }) => setMaxCost(target.value)}
            />
            <div className="from-to">
              <div className="app-label">
                <DatePicker
                  label="From"
                  name="from"
                  onChangeHandler={setFrom}
                />
              </div>
              <div>to</div>
              <div className="app-label">
                <DatePicker
                  label="To"
                  name="to"
                  onChangeHandler={setTo}
                  selected={to}
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
      <div className="education-list__header">
        <EducationFilter update={onChangeTypes} />
        <div className="education-list__header__toggle-wrapper">
          <Button
            onClick={toggleList.bind(this, true)}
            accent={true}
            variant={listView ? "default" : "ghost"}
          >
            <FontAwesomeIcon icon={faListUl} className="option-1" />
          </Button>
          <Button
            onClick={toggleList.bind(this, false)}
            accent={true}
            variant={!listView ? "default" : "ghost"}
          >
            <FontAwesomeIcon icon={faTh} className="option-2" />
          </Button>
        </div>
      </div>

      <Loader loading={loading} />
      {!loading && (
        <EducationList educations={filteredEducations} listView={listView} />
      )}
    </div>
  );
};

export default EducationListContainer;
