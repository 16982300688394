import { AxiosResponse } from "axios";
import http from "./http";
import { Bookings, BookingState } from "../domain/booking";
import { EducationTicketOptionRequirementType } from "../domain/education";
import { DepartmentType } from "../domain/department";

const BASE_URL = "/bookings";

const getBookings = (department?: DepartmentType, year?: number): Promise<Bookings> => {
  return http
    .get<Bookings>(
      `${BASE_URL}/view${year || department ? `?` : ""}${year ? `year=${year}&` : ""}${
        department ? `department=${department}` : ""
      }`
    )
    .then(({ data }: AxiosResponse<Bookings>) => data);
};

const updateBookingTicket = (id: string, ticketType: EducationTicketOptionRequirementType, state: BookingState) => {
  return http.post(`${BASE_URL}/${id}/tickets/${ticketType}`, {
    state
  });
};

const approveBooking = (id: string) => {
  return http.post(`${BASE_URL}/${id}/approve`);
};

const closeBooking = (bookingId: string, poNumber: string) => {
  return http.post(`${BASE_URL}/${bookingId}/close`, {
    bookingId,
    poNumber
  });
};

const approveUser = (bookingId: string, userId: string) => {
  return http.put(`${BASE_URL}/${bookingId}/users/${userId}/approve`);
};

const rejectUser = (id: string, userId: string) => {
  return http.put(`${BASE_URL}/${id}/users/${userId}/reject`);
};

export { getBookings, updateBookingTicket, approveBooking, closeBooking, approveUser, rejectUser };
