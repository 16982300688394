import { useState, useEffect, useContext } from "react";
import { searchEducations } from "../../services/education";

import "./my-education-container.scss";
import Loader from "../../components/Loader";
import { Education } from "../../domain/education";
import UserContext from "../../context/user";
import EducationSlider from "../../components/EducationSlider";

const MyEducationsContainer = () => {
  const { user } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [educations, setEducations] = useState<Education[]>([]);

  const fetchEducations = async () => {
    setLoading(true);
    try {
      const { result } = await searchEducations(
        user?.departments,
        [],
        undefined
      );

      setTimeout(() => {
        setEducations(result || []);
        setLoading(false);
      }, 1000);
    } catch {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEducations();
  }, []);

  return (
    <div className="education-slider-container">
      <Loader loading={loading} />
      {!loading && <EducationSlider educations={educations} />}
    </div>
  );
};

export default MyEducationsContainer;
