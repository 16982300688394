import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import Loader from "../../../components/Loader";
import Modal from "../../../components/Modal";
import ProfilePicture from "../../../components/ProfilePicture";
import UserEnrollment from "../../../components/UserEnrollment";
import { DepartmentType } from "../../../domain/department";
import { Education, EducationTicketOptionEnrollments } from "../../../domain/education";
import User, { WaitinglistUser } from "../../../domain/user";
import { getEducationEnrollments } from "../../../services/enrollment";
import messageService from "../../../services/message.service";
import { deleteConsultantsEducation, enrollUsers, searchUsers } from "../../../services/user";
import compareValue from "../../../utils/compare";

import "./enrollments.scss";
import CollapsableCard from "../../../components/CollapsableCard";
import { getDepartment } from "../../../components/DepartmentsSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import AxxesPointLogo from "../../../components/AxxesPointLogo";
import { useParams } from "react-router-dom";

const sortConsultants = (consultants: User[]): User[] => {
  const sortedConsultants = [...consultants];
  sortedConsultants.sort((a, b) =>
    compareValue(`${a.firstName} ${a.lastName}`.toLowerCase(), `${b.firstName} ${b.lastName}`.toLowerCase())
  );
  return sortedConsultants;
};

const sortWaitingConsultants = (consultants: WaitinglistUser[]): WaitinglistUser[] => {
  const sortedConsultants = [...consultants];
  sortedConsultants.sort((a, b) => compareValue(a.name.toLowerCase(), a.name.toLowerCase()));
  return sortedConsultants;
};

const EducationEnrollment: React.FunctionComponent<{}> = () => {
  const educationId = `${useParams().educationId}`;
  const [loading, setLoading] = useState(false);
  const [education, setEducation] = useState<Education>();
  const [educationEnrollments, setEducationEnrollments] = useState<EducationTicketOptionEnrollments[]>([]);
  const [enrolling, setEnrolling] = useState<boolean>(false);
  const [confirmingUnsubscribe, toggleConfirmation] = useState<boolean>(false);
  const [unsubscribingUser, setUnsubscribingUser] = useState<User>();
  const [unsubscribingTicketOption, setUnsubscribingTicketOption] = useState<string>();

  async function fetchEnrollments() {
    setLoading(true);
    try {
      const response = await getEducationEnrollments(educationId);
      setEducation(response.education);
      setEducationEnrollments(response.enrollments);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchEnrollments();
  }, [educationId]);

  const enrollUsersOrDepartment = async (users: User[], ticketOption: string, department?: DepartmentType) => {
    try {
      setEnrolling(true);
      if (education) {
        const userIds = department
          ? (await searchUsers(department)).map((user: User) => user.uuid)
          : users.map((user: User) => user.uuid);
        if (userIds.length) {
          await enrollUsers(userIds, education, ticketOption);
          messageService.success(
            `Successfully subscribed ${userIds.length} Users for ${education.name}: ${ticketOption}.`
          );
          fetchEnrollments();
        }
      }
    } finally {
      setEnrolling(false);
    }
  };

  const unsubscribe = async (education?: Education, ticketOption?: string) => {
    if (unsubscribingUser && education && ticketOption) {
      try {
        await deleteConsultantsEducation(unsubscribingUser.uuid, education.year, education.uuid, ticketOption).then(
          () => {
            fetchEnrollments();
          }
        );
        setUnsubscribingUser(undefined);
        messageService.success(
          `Education ${education.name}: ${ticketOption} successfully unsubscribed for ${unsubscribingUser.firstName} ${unsubscribingUser.lastName}.`
        );
      } catch ({ response }) {
        setUnsubscribingUser(undefined);
        setUnsubscribingTicketOption(undefined);

        messageService.error(
          response?.data?.message ||
            `Unable to unsubscribe ${unsubscribingUser.firstName} ${unsubscribingUser.lastName}. Please try again later.`,
          {
            timeOut: 15000
          }
        );
      }
    }
  };

  const getTotalEnrolledUsers = (ee: EducationTicketOptionEnrollments[]) => {
    return ee.map((enrollment) => enrollment.users.length).reduce((prev, x) => prev + x, 0);
  };

  return (
    <div className="page education-enrollments page-padding">
      <Helmet>
        <title>FEP: {education?.name || ""} enrollments</title>
      </Helmet>
      <div className="page-container">
        <Loader loading={loading} />
        {!loading && education ? (
          <div className="education-enrollments-content">
            <div className="education-enrollments-header">
              <h1>{education.name}</h1>
              {enrolling ? <Loader loading={true} /> : null}
            </div>
            {education.website && (
              <p>
                Original website url:{" "}
                <a href={education.website} target="_blank">
                  {education.website}
                </a>
              </p>
            )}
            <p>
              Below you can find all the ticket options with it's enrolled users for <b>{education.name}</b>.
            </p>
            <p>
              Total enrollments: <b>{getTotalEnrolledUsers(educationEnrollments)}</b>
            </p>
            <div className="education-enrollments-container">
              {educationEnrollments.map((enrollment) => (
                <CollapsableCard
                  key={enrollment.ticketOption}
                  className="education-ticket-option"
                  title={
                    <div className="education-ticket-option-header">
                      <a href={`/education/${education.uuid}`} target="_blank">
                        {enrollment.ticketOption}
                      </a>
                    </div>
                  }
                  extraElement={
                    <UserEnrollment
                      onEnroll={(users: User[], department?: DepartmentType) =>
                        enrollUsersOrDepartment(users, enrollment.ticketOption, department)
                      }
                      education={education}
                    />
                  }
                >
                  <div className="enrolled-users">
                    {sortConsultants(enrollment.users).map((user) => (
                      <div className="enrolled-user" key={user.uuid}>
                        <div className="content">
                          <h4>
                            <ProfilePicture user={user} size="small" />
                            {user.firstName} {user.lastName}
                          </h4>
                          <div className="lower">
                            <span className="axxes-badge department">{getDepartment(user.departments[0])?.label}</span>
                            <span className="credits">
                              <b>
                                {user.availableCredits}/{user.totalCredits}
                              </b>
                              <AxxesPointLogo height="small" />
                            </span>
                          </div>
                        </div>
                        <button
                          className="axxes-button --color-delete action-button"
                          title="Unsubscribe"
                          onClick={() => {
                            setUnsubscribingTicketOption(enrollment.ticketOption);
                            setUnsubscribingUser(user);
                            toggleConfirmation(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </button>
                      </div>
                    ))}
                    {enrollment.users.length === 0 ? <i>No users yet enrolled for this ticket</i> : null}
                  </div>
                </CollapsableCard>
              ))}
              <CollapsableCard collapsable={false} title="Waiting List">
                <div className="education-waiting-content">
                  <p>
                    Below you can find all users who are on the waiting list for:
                    <b> {education.name}</b>.
                  </p>
                  {education.waitingList.users.length ? (
                    <p>
                      Total Users waiting:
                      <b> {education.waitingList.users.length}</b>
                    </p>
                  ) : null}
                  <div className="education-waitinglist-container">
                    {sortWaitingConsultants(education.waitingList.users).map((user, index: number) => (
                      <div className="waiting-user" key={`user_${index}`}>
                        <div className="content">
                          <h4>{user.name}</h4>
                        </div>
                      </div>
                    ))}
                    {education.waitingList.users.length === 0 ? (
                      <i>No users yet subscribed for the waiting list</i>
                    ) : null}
                  </div>
                </div>
              </CollapsableCard>
            </div>
          </div>
        ) : null}
        {!loading && (!education || educationEnrollments.length === 0) ? (
          <div>
            <i>No ticket options yet configured for this education</i>
          </div>
        ) : null}
        <Modal
          open={confirmingUnsubscribe && !!unsubscribingUser}
          onClose={() => {
            setUnsubscribingUser(undefined);
            toggleConfirmation(false);
          }}
          onSubmit={() => {
            unsubscribe(education, unsubscribingTicketOption);
          }}
        >
          <p>
            You are about to unsubscribe{" "}
            <b>
              {unsubscribingUser?.firstName} {unsubscribingUser?.lastName}
            </b>{" "}
            for{" "}
            <b>
              {education?.name}: {unsubscribingTicketOption}
            </b>
            . Are you sure?
          </p>
        </Modal>
      </div>
    </div>
  );
};

export default EducationEnrollment;
