import React, { useContext } from "react";
import { Helmet } from "react-helmet";

import UserContext from "../../context/user";
import { hasRole, ROLES } from "../../services/user";
import ConsultantManagement from "../../containers/ConsultantManagement";
import HRDashboard from "../Dashboard/hr";

import "./consultant-management-page.scss";
import { useNavigate } from "react-router-dom";

const ConsultantManagementPage = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  if (!hasRole(user, [ROLES.HR, ROLES.COACH])) {
    return navigate("/dashboard");
  }
  if (hasRole(user, [ROLES.HR])) {
    return (
      <div className="page-container">
        <HRDashboard />
      </div>
    );
  }
  if (hasRole(user, [ROLES.ADMIN])) {
    return navigate("/reports");
  }
  return (
    <div className="consultant-management-page page page-padding">
      <Helmet>
        <title>FEP: Consultant Management</title>
      </Helmet>
      {user && (
        <div className="page-container">
          <ConsultantManagement department={user.departments[0]} />
        </div>
      )}
    </div>
  );
};

export default ConsultantManagementPage;
