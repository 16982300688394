import React, { useState } from "react";

import "./toggle.scss";

interface Props {
  enabled?: boolean;
  theme?: string;
  onStateChange?: (value: boolean) => void;
}

const Toggle: React.FunctionComponent<Props> = ({
  enabled = false,
  theme = "default",
  onStateChange = (value) => value
}) => {
  const [selected, setSelected] = useState<boolean>(enabled);
  const toggleSwitch = (event: any) => {
    event.preventDefault();
    setSelected(!selected);
    onStateChange(!selected);
  };
  return (
    <div className="switch">
      <div
        role="switch"
        tabIndex={0}
        aria-checked={selected}
        className={`toggle ${theme}`}
        onClick={toggleSwitch}
        onKeyPress={toggleSwitch}
      >
        <span className={`slider ${selected ? "on" : "off"}`} />
      </div>
    </div>
  );
};

export default Toggle;
