import React from "react";
import { Routes as ReactRoutes, Route } from "react-router-dom";

import { PrivateRoute } from "../Route";

import Login from "../../pages/Login";
import NotFound from "../../pages/404";
import EducationOverview from "../../pages/Education/overview";
import EducationDetail from "../../pages/Education/detail";
import Dashboard from "../../pages/Dashboard";
import ConsultantManagement from "../../pages/ConsultantManagement";
import EducationManagement from "../../pages/Education/educationManagement";
import { ROLES } from "../../services/user";
import EducationEnrollment from "../../pages/Education/enrollment";
import Administration from "../../pages/Administration";
import Settings from "../../pages/Settings";
import EducationWaitingList from "../../pages/Education/waitinglist";
import TicketAdministration from "../../pages/TicketAdministration";
import EducationCreatePage from "../../pages/Education/create";

const Routes = () => {
  return (
    <ReactRoutes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/"
        element={<PrivateRoute component={Dashboard} roles={ROLES.ALL} />}
      />
      <Route
        path="/educations"
        element={<PrivateRoute component={EducationOverview} />}
      />
      <Route
        path="/dashboard"
        element={<PrivateRoute component={Dashboard} />}
      />
      <Route
        path="/consultants"
        element={<PrivateRoute component={ConsultantManagement} />}
      />
      <Route
        path="/educations/manage"
        element={
          <PrivateRoute
            component={EducationManagement}
            roles={[ROLES.COACH, ROLES.HR]}
          />
        }
      />
      <Route
        path="/educations/create"
        element={
          <PrivateRoute
            component={EducationCreatePage}
            roles={[ROLES.COACH, ROLES.HR]}
          />
        }
      />
      <Route
        path="/educations/:educationId/enrollments"
        element={
          <PrivateRoute
            component={EducationEnrollment}
            roles={[ROLES.COACH, ROLES.HR]}
          />
        }
      />
      <Route
        path="/educations/:educationId/waiting-list"
        element={
          <PrivateRoute
            component={EducationWaitingList}
            roles={[ROLES.COACH, ROLES.HR]}
          />
        }
      />
      <Route
        path="/educations/:educationId"
        element={<PrivateRoute component={EducationDetail} />}
      />
      <Route
        path="/reports"
        element={
          <PrivateRoute
            component={Administration}
            roles={[ROLES.COACH, ROLES.HR, ROLES.ADMIN]}
          />
        }
      />
      <Route
        path="/ticket-administration"
        element={
          <PrivateRoute
            component={TicketAdministration}
            roles={[ROLES.COACH, ROLES.HR, ROLES.ADMIN]}
          />
        }
      />
      <Route path="/settings" element={<PrivateRoute component={Settings} />} />
      <Route path="*" element={<NotFound />} />
    </ReactRoutes>
  );
};

export default Routes;
