import React, { useState } from "react";
import { Button } from "@axxes/design-system";
import { useForm } from "react-form";
import { Menu, MenuItem } from "react-bootstrap-typeahead";
import Modal from "../Modal";
import { AsyncTypeahead, TypeSelector } from "../Form";
import { searchUsers } from "../../services/user";
import User from "../../domain/user";
import ProfilePicture from "../ProfilePicture";

import "./user-enrollment.scss";
import { Education } from "../../domain/education";
import DepartmentsSelector from "../DepartmentsSelector";
import { DepartmentType } from "../../domain/department";

interface Props {
  education: Education;
  onEnroll: (users: User[], department?: DepartmentType) => void;
}

const UserEnrollment: React.FunctionComponent<Props> = ({
  education,
  onEnroll
}) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);
  const [department, setDepartment] = useState<DepartmentType>();
  const [enrolling, setEnrolling] = useState<string>("PERSONS");

  const { Form } = useForm();

  const enrollUser = () => {
    setModalOpen(false);
    if (enrolling === "PERSONS") {
      onEnroll(users);
    }
    if (enrolling === "DEPARTMENT" && department) {
      onEnroll([], department);
    }
  };

  return (
    <div className="user-enrollment">
      <Button accent={true} onClick={setModalOpen.bind(this, true)}>
        Enroll users
      </Button>
      <Modal
        open={modalOpen}
        onClose={setModalOpen.bind(this, false)}
        onSubmit={enrollUser}
        confirmText="Enroll"
        completeDisabled={
          enrolling === "PERSONS" ? users.length === 0 : !department
        }
      >
        <h2>Enroll for {education.name}</h2>
        <Form>
          <TypeSelector
            name="enrolling"
            defaultSelected={enrolling}
            onChangeHandler={setEnrolling}
            options={[
              { text: "Persons", value: "PERSONS" },
              { text: "Department", value: "DEPARTMENT" }
            ]}
          />
          {enrolling === "PERSONS" ? (
            <AsyncTypeahead
              label="User"
              name="user"
              onSearch={(query) => searchUsers(undefined, query)}
              clearButton={true}
              multiple={true}
              onChangeHandler={(users: User[]) => setUsers(users)}
              labelKey={(option) => `${option.firstName} ${option.lastName}`}
              filterBy={() => true}
              renderMenu={(results: User[], menuProps: any) => (
                <Menu {...menuProps}>
                  {results.map((user: User, index: number) => (
                    <MenuItem option={user} position={index} key={index}>
                      <ProfilePicture user={user} size="small" />
                      {user.firstName} {user.lastName}
                    </MenuItem>
                  ))}
                </Menu>
              )}
            />
          ) : (
            <div className="department">
              <DepartmentsSelector
                multiple={false}
                label="Department"
                onChange={setDepartment}
                mapper={(x) => x && x.value}
              />
            </div>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default UserEnrollment;
