import { Card, CardContent, CardHeader } from "@axxes/design-system";
import { Booking } from "../../../domain/booking";

interface DoneProps {
  bookings: Booking[];
  educationName: string;
}

const AdministrationDoneTicket: React.FunctionComponent<DoneProps> = ({ educationName, bookings }) => (
  <Card customClasses="done-ticket">
    <CardHeader>
      <h4>{educationName}</h4>
    </CardHeader>
    <CardContent>
      <div className="axxes-badge department">{bookings[0].organisingDepartment}</div>
      <div>Handled {bookings.length} bookings</div>
    </CardContent>
  </Card>
);

export default AdministrationDoneTicket;
