import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

import "./tabs.scss";

interface Tab {
  tab: any;
  body: any;
  value?: any;
  deleteable?: any;
}

interface Props {
  tabs: Tab[];
  addTab: () => void;
  onDelete?: (tab: any) => void;
}

const Tabs: React.FunctionComponent<Props> = ({
  tabs,
  addTab,
  onDelete = (tab: any) => tab
}) => {
  const [activeTab, setActiveTab] = useState<Tab>(tabs[0]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    if (tabs.length) {
      setActiveTab(tabs[0]);
      setActiveTabIndex(0);
    }
  }, [tabs]);

  return (
    <div className="fep-tabs">
      <div className="fep-tabs__nav">
        {tabs.map((tab, index) => (
          <div
            className={`fep-tabs__nav__item ${
              activeTabIndex === index && "active"
            }`}
            onClick={() => {
              setActiveTab(tab);
              setActiveTabIndex(index);
            }}
            key={index}
          >
            <b>{tab.tab}</b>
            {tab.deleteable && tabs.length > 1 ? (
              <FontAwesomeIcon
                onClick={() => {
                  if (tabs[0]) {
                    setActiveTab(tabs[0]);
                    setActiveTabIndex(0);
                  }
                  onDelete(tab.value);
                }}
                icon={faTimes}
                className="fep-tabs__nav__item--action"
              />
            ) : (
              ""
            )}
          </div>
        ))}
        {addTab && (
          <div className="fep-tabs__nav__item" onClick={() => addTab()}>
            +
          </div>
        )}
      </div>
      <div className="fep-tabs__content">
        {activeTab ? activeTab.body : null}
      </div>
    </div>
  );
};

export default Tabs;
