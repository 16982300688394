import React, { useState } from "react";
import {
  Card,
  CardAction,
  CardContent,
  CardHeader,
  CardTitle
} from "@axxes/design-system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

import "./collapse-card.scss";

interface CollapsableCardProperties {
  children: any;
  open?: boolean;
  title: string | React.ReactElement;
  subTitle?: string | React.ReactElement;
  extraElement?: React.ReactElement;
  className?: string;
  collapsable?: boolean;
}

const CollapsableCard: React.FunctionComponent<CollapsableCardProperties> = ({
  open = true,
  title,
  subTitle,
  children,
  className = "",
  collapsable = true,
  extraElement
}) => {
  const [collapsed, setCollapsed] = useState(collapsable ? !open : false);

  function toggleCollapsed() {
    setCollapsed(!collapsed);
  }

  return (
    <div className={`collapsable-card ${className}`}>
      <Card>
        <CardHeader>
          {title ? (
            <div className="collapsable-card-title">
              <CardTitle>{title}</CardTitle>
              <div className="extra-element">{extraElement}</div>
            </div>
          ) : null}
          {collapsable ? (
            <CardAction>
              <FontAwesomeIcon
                onClick={toggleCollapsed}
                icon={collapsed ? faCaretDown : faCaretUp}
              />
            </CardAction>
          ) : null}
        </CardHeader>
        <CardContent>
          {subTitle ? (
            <div className="collapsable-card-subtitle">{subTitle}</div>
          ) : null}
          <div
            className={`collapsable-card-content ${collapsed ? "hidden" : ""}`}
          >
            <div className="content-body">{children}</div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default CollapsableCard;
