import React, { useState, useEffect, useContext } from "react";
import EducationTimeline from "../../components/EducationTimeline";

import UserContext from "../../context/user";
import { getCurrentEducationPlan, getEducationPlan, removeBookmark } from "../../services/user";
import Loader from "../../components/Loader";

import "./myEducationsOverview.scss";
import { EducationPlan, SimpleEducation } from "../../domain/education";
import CollapsableCard from "../../components/CollapsableCard";
import moment from "moment";
import Calendar from "react-calendar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import formatDate from "../../utils/date";
import { EducationBarChart } from "../../components/EducationChart";
import { EducationBookmark } from "../../domain/user";
import Star from "../../components/Star";
import messageService from "../../services/message.service";

import axxes from "../../assets/images/axxes_black.svg";
import { BookingTicket } from "../../domain/booking";
import TicketAdministrationIcon from "../../components/TicketAdministrationIcon";
import AxxesDropdown from "../../components/AxxesDropdown";

interface MiniEducationProps {
  education: SimpleEducation;
  showImage?: boolean;
}

const MiniEducation: React.FunctionComponent<MiniEducationProps> = ({ education, showImage = false }) => {
  return (
    <div className="fep-calendar-mini-education">
      {showImage ? (
        <div className="fep-calendar-mini-education-image">
          <img src={education.imageUrl || axxes} alt={education.educationName} />
        </div>
      ) : null}
      <div className="fep-calendar-mini-education-content">
        <h3>
          <a href={`/educations/${education.educationId}`}>{education.educationName}</a>
        </h3>
        {education.location ? (
          <div className="fep-calendar-mini-education-content-location">
            <FontAwesomeIcon icon={faMapMarkerAlt} /> {education.location}
          </div>
        ) : null}
        <div className="tickets">
          {education.tickets.map((ticket: BookingTicket) => (
            <TicketAdministrationIcon key={ticket.type} state={ticket.state} value={ticket.type} />
          ))}
        </div>
      </div>
    </div>
  );
};

const MyEducationsOverview = () => {
  const currentYear = new Date().getFullYear();
  const selecteableYears = [currentYear - 2, currentYear - 1, currentYear];
  const { user, updateUserContext } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [loadingPrevious, setLoadingPrevious] = useState(false);
  const [educationPlan, setEducationPlan] = useState<EducationPlan>();
  const [educationPlanForYear, setEducationPlanForYear] = useState<EducationPlan>();
  const [previousYearEnabled, setPreviousYearEnabled] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedYear, selectYear] = useState<number>(currentYear);

  const fetchEducations = async () => {
    if (user) {
      setLoading(true);
      try {
        const e = await getCurrentEducationPlan(user);
        setTimeout(() => {
          setEducationPlan(e);
        }, 1000);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
      return fetchEducations;
    }
  };

  const fetchPreviousPlan = async () => {
    if (user) {
      setLoadingPrevious(true);
      try {
        let epForYear: EducationPlan;

        if (selectedYear !== currentYear) {
          epForYear = await getEducationPlan(user, selectedYear);
        }

        setTimeout(() => {
          if (epForYear) {
            setEducationPlanForYear(epForYear);
          }
        }, 1000);
      } finally {
        setTimeout(() => {
          setLoadingPrevious(false);
        }, 1000);
      }
      return fetchPreviousPlan;
    }
  };

  const hasEducation = (date: Date) => {
    if (!educationPlan) {
      return false;
    } else {
      let result = false;
      for (let education of educationPlan.tickets) {
        if (education.startDate) {
          if (formatDate(date) === formatDate(education.startDate)) {
            result = true;
          }
        }
        if (education.endDate) {
          if (formatDate(date) === formatDate(education.endDate)) {
            result = true;
          }
        }
        if (!result && education.startDate && education.endDate) {
          result = moment(date).isBetween(education.startDate, education.endDate);
        }
        if (result) {
          return result;
        }
      }
    }

    return false;
  };

  const getEducationsForDate = (date: Date) => {
    if (educationPlan) {
      return educationPlan.tickets
        .filter((education) => !!education.startDate)
        .filter((education) => {
          return moment(date)
            .hours(12)
            .isBetween(moment(education.startDate).startOf("day"), moment(education.endDate).endOf("day"));
        });
    }
    return [];
  };

  const getUpcomingEducations = () => {
    if (educationPlan) {
      return educationPlan.tickets
        .filter((education) => !!education.startDate)
        .filter((education) => {
          return moment(education.endDate).isAfter(moment());
        });
    }
    return [];
  };

  const getPassedEducations = (e = educationPlan) => {
    const tickets = e?.tickets || [];
    return tickets
      .filter((education) => !!education.startDate)
      .filter((education) => {
        return moment(education.endDate).isBefore(moment());
      });
  };

  const getAdhocEducations = () => {
    if (educationPlan) {
      return educationPlan.tickets.filter((education) => !education.startDate);
    }
    return [];
  };

  const getBookmarkedEducations = () => {
    return user?.bookmarks || [];
  };

  const unstar = async (education: EducationBookmark) => {
    if (user) {
      await removeBookmark(user.uuid, education.educationId);
      updateUserContext({
        ...user,
        bookmarks: user.bookmarks.filter((bm: EducationBookmark) => bm.educationId !== education.educationId)
      });
      messageService.success(`Successfully removed bookmark for ${education.name}`);
    }
  };

  useEffect(() => {
    if (user) {
      fetchEducations();
    }
  }, [user?.uuid]);

  useEffect(() => {
    if (user) {
      fetchPreviousPlan();
    }
  }, [user?.uuid, selectedYear]);

  return (
    <div className="educations-overview-container">
      <main>
        <CollapsableCard title="Upcoming educations">
          <Loader loading={loading} />
          {!loading && (
            <EducationTimeline educationContainers={getUpcomingEducations()} onUnsubscribe={fetchEducations} />
          )}
        </CollapsableCard>
        <CollapsableCard className="adhoc-educations" title="Ad hoc educations">
          <Loader loading={loading} />
          {!loading &&
            getAdhocEducations().map((education) => (
              <MiniEducation key={education.educationId} education={education} showImage={true} />
            ))}
          {!loading && getAdhocEducations().length === 0 && "No Ad hoc educations"}
        </CollapsableCard>
        <CollapsableCard title={`Past educations (${getPassedEducations().length})`} open={false}>
          <Loader loading={loading || loadingPrevious} />
          {!loading && (
            <EducationTimeline educationContainers={getPassedEducations()} history={true} disableUnsubscribe={true} />
          )}
          <label>
            <input
              type="checkbox"
              onClick={(e: any) => {
                setPreviousYearEnabled(e.target.checked);
              }}
            />{" "}
            Enable previous year
          </label>
          {previousYearEnabled ? (
            <div>
              <label>
                Year
                <AxxesDropdown
                  options={selecteableYears.map((year) => ({
                    label: `${year}`,
                    value: year
                  }))}
                  selectedItem={currentYear}
                  onChange={(year) => selectYear(year)}
                />
              </label>
              {!loadingPrevious && (
                <EducationTimeline
                  educationContainers={getPassedEducations(educationPlanForYear)}
                  history={true}
                  disableUnsubscribe={true}
                />
              )}
            </div>
          ) : null}
        </CollapsableCard>
        <CollapsableCard title={`Statistics ${educationPlan?.year || ""}`}>
          <Loader loading={loading} />
          {!loading && educationPlan && <EducationBarChart educationPlan={educationPlan} />}
        </CollapsableCard>
      </main>
      <aside>
        <CollapsableCard
          title="FEP Balance"
          className={`fep-balance ${educationPlan && educationPlan.availableCredits < 0 ? "negative" : ""}`}
          collapsable={false}
        >
          <div className="fep-balance-year">
            For {educationPlan?.year} <span className="highlight">-</span> [ends {moment().endOf("year").fromNow()}]
          </div>
          <div className="fep-balance-credits">
            <div>
              <div className="title">Available</div>
              <h1>{educationPlan?.availableCredits}</h1>
            </div>
            <div>
              <div className="title">Total</div>
              <h1>{educationPlan?.assignedCredits}</h1>
            </div>
          </div>
        </CollapsableCard>
        <CollapsableCard title={`Bookmarked (${getBookmarkedEducations().length})`}>
          {!loading &&
            getBookmarkedEducations().map((education: EducationBookmark) => (
              <div className="simple-education-star" key={education.educationId}>
                <MiniEducation
                  key={education.educationId}
                  education={{
                    ...education,
                    educationName: education.name,
                    tickets: []
                  }}
                />
                <Star starred={true} onChange={() => unstar(education)} />
              </div>
            ))}
          {!loading && getBookmarkedEducations().length === 0 ? <div>No bookmarked educations</div> : null}
          <Loader loading={loading} />
        </CollapsableCard>
        <CollapsableCard title="Calendar" className="fep-calendar">
          {educationPlan ? (
            <Calendar
              tileClassName={({ date }) => (hasEducation(date) ? "active__education" : "")}
              onChange={(d: Date) => setSelectedDate(d)}
            />
          ) : (
            <Loader loading={loading} />
          )}
          <hr />
          <div className="fep-calendar-selecteddate">
            {getEducationsForDate(selectedDate).length === 0 ? "No educations on " : "Educations on "}{" "}
            <b>{formatDate(selectedDate)}</b>
          </div>
          <div>
            {getEducationsForDate(selectedDate).map((education) => (
              <MiniEducation key={education.educationId} education={education} />
            ))}
          </div>
        </CollapsableCard>
      </aside>
    </div>
  );
};

export default MyEducationsOverview;
