import { AxiosResponse } from "axios";
import moment from "moment";
import { saveAs } from "file-saver";
import http from "./http";

const formatDateForFile = (date: Date) => moment(date).format("yyyyMMDD");

export const downloadRecupUsage = (year: number) => {
  return http
    .get<any>(`/reports/recup-usage?year=${year}`, {
      responseType: "blob"
    })
    .then(({ data }: AxiosResponse<any>) => {
      saveAs(data, `${year}-recup-usage.csv`);
    });
};

export const downloadFullExport = (start: Date, end: Date) => {
  return http
    .get<any>(
      `/reports/full-export?from=${moment(start)
        .startOf("day")
        .toISOString()}&to=${moment(end).endOf("day").toISOString()}`,
      {
        responseType: "blob"
      }
    )
    .then(({ data }: AxiosResponse<any>) => {
      saveAs(
        data,
        `${formatDateForFile(start)}_${formatDateForFile(end)}-full-export.xls`
      );
    });
};
