import { useEffect, useState } from "react";
import { useForm } from "react-form";
import {
  Booking,
  BookingTicket,
  BookingTicketState,
  BookingUser
} from "../../../domain/booking";
import {
  EducationTicketOptionRequirementType,
  REQUIREMENT_ACCOMMODATION,
  REQUIREMENT_ENTRY,
  REQUIREMENT_OTHER,
  REQUIREMENT_TRANSPORT,
  REQUIREMENT_VENUE
} from "../../../domain/education";
import formatDate from "../../../utils/date";
import { TypeSelector } from "../../Form";
import Modal from "../../Modal";
import TicketAdministrationIcon from "../../TicketAdministrationIcon";

interface HandlingBookingModalProps {
  open: boolean;
  booking?: Booking;
  onClose: () => void;
  onComplete: (
    values: { [field: string]: BookingTicketState },
    booking?: Booking
  ) => void;
}

const isBookingComplete = (
  values: {
    [field: string]: BookingTicketState;
  },
  booking?: Booking
): boolean => {
  return (
    !!booking &&
    Object.values(values).filter(
      (state: BookingTicketState) => state === "ORDERED"
    ).length === booking.tickets.length
  );
};

const HandlingBookingModal: React.FunctionComponent<
  HandlingBookingModalProps
> = ({ booking, open, onClose, onComplete }) => {
  const [ticketbookingValues, setTicketbookingValues] = useState<{
    [field: string]: BookingTicketState;
  }>({});

  useEffect(() => {
    if (booking) {
      const values: {
        [field: string]: BookingTicketState;
      } = {};
      booking.tickets.forEach((ticket: BookingTicket) => {
        values[ticket.type] = ticket.state;
      });
      setTicketbookingValues(values);
    }
  }, [booking?.id]);

  const administrationForm = useForm({
    validatePristine: true,
    onSubmit: () => {}
  });

  return (
    <Modal
      open={open}
      title={`Handle ${booking?.educationName}: ${booking?.ticketOption}`}
      confirmText="Save"
      onClose={onClose}
      onSubmit={onComplete.bind(this, ticketbookingValues, booking)}
    >
      <p>
        Below you find all necessary requirements for{" "}
        <b>
          {booking?.educationName} {booking?.ticketOption}
        </b>
        .<br />
        Set every requirement in the right state and press <b>CONFIRM</b> in
        order to complete the processing. If any information is missing don't
        <br /> hesitate to contact the organising department of the education.
      </p>
      {booking?.location ? (
        <p>
          <b>
            Location
            <br />
          </b>
          {booking.location}
        </p>
      ) : null}
      {booking?.start || booking?.end ? (
        <p>
          <b>
            Start - End
            <br />
          </b>
          <b>
            {!!booking.start && !booking.end ? "Start: " : null}
            {!booking.start && !!booking.end ? "End: " : null}
          </b>
          {booking.start ? formatDate(booking.start) : null}
          {booking.start && booking.end ? " - " : null}
          {booking.end ? formatDate(booking.end) : null}
        </p>
      ) : null}
      {booking?.extraInfo ? (
        <p>
          <b>
            Extra information given by the creator
            <br />
          </b>
          {booking?.extraInfo}
        </p>
      ) : null}
      <div className="margin-b-12">
        <b>Consultants ({(booking?.users || []).length})</b>
        {(booking?.users || []).map((user: BookingUser) => (
          <div className="approval-booking-user" key={user.id}>
            <div>
              {user.photoUrl ? (
                <img src={user.photoUrl} alt="profile picture" />
              ) : null}
              {user.name}
            </div>
          </div>
        ))}
      </div>

      <div>
        <b>Actions</b>
        <administrationForm.Form>
          <div className="ticket-administration-types">
            {[
              {
                value: REQUIREMENT_ENTRY,
                label: "Tickets"
              },
              {
                value: REQUIREMENT_ACCOMMODATION,
                label: "Hotel/Accommodation"
              },
              {
                value: REQUIREMENT_TRANSPORT,
                label: "Transport"
              },
              {
                value: REQUIREMENT_VENUE,
                label: "Venue (Meeting/Conference room)"
              },
              {
                value: REQUIREMENT_OTHER,
                label: "Other (See extra information)"
              }
            ]
              .filter(
                (render) =>
                  !!(booking?.tickets || []).find(
                    (ticket) => ticket.type === render.value
                  )
              )
              .map(
                (render: {
                  value: EducationTicketOptionRequirementType;
                  label: string;
                }) => {
                  const active = (booking?.tickets || []).find(
                    (ticket) => ticket.type === render.value
                  );
                  return (
                    <div
                      key={render.value}
                      className={`administration-category`}
                    >
                      <div>
                        <TicketAdministrationIcon value={render.value} />{" "}
                        {render.label}
                      </div>
                      <div>
                        <TypeSelector
                          name="bookingState"
                          options={[
                            {
                              text: "UNORDERED",
                              value: "UNORDERED"
                            },
                            {
                              text: "ORDERING",
                              value: "ORDERING"
                            },
                            { text: "ORDERED", value: "ORDERED" }
                          ]}
                          defaultSelected={active?.state || "UNORDERED"}
                          onChangeHandler={(state: BookingTicketState) =>
                            setTicketbookingValues({
                              ...ticketbookingValues,
                              [render.value]: state
                            })
                          }
                        />
                      </div>
                    </div>
                  );
                }
              )}
          </div>
        </administrationForm.Form>
      </div>
      {isBookingComplete(ticketbookingValues, booking) ? (
        <p>
          <b>
            You are about to complete ALL actions for the current ticket option.
            This implies that the ticket can be closed. Please don't forget to
            do so. <br />
            <u>Are you sure?</u>
          </b>
        </p>
      ) : null}
      <div className="ticket-handling-legend">
        <div className="states">
          <div>
            <b>UNORDERED: </b>
            This state means that you haven't done anything yet
          </div>
          <div className="ordering">
            <b>ORDERING: </b>
            Some actions have waiting times at external partners
          </div>
          <div className="ordered">
            <b>ORDERED: </b>The action is complete
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default HandlingBookingModal;
