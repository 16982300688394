import React from "react";
import Loader from "../Loader";

interface Props {
  loading: boolean;
  children: any;
}

const LoadingBlock: React.FunctionComponent<Props> = ({
  loading = false,
  children
}) => {
  return (
    <div className="loading-block">
      <Loader loading={loading} />
      <div className="loaded">{!loading && children}</div>
    </div>
  );
};

export default LoadingBlock;
