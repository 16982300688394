import React, { useState, useRef, useEffect } from "react";

import "./AxxesDropdown.scss";

interface DropdownOption {
  label: string;
  value: any;
}

interface Props {
  options: DropdownOption[];
  selectedItem: any;
  onChange: (value: any) => void;
}

const AxxesDropdown: React.FunctionComponent<Props> = ({
  options = [],
  selectedItem,
  onChange = (value: any) => value
}) => {
  const foundItem = options.find((i: DropdownOption) => i.value === selectedItem);
  const [item, selectItem] = useState<DropdownOption>();
  const [open, toggleOpen] = useState(false);
  const [direction, setDirection] = useState<"UP" | "DOWN">("DOWN");
  const container = useRef<HTMLDivElement>(null);

  const change = (i: DropdownOption) => {
    selectItem(i);
    toggleOpen(false);
    onChange(i.value);
  };

  const toggle = () => {
    if (container) {
      if (container.current !== null) {
        const spaceBelow = window.innerHeight - container.current.getBoundingClientRect().bottom;
        if (spaceBelow < 300) {
          setDirection("UP");
        } else {
          setDirection("DOWN");
        }
        toggleOpen(false);
      }
    }
    toggleOpen(!open);
  };

  useEffect(() => {
    if (foundItem) {
      selectItem(foundItem);
    } else {
      selectItem(undefined);
    }
    document.body.addEventListener("click", (ev: any) => {
      if (container) {
        if (container.current !== null && !container.current.contains(ev.target)) {
          toggleOpen(false);
        }
      }
    });
  }, [selectedItem]);

  return (
    <div className={`axxes-custom-dropdown ${direction}`} ref={container}>
      <div className="axxes-custom-dropdown__selected-item axxes-custom-dropdown-item" onClick={() => toggle()}>
        {(item && <span className="axxes-custom-dropdown-item">{item && item.label}</span>) || (
          <span className="axxes-custom-dropdown-item axxes-custom-dropdown__no-selected-item">Select an option</span>
        )}
      </div>
      <ul className={`axxes-custom-dropdown__list ${open && "-open"}`}>
        {options.map((option) => (
          <li key={option.value} className="axxes-custom-dropdown__list__item" onClick={() => change(option)}>
            {option.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AxxesDropdown;
