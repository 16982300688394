import React, { useState, useEffect } from "react";
import { Route as ReactRoute, useNavigate } from "react-router-dom";
import { getUser, hasRole } from "../../services/user";
import UserContext from "../../context/user";
import Header from "../Header";
import Footer from "../Footer";
import User from "../../domain/user";

interface HeadlessRouteProps {
  path: string;
  component: any;
}

const HeadlessRoute: React.FunctionComponent<HeadlessRouteProps> = ({
  path,
  component
}) => {
  return <ReactRoute path={path} element={component} />;
};

interface RouteProps {
  component: any;
}

const Route: React.FunctionComponent<RouteProps> = ({ component }) => {
  const [user, setUser] = useState<User>();

  const updateUserContext = (updatedUser?: User) => {
    if (updatedUser) {
      setUser(updatedUser);
    } else {
      getUser().then((u: User) => {
        setUser(u);
      });
    }
  };

  useEffect(() => {
    updateUserContext();
  }, []);
  const Component = component;

  return (
    <main>
      <UserContext.Provider
        value={{
          user,
          updateUserContext
        }}
      >
        <Header />
        <div className="app-content">
          <Component />
        </div>
        <Footer />
      </UserContext.Provider>
    </main>
  );
};

const AUTHENTICATION_STEPS = {
  INIT: "INIT",
  AUTHENTICATING: "AUTHENTICATING",
  AUTHENTICATED: "AUTHENTICATED",
  UNAUTHORIZED: "UNAUTHORIZED"
};

interface PrivateRouteProps {
  component: any;
  roles?: string[];
}

const PrivateRoute: React.FunctionComponent<PrivateRouteProps> = ({
  component,
  roles = []
}) => {
  const [user, setUser] = useState<User>();
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(
    AUTHENTICATION_STEPS.AUTHENTICATING
  );

  const updateUserContext = (updatedUser?: User) => {
    if (updatedUser) {
      setUser(updatedUser);
    } else {
      setAuthenticated(AUTHENTICATION_STEPS.AUTHENTICATING);
      getUser()
        .then((u: User) => {
          setUser(u);
          setAuthenticated(AUTHENTICATION_STEPS.AUTHENTICATED);
          if (!hasRole(u, roles)) {
            navigate("/login");
          }
        })
        .catch(() => navigate("/login"));
    }
  };

  useEffect(() => {
    updateUserContext();
  }, []);

  const Component = component;

  if (authenticated === AUTHENTICATION_STEPS.AUTHENTICATED) {
    return (
      <main>
        <UserContext.Provider
          value={{
            user,
            updateUserContext
          }}
        >
          <Header />
          <div className="app-content">
            <Component />
          </div>
          <Footer />
        </UserContext.Provider>
      </main>
    );
  }
  return <div>Unauthorized</div>;
};

export { HeadlessRoute, Route, PrivateRoute, hasRole };
