import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-regular-svg-icons";

import { Education, EDUCATION_TYPES } from "../../domain/education";

import "./education-list.scss";
import AxxesPointLogo from "../AxxesPointLogo";
import axxes from "../../assets/images/axxes_black.svg";
import Website from "../Website";
import formatDate from "../../utils/date";
import { DEPARTMENTS, DepartmentType, getDepartmentLabel } from "../../domain/department";
import {
  faCalendarAlt,
  faInfoCircle,
  faMapMarkerAlt,
  faUser,
  faUserGraduate,
  faUserShield
} from "@fortawesome/free-solid-svg-icons";
import { Card, CardContent } from "@axxes/design-system";

interface ListProps {
  educations: Education[];
  listView: boolean;
}

const EducationList: React.FunctionComponent<ListProps> = ({ educations = [], listView = false }) => {
  return (
    <div className={`education-list ${listView ? "list" : "tiles"}`}>
      {educations.length === 0 ? <p>No educations found</p> : ""}
      {educations.map((education) =>
        listView ? (
          <EducationListItem key={education.uuid} education={education} />
        ) : (
          <EducationTileItem key={education.uuid} education={education} />
        )
      )}
    </div>
  );
};

interface ElementProps {
  education: Education;
}

const EducationListItem: React.FunctionComponent<ElementProps> = ({ education }) => {
  const [showTicketOptions, toggleTicketOptions] = useState(false);
  return (
    <div
      className={`education-list__item-container ${education.state}`}
      title={`${education.name} ${education.state !== "PUBLISHED" ? "is hidden for consultants" : ""}`}
    >
      <div className="education-list__item">
        <div className="education-list__item-image">
          {education.imageUrl ? (
            <img src={education.imageUrl} alt={education.name} />
          ) : (
            <img src={axxes} alt={education.name} />
          )}
        </div>
        <div className="education-list__item-content">
          <div className="education-list__item-header__basic-info">
            <div>
              <h2 className="education-list__item-name">
                <Link to={`/educations/${education.uuid}`}>{education.name}</Link>
                {education.state === "UNPUBLISHED" ? (
                  <FontAwesomeIcon className="education-list__item-visibility" icon={faEyeSlash} />
                ) : null}
                {education.state === "PRIVATE" ? (
                  <FontAwesomeIcon className="education-list__item-visibility" icon={faUserShield} />
                ) : null}
              </h2>

              {education.ticketOptions.length === 1 ? (
                <div className="education-list__item-dates">
                  {education.ticketOptions[0].start || education.ticketOptions[0].price?.halfDays ? (
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  ) : null}
                  {education.ticketOptions[0].start ? (
                    <div className="education-detail-dates">
                      <span>{formatDate(education.ticketOptions[0].start)}</span>
                      {education.ticketOptions[0].start !== education.ticketOptions[0].end && (
                        <span>
                          <span className="separator"> - </span>
                          <span>{formatDate(education.ticketOptions[0].end)}</span>
                        </span>
                      )}
                    </div>
                  ) : education.ticketOptions[0].price?.halfDays ? (
                    <div>
                      <b>Duration: </b>
                      {education.ticketOptions[0].price?.halfDays} Half day
                      {education.ticketOptions[0].price?.halfDays > 1 ? "s" : ""}
                    </div>
                  ) : null}
                </div>
              ) : null}
              {education.location ? (
                <div className="education-list__item-location">
                  <FontAwesomeIcon icon={faMapMarkerAlt} />
                  {education.location}
                </div>
              ) : null}
              {education.tutor ? (
                <div>
                  <FontAwesomeIcon icon={faUserGraduate} />
                  <i className="education-list__tutor">By {education.tutor}</i>
                </div>
              ) : null}
              {education.maxAttendees ? (
                <div
                  className={`education-list__item-attendees ${
                    education.attendees === education.maxAttendees ? "full" : ""
                  }`}
                >
                  <FontAwesomeIcon icon={faUser} /> {education.attendees ? `${education.attendees}/` : "0/"}
                  {education.maxAttendees}
                </div>
              ) : null}
              {education.website && (
                <Website url={education.website} className="education-list__item-info-url" prevent={true} />
              )}
              <div className="education-tags">
                <span className="axxes-badge education-type" title="TYPE">
                  {EDUCATION_TYPES[education.educationType]}
                </span>
                {education.departments?.length
                  ? education.departments.map((dep: DepartmentType) => (
                      <span className="axxes-badge department" key={dep} title="DEPARTMENT">
                        {getDepartmentLabel(dep)}
                      </span>
                    ))
                  : null}
                {education.tags?.length
                  ? education.tags.map((tag: string) => (
                      <span className="axxes-badge" key={tag} title="TAG">
                        {tag}
                      </span>
                    ))
                  : null}
              </div>
            </div>
          </div>
          {education.ticketOptions?.length === 1 ? (
            <div className="education-list__item-credits">
              <b>{education.ticketOptions[0].price.credits}</b> <AxxesPointLogo height="medium" />
            </div>
          ) : null}
          {education.ticketOptions?.length > 1 ? (
            <div className="education-list__item-multiple-ticket-options">
              <FontAwesomeIcon icon={faInfoCircle} />
              <i>Multiple ticket options</i> (click <a onClick={() => toggleTicketOptions(!showTicketOptions)}>here</a>)
            </div>
          ) : null}
          {education.ticketOptions?.length === 0 ? (
            <div className="education-list__item-multiple-ticket-options no-tickets">
              <i>No Ticket options available</i>
            </div>
          ) : null}
          {education.ticketOptions?.length === 1 && education.ticketOptions[0].registrationDeadline ? (
            <div className="education-list__item-info-duedate">
              <b>Registration Deadline: </b>
              {formatDate(education.ticketOptions[0].registrationDeadline)}
            </div>
          ) : null}
        </div>
      </div>
      {education.ticketOptions.length > 1 && showTicketOptions ? (
        <div className="education-list__item-ticket-options">
          {education.ticketOptions.map((ticketOption) => (
            <div className="education-list__item-ticket-options__item" key={ticketOption.name}>
              <h5 style={{ margin: "0", fontSize: "1.1em" }}>{ticketOption.name}</h5>
              <div>
                {ticketOption.start
                  ? formatDate(ticketOption.start) + (ticketOption.end ? " - " + formatDate(ticketOption.end) : "")
                  : ""}
              </div>
              <div className="education-list__item-ticket-options__item__duedate">
                <b>Registration Deadline: </b>
                {formatDate(ticketOption.registrationDeadline) || "-"}
              </div>
              <div className="credits">
                <b style={{ marginRight: "5px" }}>{ticketOption.price.credits}</b>
                <AxxesPointLogo height="small" />
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

const EducationTileItem: React.FunctionComponent<ElementProps> = ({ education }) => {
  return (
    <Card customClasses="education-list__tile">
      <CardContent>
        {education.ticketOptions?.length === 1 ? (
          <div className="education-list__item-credits">
            <b>{education.ticketOptions[0].price.credits}</b> <AxxesPointLogo height="medium" />
          </div>
        ) : (
          <div className="education-list__item-multiple-ticket-options">
            <Link to={`/educations/${education.uuid}`}>
              <FontAwesomeIcon icon={faInfoCircle} title="Multiple ticket options (see detail page for more info)" />
            </Link>
          </div>
        )}
        <div className="education-list__tile__content">
          <div className="education-list__item-image">
            {education.imageUrl ? (
              <img src={education.imageUrl} alt={education.name} />
            ) : (
              <img src={axxes} alt={education.name} />
            )}
          </div>

          <h1>
            <Link to={`/educations/${education.uuid}`}>{education.name}</Link>
          </h1>

          {education.location ? (
            <div className="education-list__item-location">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              {education.location}
            </div>
          ) : null}
          <div className="education-tags">
            <span className="axxes-badge education-type" title="TYPE">
              {EDUCATION_TYPES[education.educationType]}
            </span>
            {education.departments?.length
              ? education.departments.slice(0, 5).map((dep: DepartmentType) => (
                  <span className="axxes-badge department" key={dep} title="DEPARTMENT">
                    {getDepartmentLabel(dep)}
                  </span>
                ))
              : null}
            {education.departments?.length &&
            education.departments?.length > 5 &&
            education.departments?.length !== DEPARTMENTS.length - 1 ? (
              <span className="axxes-badge department">...</span>
            ) : null}
            {education.departments?.length && education.departments?.length === DEPARTMENTS.length - 1 ? (
              <span className="axxes-badge department">ALL</span>
            ) : null}
            {education.tags?.length
              ? education.tags.slice(0, 5).map((tag: string) => (
                  <span className="axxes-badge" key={tag} title="TAG">
                    {tag}
                  </span>
                ))
              : null}
            {education.tags?.length && education.tags?.length > 5 ? <span className="axxes-badge">...</span> : null}
          </div>
        </div>
        {education.ticketOptions?.length === 1 && education.ticketOptions[0].registrationDeadline ? (
          <div className="education-list__item-info-duedate">
            <b>Registration Deadline: </b>
            {formatDate(education.ticketOptions[0].registrationDeadline)}
          </div>
        ) : null}
      </CardContent>
    </Card>
  );
};

export { EducationTileItem };

export default EducationList;
