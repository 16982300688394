import React, { useEffect, useState } from "react";
import { useForm } from "react-form";
import "./educationFilter.scss";
import { DEPARTMENTS, DepartmentType } from "../../domain/department";
import { EducationType, EDUCATION_TYPE_OPTIONS } from "../../domain/education";
import { Typeahead } from "../Form";
import { Token } from "react-bootstrap-typeahead";
import { searchTags } from "../../services/tag";

export const DEPARTMENT_FILTER = "DEPARTMENT_FILTER";
export const TYPE_FILTER = "TYPES_FILTER";

interface Props {
  update: (
    departments: DepartmentType[],
    educationTypes: EducationType[],
    name?: string,
    tags?: string[]
  ) => void;
}

const EducationFilter: React.FunctionComponent<Props> = ({ update }) => {
  const [freeText, setFreeText] = useState(false);
  const [options, setOptions] = useState<any[]>([]);
  const formInstance = useForm();
  const defaultDepartments = JSON.parse(
    localStorage.getItem(DEPARTMENT_FILTER) || "[]"
  );
  const defaultEducationTypes = JSON.parse(
    localStorage.getItem(TYPE_FILTER) || "[]"
  );
  const { Form } = formInstance;

  const standardOptions = [
    ...DEPARTMENTS.map(({ label, value }) => ({
      value,
      label,
      type: "DEPARTMENT"
    })).filter((department) => department.value !== "ADMIN"),
    ...EDUCATION_TYPE_OPTIONS.map(({ label, value }) => ({
      value,
      label,
      type: "TYPE"
    }))
  ];

  useEffect(() => {
    async function getTags() {
      const tags = await searchTags("");

      setOptions([
        ...standardOptions,
        ...tags.map((tag: string) => ({
          label: tag,
          value: tag,
          type: "TAG"
        }))
      ]);
    }

    getTags();
  }, []);

  const updateFilter = (filters: any[]) => {
    const departments = filters
      .filter((option) => option.type === "DEPARTMENT")
      .map((option) => option.value);
    const educationTypes = filters
      .filter((option) => option.type === "TYPE")
      .map((option) => option.value);
    const tags = filters
      .filter((option) => option.type === "TAG")
      .map((option) => option.value);
    const text = filters.filter((option) => option.length)[0];
    if (text) {
      setFreeText(true);
    } else {
      setFreeText(false);
    }
    localStorage.setItem(DEPARTMENT_FILTER, JSON.stringify(departments));
    localStorage.setItem(TYPE_FILTER, JSON.stringify(educationTypes));
    update(departments, educationTypes, text, tags);
  };

  return (
    <div className="education-filter-container">
      <Form>
        <div>
          <Typeahead
            name="search"
            multiple={true}
            clearButton={true}
            allowNew={!freeText}
            placeholder="Filter By Department, Type, Tags or name"
            defaultSelected={[
              ...defaultDepartments.map((dep: any) =>
                standardOptions.find((option) => option.value === dep)
              ),
              ...defaultEducationTypes.map((type: any) =>
                standardOptions.find((option) => option.value === type)
              )
            ]}
            maxVisible={1000}
            onChangeHandler={updateFilter}
            options={options}
            renderMenuItemChildren={(option: any) => {
              return (
                <span className={option.type}>
                  {option.type === "TAG" ? "Tag: " : ""}
                  {option.label}
                </span>
              );
            }}
            renderToken={(option: any, { onRemove }: any, index: number) => {
              return (
                <Token
                  key={index}
                  onRemove={onRemove}
                  option={option}
                  className={option.customOption ? "CUSTOM" : option.type}
                >
                  {option.type === "TAG" ? "Tag: " : ""}
                  {option.customOption ? "Name: " : ""}
                  {option.label}
                </Token>
              );
            }}
          />
        </div>
      </Form>
    </div>
  );
};

export default EducationFilter;
