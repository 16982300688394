import React from "react";

import "./footer.scss";

import axxes from "../../assets/images/axxes_black.svg";

const Footer = () => {
  const now = new Date();
  return (
    <footer className="axxes-footer">
      <div>
        <img src={axxes} alt="Axxes logo" className="footer__logo" />
        <span>Copyright &copy; Axxes {now.getFullYear()}</span>
      </div>
    </footer>
  );
};

export default Footer;
