import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

import "./timeline.scss";
import { EducationPlanEducation } from "../../domain/education";
import formatDate from "../../utils/date";
import { faCalendarAlt, faClock } from "@fortawesome/free-regular-svg-icons";

import axxes from "../../assets/images/axxes_black.svg";
import EducationUnsubscribe from "../EducationUnsubscribe";
import Live from "../Live";
import { BookingTicket } from "../../domain/booking";
import TicketAdministrationIcon from "../TicketAdministrationIcon";

interface Props {
  educationContainers?: EducationPlanEducation[];
  history?: boolean;
  onUnsubscribe?: () => any;
  disableUnsubscribe?: boolean;
}

const EducationTimeline: React.FunctionComponent<Props> = ({
  educationContainers = [],
  history = false,
  onUnsubscribe = () => {},
  disableUnsubscribe = false
}) => {
  const getSortedEducations = () => {
    const educationsWithPeriod = educationContainers.filter((educationContainer) => educationContainer.endDate);
    educationsWithPeriod.sort((educationContainer1, educationContainer2) => {
      if (moment(educationContainer1.endDate).isSame(educationContainer2.endDate)) return 0;
      if (moment(educationContainer1.endDate).isBefore(educationContainer2.endDate)) return -1;
      return 1;
    });
    return educationsWithPeriod;
  };

  const canEnroll = (education: EducationPlanEducation) => {
    return !(
      !education.registrationDeadline ||
      education.educationType === "LICENCE" ||
      education.educationType === "ONLINE_COURSE" ||
      (education.registrationDeadline &&
        moment(education.registrationDeadline).hours(23).minutes(59).isBefore(new Date()))
    );
  };

  const isLive = (education: EducationPlanEducation) => {
    return (
      education &&
      education.startDate &&
      education.endDate &&
      moment(education.startDate).isBefore(new Date()) &&
      moment(education.endDate).isAfter(new Date())
    );
  };

  return (
    <div className="education-timeline">
      <div className="education-timeline__timeline">
        <div className="education-timeline__timeline-with-date">
          {getSortedEducations().map((education) => (
            <div className="education-timeline__timeline-entry" key={education.educationId}>
              <div className="education-timeline__timeline-entry__image">
                {education.imageUrl ? (
                  <img src={education.imageUrl} alt={education.educationName} />
                ) : (
                  <img src={axxes} alt={education.educationName} />
                )}
              </div>
              <div className="education-timeline__timeline-entry__content">
                <div className="education-timeline__timeline-entry__date">
                  {history ? formatDate(education.startDate) : moment(education.startDate).fromNow()}
                </div>
                <FontAwesomeIcon className={`${history ? "history" : ""}`} icon={faCircle} />
                <h3>
                  <a href={`/educations/${education.educationId}`}>{education.educationName}</a>
                </h3>
                <div className="dates">
                  <FontAwesomeIcon icon={faCalendarAlt} />
                  {formatDate(education.startDate)}
                  {education.endDate !== education.startDate ? " - " : null}
                  {education.endDate !== education.startDate ? formatDate(education.endDate) : null}
                </div>
                {education.recupUnits ? (
                  <div className="recup-units">
                    <FontAwesomeIcon icon={faClock} />
                    {education.recupUnits} Half day{education.recupUnits > 1 ? "s" : ""} recup used
                  </div>
                ) : null}
                {education.location ? (
                  <div className="location">
                    <FontAwesomeIcon icon={faMapMarkerAlt} />
                    {education.location}
                  </div>
                ) : null}
              </div>
              {!disableUnsubscribe && canEnroll(education) && !isLive(education) ? (
                <div className="unsubscribe">
                  <EducationUnsubscribe education={education} disabled={false} onUnsubscribe={() => onUnsubscribe()} />
                </div>
              ) : null}
              {!disableUnsubscribe && !canEnroll(education) && !isLive(education) ? (
                <div className="tickets">
                  {(education.tickets || []).map((ticket: BookingTicket) => (
                    <TicketAdministrationIcon key={ticket.type} value={ticket.type} state={ticket.state} />
                  ))}
                </div>
              ) : null}
              {isLive(education) ? <Live /> : null}
            </div>
          ))}
          {educationContainers.length === 0 &&
            (history ? (
              <p className="no-upcoming-educations">No Passed Educations</p>
            ) : (
              <p className="no-upcoming-educations">No upcoming Educations</p>
            ))}
        </div>
      </div>
    </div>
  );
};

export default EducationTimeline;
