import { faSignOutAlt, faUsersCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../context/user";
import { invalidateUser } from "../../services/user";
import Modal from "../Modal";
import OptionMenu from "../OptionMenu";

import ProfilePicture from "../ProfilePicture";

const UserInfo: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const { user } = useContext(UserContext);

  const logout = () => {
    setConfirmationOpen(false);
    invalidateUser();
    navigate("/login");
  };

  return user ? (
    <>
      <div className="user-dropdown">
        <span className="greeting">
          Hello, {user.firstName} {user.lastName}
        </span>
        <OptionMenu
          options={[
            {
              label: (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end"
                  }}
                >
                  Settings
                  <FontAwesomeIcon
                    icon={faUsersCog}
                    style={{ marginLeft: "5px", fontSize: "1.3em" }}
                  />
                </span>
              ),
              action: () => (location.href = "/settings")
            },
            {
              label: (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end"
                  }}
                >
                  Logout
                  <FontAwesomeIcon
                    icon={faSignOutAlt}
                    style={{ marginLeft: "5px", fontSize: "1.3em" }}
                  />
                </span>
              ),
              action: () => setConfirmationOpen(true)
            }
          ]}
        >
          <span className="user-image">
            <ProfilePicture user={user} size="small" />
          </span>
        </OptionMenu>
      </div>
      <Modal
        open={confirmationOpen}
        title="Logout"
        confirmText={"Yes"}
        onClose={setConfirmationOpen.bind(this, false)}
        onSubmit={logout}
      >
        <p>Are you sure you want to logout?</p>
      </Modal>
    </>
  ) : null;
};

export default UserInfo;
