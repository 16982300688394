import { BookingTicket } from "./booking";
import { DepartmentType } from "./department";
import User, { WaitinglistUser } from "./user";

export const URL_REGEX = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?%#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/;

export interface Education {
  uuid: string;
  name: string;
  description: string;
  location: string;
  educationType: EducationType;
  website: string;
  imageUrl: string;
  tags: string[];
  departments: DepartmentType[];
  year: number;
  open: boolean;
  state: EducationState;
  requirements: string[];
  tutor: string;
  maxAttendees: number;
  attendees: number;
  organisingDepartment: DepartmentType;
  ticketOptions: EducationTicketOption[];
  expenses: EducationExpense[];
  waitingList: WaitingList;
}

export interface WaitingList {
  users: WaitinglistUser[];
}

export interface EducationExpense {
  expense: string;
  cost: number;
  description?: string;
}

export interface EducationTicketOption {
  end?: Date;
  start?: Date;
  registrationDeadline?: Date;
  name: string;
  price: EducationPricing;
  bookingInfo: TicketOptionBookingInfo;
}

export type EducationTicketOptionRequirementType = "TRANSPORT" | "ENTRY" | "VENUE" | "ACCOMMODATION" | "OTHER";

export const REQUIREMENT_TRANSPORT: EducationTicketOptionRequirementType = "TRANSPORT";
export const REQUIREMENT_ENTRY: EducationTicketOptionRequirementType = "ENTRY";
export const REQUIREMENT_VENUE: EducationTicketOptionRequirementType = "VENUE";
export const REQUIREMENT_ACCOMMODATION: EducationTicketOptionRequirementType = "ACCOMMODATION";
export const REQUIREMENT_OTHER: EducationTicketOptionRequirementType = "OTHER";

export interface TicketOptionBookingInfo {
  tickets: EducationTicketOptionRequirement[];
  extraInfo: string;
}

export interface EducationTicketOptionRequirement {
  type: EducationTicketOptionRequirementType;
  bookBefore?: Date;
}

export interface EducationPricing {
  credits: number;
  halfDays: number;
  discountPerRecupUnit?: number;
  cost: number;
  additionalCosts: number;
  overruledApPrice?: number;
}

export interface CreateEducation {
  name?: string;
  description?: string;
  location?: string;
  educationType?: EducationType;
  website?: string;
  credits?: number;
  startDate?: Date | string;
  endDate?: Date | string;
  latestEnrollmentDate?: Date | string;
  tags?: string[];
  departments?: DepartmentType[];
  year?: number;
  cost?: number;
  additionalCosts?: number;
  halfDays?: number;
  overruledApPrice?: number;
  state: EducationState;
  requirements: string[];
  maxAttendees?: number;
  organisingDepartment?: string;
}

export interface UpdateEducation {
  uuid: string;
  name: string;
  year: number;
  website?: string;
  educationType: EducationType;
  location?: string;
  description?: string;
  startDate?: Date | string;
  endDate?: Date | string;
  latestEnrollmentDate?: Date | string;
  tags?: string[];
  departments?: string[];
  requirements: string[];
}

export type EducationStatus = "ENROLLED" | "ATTENDED" | "NEW";

export interface EducationPlanEducation {
  educationId: string;
  educationName: string;
  description: string;
  location: string;
  educationType: EducationType;
  imageUrl: string;
  startDate: Date;
  endDate: Date;
  registrationDeadline: Date;
  recupUnits: number;
  year: number;
  open: boolean;
  state: EducationState;
  credits: number;
  ticketOption: string;
  tickets: BookingTicket[];
}

export interface SimpleEducation {
  educationId: string;
  educationName: string;
  location?: string;
  imageUrl?: string;
  tickets: BookingTicket[];
}

export interface EducationPlan {
  assignedCredits: number;
  availableCredits: number;
  year: number;
  tickets: EducationPlanEducation[];
}

export type EducationType =
  | "COURSE"
  | "ONLINE_COURSE"
  | "INTERNAL_COURSE"
  | "CONFERENCE"
  | "LICENCE"
  | "COMPETENCE_CENTER"
  | "";

export const parseEducationType = (educationType: string | undefined): EducationType | undefined => {
  if (educationType) {
    switch (educationType) {
      case "COURSE":
        return "COURSE";
      case "ONLINE_COURSE":
        return "ONLINE_COURSE";
      case "INTERNAL_COURSE":
        return "INTERNAL_COURSE";
      case "CONFERENCE":
        return "CONFERENCE";
      case "LICENCE":
        return "LICENCE";
      case "COMPETENCE_CENTER":
        return "COMPETENCE_CENTER";
      default:
        return "";
    }
  }
  return undefined;
};

export type EducationState = "PUBLISHED" | "UNPUBLISHED" | "PRIVATE";

export interface EducationTypeOption {
  label: string;
  value: string;
}

export const EDUCATION_TYPE_OPTIONS: EducationTypeOption[] = [
  {
    label: "All",
    value: "Test"
  },
  {
    label: "Conference",
    value: "CONFERENCE"
  },
  {
    label: "Classroom Course",
    value: "COURSE"
  },
  {
    label: "Licence",
    value: "LICENCE"
  },
  {
    label: "Axxes Course",
    value: "INTERNAL_COURSE"
  },
  {
    label: "Online Course",
    value: "ONLINE_COURSE"
  },
  {
    label: "Competence Center",
    value: "COMPETENCE_CENTER"
  }
];

export const EDUCATION_TYPES: { [key: string]: string } = {
  COURSE: "Classroom Course",
  ONLINE_COURSE: "Online Course",
  INTERNAL_COURSE: "Axxes Course",
  CONFERENCE: "Conference",
  LICENCE: "Licence",
  COMPETENCE_CENTER: "Competence Center"
};

export interface EducationTicketOptionEnrollments {
  status: string;
  ticketOption: string;
  users: User[];
}

export interface EducationEnrollments {
  education: Education;
  enrollments: EducationTicketOptionEnrollments[];
}
