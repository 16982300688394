import React, { useContext, useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-form";
import Loader from "../../components/Loader";
import AxxesDropdown from "../../components/AxxesDropdown";
import {
  assignCredits,
  assignInitialCredits,
  getCredits,
  getEducationPlan,
  getFunctionLevelCreditOverview,
  searchUsers,
  updateFunctionCategory
} from "../../services/user";
import moment from "moment";
import messageService from "../../services/message.service";

import "./consultant-management.scss";
import AxxesPointLogo from "../../components/AxxesPointLogo";
import Modal from "../../components/Modal";
import EducationPlan from "../EducationPlan";
import { Field, TextArea } from "../../components/Form";
import compareValue from "../../utils/compare";
import User, { CreditHistory } from "../../domain/user";
import { DepartmentType } from "../../domain/department";
import { FunctionCategoryValue, FUNCTION_LEVELS } from "../../domain/function-category";
import UserContext from "../../context/user";
import ProfilePicture from "../../components/ProfilePicture";
import { Button, Card, CardAction, CardContent, CardHeader, Input } from "@axxes/design-system";
import OptionMenu from "../../components/OptionMenu";
import formatDate from "../../utils/date";
import { EducationPlanEducation } from "../../domain/education";

const validation = yup.object().shape({
  credits: yup.string().required("Credits are required"),
  reason: yup.string().trim().required("Reason is required")
});

const validate = (field: string) => {
  return (value: any) => {
    try {
      yup.reach(validation, field).validateSync(value);
      return false;
    } catch ({ errors }) {
      return errors[0];
    }
  };
};

const sortConsultants = (consultants: User[]) => {
  consultants.sort((a, b) =>
    compareValue(`${a.firstName} ${a.lastName}`.toLowerCase(), `${b.firstName} ${b.lastName}`.toLowerCase())
  );
};

interface Props {
  department: DepartmentType;
}

const NOW = new Date();

const ConsultantManagement: React.FunctionComponent<Props> = ({ department }) => {
  const { user, updateUserContext } = useContext(UserContext);
  const currentYear = new Date().getFullYear();
  const [loading, setLoading] = useState<boolean>(false);
  const [consultants, setConsultants] = useState<User[]>([]);
  const [filteredConsultants, setFilteredConsultants] = useState<User[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [openEducationPlan, setOpenEducationPlan] = useState<boolean>(false);
  const [openCreditHistory, setOpenCreditHistory] = useState<boolean>(false);
  const [credits, setCredits] = useState<number>(0);
  const [reason, setReason] = useState<string>();
  const [selectedConsultant, setSelectedConsultant] = useState<User>();
  const [totalCredits, setTotalCredits] = useState<number>();
  const [creditsLeft, setCreditsLeft] = useState<number>();
  const [functionLevelCreditOverview, setFunctionLevelCreditOverview] = useState<any>({});
  const [selectedYear, selectYear] = useState<number>(currentYear);
  const [creditHistory, setCreditHistory] = useState<CreditHistory>();
  const [confirmInitialCredits, setInitialCreditsConfirmation] = useState(false);
  const [initialCreditsLoading, setInitialCreditLoading] = useState(false);
  const [assignYear, setAssingYear] = useState(NOW.getMonth() >= 10 ? NOW.getFullYear() + 1 : NOW.getFullYear());
  const [educations, setEducations] = useState<EducationPlanEducation[]>([]);
  const selecteableYears = [currentYear - 1, currentYear, currentYear + 1];
  const creditLogs = useMemo(() => {
    return [
      ...(creditHistory?.updates || []).map((update) => ({
        timestamp: moment(update.timestamp).toDate(),
        reason: update.reason,
        credits: update.credits
      })),
      ...educations.map((education) => ({
        timestamp: null,
        reason: `Enrollment ${education.educationName}`,
        credits: -education.credits
      }))
    ].sort(
      (update1, update2) => (update1.timestamp || new Date()).getTime() - (update2.timestamp || new Date()).getTime()
    );
  }, [educations]);

  async function fetchConsultantsAndFunctionLevelCreditOverview() {
    setLoading(true);
    const u = await searchUsers(department);
    const overview = await getFunctionLevelCreditOverview();
    sortConsultants(u);
    const c = u.filter((user: User) => !user.departments.includes("HR"));
    setTotalCredits(c.map((user: User) => user.totalCredits).reduce((a, b) => a + b, 0));
    setCreditsLeft(c.map((user: User) => user.availableCredits).reduce((a, b) => a + b, 0));
    setConsultants(c);
    setFilteredConsultants(c);
    setFunctionLevelCreditOverview(overview);
    setLoading(false);
  }

  useEffect(() => {
    fetchConsultantsAndFunctionLevelCreditOverview();
  }, [department]);

  useEffect(() => {
    async function fetchEducationPlan() {
      if (selectedConsultant) {
        try {
          const educationPlan = await getEducationPlan(selectedConsultant, selectedYear);
          setEducations(educationPlan.tickets);
        } catch (e) {
          setEducations([]);
        }
      }
    }
    fetchEducationPlan();
  }, [creditHistory]);

  const updateFunctionLevel = async (consultant: User, functionLevel: FunctionCategoryValue) => {
    try {
      await updateFunctionCategory(consultant.uuid, functionLevel);
      const updatedConsultants = consultants.map((c) => {
        if (c.uuid === consultant.uuid) {
          return {
            ...c,
            functionCategory: functionLevel
          };
        }
        return c;
      });
      setConsultants(updatedConsultants);
      setFilteredConsultants(updatedConsultants);
      messageService.success(`Function category updated for ${consultant.firstName} ${consultant.lastName}.`);
    } catch (e) {
      messageService.error("Unable to update function category. Please try again later");
    }
  };

  const addingCredits = async (consultant: User | undefined, c: number) => {
    if (consultant && c) {
      try {
        const creds = Number(c);
        await assignCredits(consultant.uuid, c, Number(selectedYear), reason || "");
        if (selectedYear === currentYear) {
          const updatedConsultants = [
            ...consultants.map((con) =>
              con.uuid === consultant.uuid
                ? {
                    ...consultant,
                    totalCredits: consultant.totalCredits + creds,
                    availableCredits: consultant.availableCredits + creds
                  }
                : con
            )
          ];
          setConsultants(updatedConsultants);
          setFilteredConsultants(updatedConsultants);
          setTotalCredits(updatedConsultants.map((u: User) => u.totalCredits).reduce((a, b) => a + b, 0));
          setCreditsLeft(updatedConsultants.map((u: User) => u.availableCredits).reduce((a, b) => a + b, 0));
          if (consultant.uuid === user?.uuid) {
            updateUserContext({
              ...user,
              availableCredits: consultant.availableCredits + creds
            });
          }
        }
        selectYear(currentYear);
        setReason(undefined);
        if (c > 0) {
          messageService.success(
            `${c} Credits added for user ${consultant.firstName} ${consultant.lastName} for year ${selectedYear}.`
          );
        } else {
          messageService.warning(
            `${Math.abs(c)} Credits removed for user ${consultant.firstName} ${
              consultant.lastName
            } for year ${selectedYear}.`
          );
        }
      } catch (e) {
        messageService.error("Unable to add credits. Please try again later");
      }
      setCredits(0);
    }
  };

  const retrieveCreditHistory = async (year: number, consultant = selectedConsultant) => {
    if (consultant) {
      const ch: CreditHistory = await getCredits(consultant, year);
      setCreditHistory(ch);
    }
  };

  const filterConsultants = (searchTerm: any) => {
    setFilteredConsultants(
      consultants.filter((consultant: User) =>
        `${consultant.firstName} ${consultant.lastName}`.toLowerCase().includes(searchTerm.target.value?.toLowerCase())
      )
    );
  };

  const openAddCredits = (consultant: User) => {
    setCredits(functionLevelCreditOverview[consultant.functionCategory]);
    setSelectedConsultant(consultant);
    setOpen(true);
  };

  const openEducationPlanForConsultant = (consultant: User) => {
    setSelectedConsultant(consultant);
    setOpenEducationPlan(true);
  };

  const openCreditHistoryForConsultant = (consultant: User) => {
    setSelectedConsultant(consultant);
    setOpenCreditHistory(true);
    retrieveCreditHistory(selectedYear, consultant);
  };

  const consultantsWithoutFunctionCategory = () => {
    return consultants.filter((consultant) => !consultant.functionCategory).length;
  };

  const assignInitialCreditsForDepartment = async () => {
    setInitialCreditLoading(true);
    await assignInitialCredits(department, assignYear);
    messageService.success(`Successfully added initial credits to ${department} for ${assignYear}`);
    const withoutFunctionCategory = consultantsWithoutFunctionCategory();
    if (withoutFunctionCategory > 0) {
      messageService.warning(
        `${withoutFunctionCategory} consultant${withoutFunctionCategory > 1 ? "s" : ""} without function category ${
          withoutFunctionCategory === 1 ? "is" : "are"
        } ignored.`
      );
    }

    if (assignYear === NOW.getFullYear()) {
      await fetchConsultantsAndFunctionLevelCreditOverview();
      setInitialCreditLoading(false);
    }
  };

  const { Form } = useForm();

  return (
    <div className="consultant-management">
      <Card>
        <CardHeader>
          <h1>{department} Consultants</h1>
          <CardAction>
            <Button accent={true} customClasses="assign-credits" onClick={() => setInitialCreditsConfirmation(true)}>
              Assign Initial Credits
            </Button>
          </CardAction>
        </CardHeader>
        <CardContent>
          <Loader loading={loading || initialCreditsLoading} />

          {!loading && !initialCreditsLoading && (
            <div className="consultant-container">
              <div className="consultant-filter">
                <Input placeholder="Search name" onChange={filterConsultants} />
              </div>
              <div>
                Total: <b>{consultants.length}</b>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Function level</th>
                    <th className="credits-column">
                      <span className="credits-header-wrapper">
                        <span className="total-credits">
                          {creditsLeft} / {totalCredits}
                        </span>
                        <AxxesPointLogo height="medium" />
                      </span>
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {filteredConsultants.map((consultant) => (
                    <tr key={consultant.uuid} className={consultant.availableCredits < 0 ? `credits-negative` : ""}>
                      <td>
                        <ProfilePicture user={consultant} />
                        <span>
                          {consultant.firstName} {consultant.lastName}
                        </span>
                      </td>
                      <td>
                        <AxxesDropdown
                          options={FUNCTION_LEVELS}
                          selectedItem={consultant.functionCategory}
                          onChange={(functionLevel: FunctionCategoryValue) => {
                            updateFunctionLevel(consultant, functionLevel);
                          }}
                        />
                      </td>
                      <td className="credits-column">
                        {consultant.availableCredits}/{consultant.totalCredits}
                      </td>
                      <td className="credits-column">
                        <OptionMenu
                          options={[
                            {
                              label: "Add credits",
                              action: () => openAddCredits(consultant)
                            },
                            {
                              label: "Credit History",
                              action: () => openCreditHistoryForConsultant(consultant)
                            },
                            {
                              label: "Enrolled Educations",
                              action: () => openEducationPlanForConsultant(consultant)
                            }
                          ]}
                        />
                      </td>
                    </tr>
                  ))}
                  {!consultants.length && (
                    <tr>
                      <td className="no-users-found" colSpan={4}>
                        No Consultants found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="consultants-mobile">
                {filteredConsultants.map((consultant) => (
                  <div className="consultant-mobile">
                    <h4>
                      <ProfilePicture user={consultant} size="small" />
                      <span>
                        {consultant.firstName} {consultant.lastName}
                      </span>
                    </h4>
                    <div className="credits">
                      <b>
                        {consultant.availableCredits}/{consultant.totalCredits}{" "}
                      </b>
                      <AxxesPointLogo height="small" />
                    </div>
                    <div>
                      <AxxesDropdown
                        options={FUNCTION_LEVELS}
                        selectedItem={consultant.functionCategory}
                        onChange={(functionLevel: FunctionCategoryValue) => {
                          updateFunctionLevel(consultant, functionLevel);
                        }}
                      />
                    </div>
                    <div className="actions">
                      <OptionMenu
                        options={[
                          {
                            label: "Add credits",
                            action: () => openAddCredits(consultant)
                          },
                          {
                            label: "Credit History",
                            action: () => openCreditHistoryForConsultant(consultant)
                          },
                          {
                            label: "Enrolled Educations",
                            action: () => openEducationPlanForConsultant(consultant)
                          }
                        ]}
                      />
                    </div>
                  </div>
                ))}
                {!consultants.length && <p>No Consultants found</p>}
              </div>
            </div>
          )}
        </CardContent>
      </Card>
      <Modal
        title={`Add Credits for ${selectedConsultant?.firstName} ${selectedConsultant?.lastName}`}
        open={open}
        onClose={() => {
          setOpen(false);
          setCredits(0);
        }}
        onSubmit={() => {
          addingCredits(selectedConsultant, credits);
          setOpen(false);
        }}
        completeDisabled={!credits || !reason || reason.trim().length === 0}
        confirmText="Add Credits"
      >
        <Form>
          <label>
            Year
            <AxxesDropdown
              options={selecteableYears.map((year) => ({
                label: `${year}`,
                value: year
              }))}
              selectedItem={currentYear}
              onChange={(year) => selectYear(year)}
            />
          </label>
          <Field
            name="credits"
            label="Credits"
            defaultValue={credits ? credits.toString() : ""}
            placeholder="Extra credits"
            type="number"
            onChangeHandler={(e) => setCredits(e.target.value)}
            required={true}
            validate={validate("credits")}
          />
          <TextArea
            name="reason"
            label="Just give me a Reason ~ P!nk"
            required={true}
            validate={validate("reason")}
            onChangeHandler={(e) => setReason(e.target.value)}
          />
        </Form>
      </Modal>
      <Modal
        title={`Education plan  ${selectedConsultant?.firstName} ${selectedConsultant?.lastName}`}
        open={openEducationPlan}
        onClose={() => setOpenEducationPlan(false)}
        hideComplete={true}
        size="wider"
      >
        <EducationPlan consultant={selectedConsultant} />
      </Modal>
      <Modal
        title={`Credit History  ${selectedConsultant?.firstName} ${selectedConsultant?.lastName}`}
        open={openCreditHistory}
        onClose={() => {
          setOpenCreditHistory(false);
          setCreditHistory(undefined);
        }}
        hideComplete={true}
        size="super"
      >
        <div className="credit-history">
          <label>
            Year
            <AxxesDropdown
              options={selecteableYears.map((year) => ({
                label: `${year}`,
                value: year
              }))}
              selectedItem={currentYear}
              onChange={(year) => retrieveCreditHistory(year, selectedConsultant)}
            />
          </label>
          <table>
            <tr>
              <th className="date-column">Date</th>
              <th>Reason</th>
              <th className="credits-column">Credits</th>
            </tr>
            {creditLogs.map((change) => (
              <tr>
                <td>{change.timestamp ? formatDate(change.timestamp, "DD/MM HH:mm") : "-"}</td>
                <td>{change.reason}</td>
                <td className="credits-column">
                  {change.credits} <AxxesPointLogo height="small" />
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan={2}>
                <b>Available credits</b>
              </td>
              <td className="credits-column">
                <b>{selectedConsultant?.availableCredits}</b> <AxxesPointLogo height="small" />
              </td>
            </tr>
            {!creditHistory || !creditHistory.updates.length ? (
              <tr>
                <td colSpan={3}>No credit history for the selected year</td>
              </tr>
            ) : null}
          </table>
        </div>
      </Modal>
      <Modal
        title="Assign initial credits"
        open={confirmInitialCredits}
        onClose={() => setInitialCreditsConfirmation(false)}
        onSubmit={() => {
          setInitialCreditsConfirmation(false);
          assignInitialCreditsForDepartment();
        }}
        confirmText="Yes"
      >
        <label>
          Assignment Year
          <AxxesDropdown
            options={selecteableYears.map((year) => ({
              label: `${year}`,
              value: year
            }))}
            selectedItem={assignYear}
            onChange={(year) => setAssingYear(year)}
          />
        </label>
        <p className="assignment-message">
          You are about to assign initial credits for year <b>{assignYear}</b>. <b>Are you sure?</b>
        </p>
      </Modal>
    </div>
  );
};

export default ConsultantManagement;
