import React from "react";

import "./404.scss";

import notfoundIllustration from "./404.svg";

const NotFound = () => {
  return (
    <div className="notfound-page page">
      <div className="container">
        <img src={notfoundIllustration} alt="notfound" />
        <h1>
          Not
          <br />
          Found
        </h1>
      </div>
    </div>
  );
};

export default NotFound;
