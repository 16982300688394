import { useForm } from "react-form";
import React, { useContext, useEffect, useReducer, useState } from "react";
import { Helmet } from "react-helmet";
import { AsyncTypeahead, Field, FileUpload, MarkdownArea, TypeSelector } from "../../../components/Form";
import { validate } from "./validation";

import "./create-education.scss";
import Toggle from "../../../components/Toggle";
import DepartmentsSelector from "../../../components/DepartmentsSelector";
import { hasRole, ROLES } from "../../../services/user";
import UserContext from "../../../context/user";
import { Department, DepartmentType } from "../../../domain/department";
import UserSearch from "../../../components/UserSearch";
import { variantsReducer } from "./variants";
import { Button, Card, CardContent, CardHeader } from "@axxes/design-system";
import TicketOption from "../../../components/TicketOption";
import { Education, EducationState, EducationTicketOption, EducationType } from "../../../domain/education";
import formatDate from "../../../utils/date";
import AxxesPointLogo from "../../../components/AxxesPointLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
import Loader from "../../../components/Loader";
import EducationRequirements, { REQUIREMENT_OPTIONS } from "../../../components/EducationRequirements";
import { searchTags } from "../../../services/tag";
import { useNavigate } from "react-router-dom";
import Modal from "../../../components/Modal";
import EducationDetail from "../../../components/EducationDetail";
import { createEducation as saveEducation, createVariant, uploadImage } from "../../../services/education";
import messageService from "../../../services/message.service";

const initialState = { variants: [], loading: false };

const EducationCreatePage: React.FunctionComponent = () => {
  const now = new Date();
  const defaultYear = now.getMonth() >= 10 ? now.getFullYear() + 1 : now.getFullYear();
  const { user } = useContext(UserContext);

  const [year, setYear] = useState(defaultYear);
  const [privateEducation, setPrivate] = useState<boolean>(false);
  const [educationType, setEducationType] = useState<string>();
  const [tutor, setTutor] = useState<string>();
  const [variantState, dispatchVariants] = useReducer(variantsReducer, initialState);
  const [variantCreation, setVariant] = useState<EducationTicketOption>();
  const [variantLoading, setVariantLoading] = useState<boolean>(false);
  const [organisingDepartment, setOrganisingDepartment] = useState<DepartmentType | undefined>();
  const [requirements, setRequirements] = useState<string[]>([]);
  const [image, setImage] = useState<any>();
  const [educationStatus, setEducationState] = useState<EducationState>();
  const [openEducationConfirm, setOpenEducationConfirm] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const navigate = useNavigate();

  const usedNames = (variantState?.variants || []).filter((x: any) => !!x).map((e: EducationTicketOption) => e.name);

  const nameValidation = yup
    .string()
    .notOneOf(usedNames, "Ticket option name must be unique")
    .required("Name is required");

  const form = useForm({
    validatePristine: true,
    onSubmit: () => {}
  });

  const togglePrivate = (state: boolean) => setPrivate(state);
  const mapDepartment = (value: Department) => value.value;
  const handleTutor = (user: any) =>
    setTutor(user ? (user.customOption ? user.label : `${user.firstName} ${user.lastName}`) : undefined);
  const addVariant = () => {
    setVariantLoading(true);
    setVariant(undefined);

    setTimeout(() => {
      dispatchVariants({
        type: "add",
        payload: variantCreation
      });
      setVariantLoading(false);
    }, 1000);
  };
  const removeVariant = (index: number) => () => {
    setVariantLoading(true);
    dispatchVariants({
      type: "remove",
      payload: {
        index
      }
    });

    setTimeout(() => {
      setVariantLoading(false);
    }, 1000);
  };

  const cancel = () => {
    navigate(-1);
  };
  const getFormValues = () => ({
    ...form.values,
    organisingDepartment,
    user: undefined,
    tutor,
    ticketOptionRequirements: undefined,
    ticketOptions: variantState?.variants || [],
    private: privateEducation
  });

  const isEducationTypeValid = (educationType: EducationType, tutor: string): boolean => {
    if (!educationType) {
      return false;
    }
    if (educationType === "INTERNAL_COURSE" || educationType === "COURSE") {
      return !!tutor;
    }
    return true;
  };

  const isFormValid = (): boolean => {
    const formValues = getFormValues();

    const isValid =
      !!formValues?.name &&
      !!formValues?.year &&
      !!formValues?.organisingDepartment &&
      !!((formValues?.departments?.length || 0) > 0) &&
      !!isEducationTypeValid(formValues?.educationType, formValues?.tutor) &&
      !!formValues?.description;
    return isValid;
  };

  const validateTicketOption = (): boolean => {
    const costIsValid =
      educationType === "COMPETENCE_CENTER" ? variantCreation?.price?.cost === 0 : variantCreation?.price?.cost;
    const halfDaysIsValid =
      educationType === "COMPETENCE_CENTER"
        ? variantCreation?.price?.halfDays === 0
        : variantCreation?.price?.halfDays;
    return (
      validate("name", nameValidation)(variantCreation?.name) ||
      !costIsValid ||
      !halfDaysIsValid ||
      validate("latestEnrollmentDate", undefined, { educationType })(variantCreation?.registrationDeadline)
    );
  };

  const toEducation = (state: EducationState = "PUBLISHED"): Education => {
    const formValues = getFormValues();
    return {
      ...formValues,
      state: privateEducation ? "PRIVATE" : state
    };
  };

  const createEducation = () => {
    setEducationState("PUBLISHED");
    setOpenEducationConfirm(true);
  };

  const saveAsDraft = () => {
    setEducationState("UNPUBLISHED");
    setOpenEducationConfirm(true);
  };

  const submitEducation = (education: Education) => {
    setOpenEducationConfirm(false);
    setSubmitting(true);
    saveEducation({
      ...education,
      year: Number(education.year),
      requirements,
      organisingDepartment
    })
      .then(({ headers: { location = "" } }: { headers: { location: string } }) => {
        const educationId = location.match(/.*\/api\/educations\/(.*)/);
        messageService.success("Successfully created your education: " + education.name);
        if (educationId) {
          const id = educationId[1].replace("/", "");
          submitImage(id);
          submitVariants(id, education.ticketOptions);
          setTimeout(() => navigate(`/educations/${id}`), 2000);
        } else {
          setSubmitting(false);
        }
      })
      .catch(({ response }) => {
        setSubmitting(false);
        messageService.error(
          response?.data?.message || "Something went wrong while creating the education. Please try again later.",
          {
            timeOut: 15000
          }
        );
      });
  };

  const submitImage = (educationId: string) => {
    if (educationId && image) {
      uploadImage(educationId, image)
        .then(() => {
          setTimeout(() => messageService.success("Successfully uploaded your image"), 2000);
        })
        .catch(({ response }) =>
          messageService.error(response?.data?.message || "Something went wrong while uploading your image.")
        );
    }
  };

  const submitVariants = async (educationId: string, ticketOptions: EducationTicketOption[] = []) => {
    for (const variant of ticketOptions) {
      await createVariant(educationId, variant).catch(({ response }) =>
        messageService.error(
          response?.data?.message || "Something went wrong while creating ticket option: " + variant.name
        )
      );
    }
    if (ticketOptions.length) {
      messageService.success(`Successfully created ${ticketOptions.length} Ticket options`);
    }
  };

  useEffect(() => {
    setOrganisingDepartment(hasRole(user, [ROLES.COACH]) ? user?.departments[0] : undefined);
  }, []);

  return (
    <div className="page page-padding">
      <Helmet>
        <title>FEP: Create Education</title>
      </Helmet>
      <div className="create-education">
        <h3>Create Education</h3>
        {submitting ? (
          <Loader loading={true} />
        ) : (
          <form.Form>
            <div className="create-form">
              <h5>General information</h5>
              <div className="row">
                <div className="col-6">
                  <Field name="name" label="Name" required={true} validate={validate("name")} />
                </div>
                <div className="col-2">
                  <Field
                    name="year"
                    label="Year"
                    type="number"
                    defaultValue={`${defaultYear}`}
                    required={true}
                    validate={validate("year")}
                    onChangeHandler={setYear}
                  />
                </div>
                <div className="col-1">
                  <div className="app-label private-toggle">
                    <div
                      className="form__input-group"
                      title="This option will make sure the education is only visible to coaches and captains."
                    >
                      <label>Private</label>
                      <Toggle onStateChange={togglePrivate} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row form-block">
                <div className="col-3">
                  <div className="app-label organising-department">
                    <DepartmentsSelector
                      name="organisingDepartment"
                      label="Organising Department"
                      required={true}
                      validate={validate("organisingDepartment")}
                      mapper={mapDepartment}
                      onChange={setOrganisingDepartment}
                      multiple={false}
                      exclusions={["ADMIN"]}
                      defaultSelected={hasRole(user, [ROLES.COACH]) ? user?.departments : []}
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="app-label">
                    <DepartmentsSelector
                      label="Departments"
                      required={true}
                      validate={validate("departments")}
                      exclusions={["ADMIN"]}
                      mapper={mapDepartment}
                    />
                  </div>
                </div>
              </div>
              <h5 className="form-block-title">Education Format</h5>
              <div className="row">
                <div className="col-10">
                  <div className="app-label">
                    <TypeSelector
                      name="educationType"
                      label="Education Type"
                      options={[
                        { text: "Axxes Course", value: "INTERNAL_COURSE" },
                        { text: "Online Course", value: "ONLINE_COURSE" },
                        { text: "Classroom Course", value: "COURSE" },
                        { text: "Conference", value: "CONFERENCE" },
                        { text: "Licence", value: "LICENCE" },
                        { text: "Competence Center", value: "COMPETENCE_CENTER" }
                      ]}
                      required={true}
                      onChangeHandler={setEducationType}
                      validate={validate("educationType")}
                    />
                  </div>
                </div>
              </div>
              <div className="row form-block">
                <div className="col-6 tutor">
                  <UserSearch
                    multiple={false}
                    allowNew={true}
                    placeholder="Tutor"
                    label="Tutor"
                    required={educationType === "INTERNAL_COURSE" || educationType === "COURSE"}
                    onChangeHandler={handleTutor}
                  />
                </div>
                <div className="col-2 max-attendees">
                  <Field
                    name="maxAttendees"
                    label="Maximum Attendees"
                    type="number"
                    validate={validate("maxAttendees")}
                  />
                </div>
              </div>
              <h5 className="form-block-title">Ticket options</h5>
              <div className="ticket-option-information form-block">
                <p>
                  Ticket options are <b>different versions of the same education</b> eg.: Same conference with or
                  without workshops, early bird tickets, etc.
                </p>
                <p style={{ marginTop: "0" }}>Number of Ticket options: {variantState?.variants.length || 0}</p>
                {educationType === "LICENCE" ? (
                  <p>Licences don't need a date. They are automatically available for a complete year.</p>
                ) : null}
                <div className="row">
                  <div className="col-6 variants-form">
                    {variantLoading ? (
                      <Loader loading={true} />
                    ) : (
                      <TicketOption
                        disableName={false}
                        index={0}
                        disableRemove={true}
                        baseEducation={{
                          year,
                          educationType
                        }}
                        usedNames={usedNames}
                        update={setVariant}
                      />
                    )}
                    <Button disabled={validateTicketOption()} onClick={addVariant}>
                      Add Ticket option
                    </Button>
                  </div>
                  <div className="col-6 variants">
                    <h5>Ticket options</h5>
                    <div className="variants-list">
                      {!variantState?.variants.length ? (
                        <p>No Ticket options created yet</p>
                      ) : (
                        variantState.variants.map((ticketOption: any, index: number) => (
                          <Card key={`${ticketOption.name}_${index}`}>
                            <CardHeader>
                              <h4>{ticketOption.name}</h4>
                            </CardHeader>
                            <CardContent>
                              <div className="body">
                                <div className="content">
                                  <div>
                                    {ticketOption.start
                                      ? formatDate(ticketOption.start) +
                                        (ticketOption.start !== ticketOption.end && ticketOption.end
                                          ? " - " + formatDate(ticketOption.end)
                                          : "-")
                                      : null}
                                    <b>
                                      {ticketOption.start ? " " : ""}({ticketOption.price.halfDays} Half days)
                                    </b>
                                  </div>

                                  {ticketOption.registrationDeadline ? (
                                    <div className="ticket-option__duedate">
                                      <b>Registration Deadline: </b>
                                      {formatDate(ticketOption.registrationDeadline)}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="credits">
                                  <b style={{ marginRight: "5px" }}>
                                    {ticketOption.price.overruledApPrice || ticketOption.price.credits}
                                    {ticketOption.price.overruledApPrice ? (
                                      <i style={{ marginLeft: "5px" }}>Overruled</i>
                                    ) : null}
                                  </b>
                                  <AxxesPointLogo height="small" />
                                </div>
                                <div>
                                  Costs:{" "}
                                  <i style={{ fontWeight: "bold" }}>
                                    (Cost: &euro;{ticketOption.price.cost} + Additional Costs: &euro;
                                    {ticketOption.price.additionalCosts})
                                  </i>
                                </div>
                                <div>
                                  Requirements:{" "}
                                  <b>
                                    {(ticketOption.bookingInfo?.tickets || [])
                                      .map((x: any) => x.type)
                                      .map((x: string) => REQUIREMENT_OPTIONS.find((option: any) => option.value === x))
                                      .map((option: any) => option.label)
                                      .join(", ")}
                                  </b>
                                  {!ticketOption?.bookingInfo.tickets?.length && <b>-</b>}
                                </div>
                              </div>
                              <div className="ticket-option-actions">
                                <Button onClick={removeVariant(index)} customClasses="delete-variant">
                                  <FontAwesomeIcon icon={faTimes} />
                                </Button>
                              </div>
                            </CardContent>
                          </Card>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <h5 className="form-block-title">Extra Information</h5>
              <div className="extra-information form-block">
                <div>
                  <p>
                    The extra information is used to give all necessary information to the consultants. The more
                    information they have the better decisions they can make to enroll for the right Education.
                  </p>
                  <p>The description can contain some basic information about the Education.</p>
                  <MarkdownArea
                    name="description"
                    label="Description"
                    required={true}
                    validate={validate("description")}
                  />
                </div>
                <div>
                  <EducationRequirements requirementsChanged={setRequirements} requirements={requirements} />
                </div>
                <div className="row">
                  <div className="col-6">
                    <Field name="website" label="Website" validate={validate("website")} />
                  </div>
                  <div className="col-6">
                    <Field name="location" label="Location" />
                  </div>
                </div>
                <div>
                  <AsyncTypeahead
                    label="Tags"
                    name="tags"
                    onSearch={searchTags}
                    multiple={true}
                    clearButton={true}
                    allowNew={true}
                  />
                </div>
                <div>
                  <FileUpload name="image" label="Education Image" onChange={setImage} />
                </div>
              </div>
              <div className="create-form-actions">
                <Button onClick={cancel} variant="subtle">
                  Cancel
                </Button>
                <Button onClick={saveAsDraft} disabled={!isFormValid()}>
                  Save as draft
                </Button>
                <Button type="submit" accent={true} disabled={!isFormValid()} onClick={createEducation}>
                  Submit
                </Button>
              </div>
            </div>
          </form.Form>
        )}
        <Modal
          open={openEducationConfirm}
          onClose={() => setOpenEducationConfirm(false)}
          onSubmit={() => submitEducation(toEducation(educationStatus))}
          confirmText={educationStatus === "PUBLISHED" ? "Confirm" : "Confirm draft"}
        >
          <EducationDetail education={toEducation(educationStatus)} />
        </Modal>
      </div>
    </div>
  );
};

export default EducationCreatePage;
