import { Button, CardContent } from "@axxes/design-system";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { flatten } from "lodash";
import {
  Booking,
  BookingTicket,
  BookingUser,
  flattenUsers,
  groupByTicketOption
} from "../../../domain/booking";
import {
  EducationTicketOptionRequirement,
  EducationTicketOptionRequirementType
} from "../../../domain/education";
import formatDate from "../../../utils/date";
import CollapsableCard from "../../CollapsableCard";
import TicketAdministrationIcon from "../../TicketAdministrationIcon";
import { hasEntryRequirement } from "../../TicketOption";

interface ApprovalProps {
  educationName: string;
  educationBookings: Booking[];
  approveEducation: (bookings: Booking[]) => void;
}

const AdministrationApprovalTicket: React.FunctionComponent<ApprovalProps> = ({
  educationName,
  educationBookings,
  approveEducation
}) => {
  const getEntryRequirement = (tickets: EducationTicketOptionRequirement[]) =>
    tickets.find(
      (ticket: EducationTicketOptionRequirement) => ticket.type === "ENTRY"
    );
  const flattenedUsers = flattenUsers(educationBookings);
  return (
    <CollapsableCard
      className="multiple-ticket-option"
      title={educationName}
      subTitle={
        <div className="split-content">
          <div className="axxes-badge department">
            {educationBookings[0].organisingDepartment}
          </div>
          <Button
            accent={true}
            onClick={approveEducation.bind(this, educationBookings)}
          >
            {flattenedUsers.filter((user) => user.approved).length ===
            flattenedUsers.length
              ? "Complete"
              : "Approve"}
          </Button>
        </div>
      }
    >
      <CardContent customClasses="ticket-options-container">
        <div className="ticket-options">
          {groupByTicketOption(educationBookings).map(
            ([ticketOption, bookings]) => (
              <div className="ticket-option" key={ticketOption}>
                <h5>{ticketOption}</h5>
                <div className="split-content">
                  <div className="approvals">
                    {
                      bookings[0].users.filter(
                        (user: BookingUser) => user.approved
                      ).length
                    }
                    /{bookings[0].users.length}
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      style={{ marginLeft: "6px", color: "green" }}
                    />
                  </div>
                  <div className="requirements">
                    {bookings[0].tickets.map((ticket: BookingTicket) => (
                      <TicketAdministrationIcon
                        key={ticket.type}
                        value={ticket.type}
                        bookBefore={ticket.bookBefore}
                      />
                    ))}
                  </div>
                </div>
                {hasEntryRequirement(
                  bookings[0].tickets.map(({ type }) => type)
                ) ? (
                  <div className="book-before">
                    {getEntryRequirement(bookings[0].tickets)?.bookBefore ? (
                      <>
                        <b>Book before: </b>
                        {formatDate(
                          getEntryRequirement(bookings[0].tickets)?.bookBefore
                        )}
                      </>
                    ) : null}
                  </div>
                ) : null}
              </div>
            )
          )}
        </div>
      </CardContent>
    </CollapsableCard>
  );
};

export { flattenUsers };
export default AdministrationApprovalTicket;
