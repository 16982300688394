import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import UserContext from "../../../context/user";

import "./education-overview.scss";
import EducationListContainer from "../../../containers/EducationList";
import { hasRole, ROLES } from "../../../services/user";
import { Button } from "@axxes/design-system";
import MyEducationsContainer from "../../../containers/MyEducationSlider";
import { getDepartmentLabel } from "../../../domain/department";
import CreateEducation from "../../../components/CreateEducation";

const EducationOverview: React.FunctionComponent = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const createEducation = () => navigate("/educations/create");

  return (
    <div className="page education-page page-padding">
      <Helmet>
        <title>FEP: Education overview</title>
      </Helmet>
      <div className="education-container">
        {hasRole(user, [ROLES.COACH, ROLES.HR]) && (
          <div className="page-title">
            <div className="education-actions">
              <Link to="/educations/manage" className="manage-action">
                <Button>
                  <span>Manage educations</span>
                </Button>
              </Link>
              <CreateEducation />
            </div>
          </div>
        )}
        <div className="my-educations">
          <h1>{getDepartmentLabel(user?.departments[0])}</h1>
          <MyEducationsContainer />
        </div>
        <div className="all-educations">
          <h1>All Educations</h1>
          <EducationListContainer />
        </div>
      </div>
    </div>
  );
};

export default EducationOverview;
