import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import UserContext from "../../context/user";

import logo from "../../logo-dark.svg";

import "./header.scss";
import UserInfo from "../User";

import AxxesPointLogo from "../AxxesPointLogo";
import { hasRole, ROLES } from "../../services/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faClipboardList,
  faHome,
  faUserCircle,
  faUserFriends
} from "@fortawesome/free-solid-svg-icons";

const Header: React.FunctionComponent = () => {
  const { user } = useContext(UserContext);

  return (
    <>
      <header className="axxes-header">
        <div className="axxes-header__topbar">
          <nav className="axxes-header__topbar__nav">
            <span className="axxes-header__topbar__logo">
              <img src={logo} alt="Logo FEP" />
            </span>
            {hasRole(user, [ROLES.CONSULTANT, ROLES.COACH]) && (
              <NavLink to="/dashboard">
                <span>Dashboard</span>
              </NavLink>
            )}
            {hasRole(user, [ROLES.COACH, ROLES.CONSULTANT, ROLES.HR]) && (
              <NavLink to="/educations">
                <span>Educations</span>
              </NavLink>
            )}
            {hasRole(user, [ROLES.COACH, ROLES.HR]) && (
              <NavLink
                to="/consultants"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <span>Consultants</span>
              </NavLink>
            )}
            {hasRole(user, [ROLES.COACH, ROLES.HR, ROLES.ADMIN]) && (
              <NavLink
                to="/ticket-administration"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <span>Tickets</span>
              </NavLink>
            )}
            {hasRole(user, [ROLES.COACH, ROLES.HR, ROLES.ADMIN]) && (
              <NavLink
                to="/reports"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <span>Reports</span>
              </NavLink>
            )}
          </nav>
          <div className="axxes-header__topbar__user">
            {hasRole(user, [ROLES.CONSULTANT]) && (
              <div className="axxes-header__topbar__axxespoints">
                {user?.availableCredits}
                <AxxesPointLogo height="medium" />
              </div>
            )}
            <UserInfo />
          </div>
        </div>
      </header>
      <header className="axxes-mobile-header">
        <div className="axxes-header__bottombar">
          <nav className="axxes-header__bottombar__nav">
            {hasRole(user, [ROLES.CONSULTANT, ROLES.COACH, ROLES.HR]) && (
              <NavLink to="/dashboard">
                <FontAwesomeIcon icon={faHome} />
              </NavLink>
            )}
            {hasRole(user, [ROLES.COACH, ROLES.CONSULTANT, ROLES.HR]) && (
              <NavLink to="/educations">
                <FontAwesomeIcon icon={faBook} />
              </NavLink>
            )}
            {hasRole(user, [ROLES.COACH, ROLES.HR]) && (
              <NavLink
                to="/consultants"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <FontAwesomeIcon icon={faUserFriends} />
              </NavLink>
            )}
            {hasRole(user, [ROLES.COACH, ROLES.HR, ROLES.ADMIN]) && (
              <NavLink
                to="/reports"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <FontAwesomeIcon icon={faClipboardList} />
              </NavLink>
            )}
            <NavLink
              to="/settings"
              className={({ isActive }) => (isActive ? "active" : "")}
            >
              <FontAwesomeIcon icon={faUserCircle} />
            </NavLink>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;
