import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./registerSW";
import { Button } from "@axxes/design-system";

import logo from "./logo-dark.svg";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DISMISS = "HOME_DISMISS";

let deferredPrompt: any;

const Installation = () => (
  <div
    className={`installation-prompt ${
      localStorage.getItem(DISMISS) ? "dismissed" : ""
    }`}
  >
    <div className="upper">
      <div>
        <img src={logo} alt="logo" />
        fep.axxes.com
      </div>
      <button
        className="axxes-button--subtle"
        type="button"
        onClick={() => {
          localStorage.setItem(DISMISS, "true");
          document
            .getElementsByClassName("installation-prompt")[0]
            .classList.remove("visible");
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
    <Button
      accent={true}
      onClick={() => {
        if (deferredPrompt) {
          deferredPrompt.prompt();
          deferredPrompt.userChoice.then(() => {
            deferredPrompt = null;
          });
          document
            .getElementsByClassName("installation-prompt")[0]
            .classList.remove("visible");
        }
      }}
    >
      Add to homescreen
    </Button>
  </div>
);

ReactDOM.render(
  <React.StrictMode>
    <App />
    <Installation />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();

window.addEventListener("beforeinstallprompt", (event) => {
  event.preventDefault();
  if (!location.href.includes("login")) {
    deferredPrompt = event;
    console.log("beforeinstallprompt");
    document
      .getElementsByClassName("installation-prompt")[0]
      .classList.add("visible");
  }
});
