import React from "react";
import ap from "../../assets/images/AxxesPoint.svg";
import "./axxesPointLogo.scss";

interface Props {
  height: string;
}

const AxxesPointLogo: React.FunctionComponent<Props> = ({
  height = "small"
}) => {
  return (
    <span title="Axxes points" className="axxes-points">
      <img className={`${height}`} src={ap} alt="Axxes point logo" />
    </span>
  );
};

export default AxxesPointLogo;
