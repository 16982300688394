import React from "react";
import { Helmet } from "react-helmet";

import MyEducationsOverview from "../../../containers/MyEducationsOverview";

import "./dashboard.scss";

const UserDashboard = () => {
  return (
    <div className="page-padding">
      <Helmet>
        <title>FEP: Dashboard</title>
      </Helmet>
      <MyEducationsOverview />
    </div>
  );
};

export default UserDashboard;
