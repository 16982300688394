import React from "react";
import { Typeahead } from "../Form";
import {
  Department,
  DEPARTMENTS,
  DepartmentType
} from "../../domain/department";
import AxxesDropdown from "../AxxesDropdown";

const getDepartment = (department?: DepartmentType): Department => {
  const result = DEPARTMENTS.find((dep) => dep.value === department);
  if (!result) {
    return { label: "Unknown", value: "UNKNOWN" };
  }
  return result;
};

interface Props {
  name?: string;
  onChange?: (value: any) => void;
  validate?: (value: any) => boolean;
  mapper?: (value: any) => any;
  label?: string;
  exclusions?: string[];
  defaultSelected?: (DepartmentType | undefined)[];
  disabled?: boolean;
  required?: boolean;
  multiple?: boolean;
}

function mapLabel(department: Department) {
  return department.label;
}

const DepartmentsSelector: React.FunctionComponent<Props> = ({
  name = "departments",
  onChange = () => {},
  validate = () => false,
  mapper = (value: string) => value,
  label = "",
  exclusions = [],
  defaultSelected = [],
  disabled = false,
  required = false,
  multiple = true
}) => {
  if (multiple) {
    return (
      <Typeahead
        name={name}
        label={label}
        required={required}
        multiple={multiple}
        clearButton={true}
        maxVisible={DEPARTMENTS.length}
        labelKey={mapLabel}
        options={DEPARTMENTS.filter(
          (dep: Department) => !exclusions.includes(dep.value)
        )}
        paginate={false}
        placeholder="Enter a department"
        validate={validate}
        onChangeHandler={onChange}
        mapper={mapper}
        defaultSelected={defaultSelected
          .map(getDepartment)
          .filter((dep) => !!dep)}
        disabled={disabled}
      />
    );
  } else {
    return (
      <div className="form__input-group">
        <label>
          {label}{" "}
          <span className="form__input-required">{required ? "*" : ""}</span>
        </label>

        <AxxesDropdown
          options={DEPARTMENTS.filter(
            (dep: Department) => !exclusions.includes(dep.value)
          )}
          selectedItem={defaultSelected[0]}
          onChange={onChange}
        />
      </div>
    );
  }
};

export { getDepartment };
export default DepartmentsSelector;
