import { Button, Card, CardContent, CardHeader } from "@axxes/design-system";
import { Booking, BookingTicket } from "../../../domain/booking";
import { EducationTicketOptionRequirement } from "../../../domain/education";
import formatDate from "../../../utils/date";
import TicketAdministrationIcon from "../../TicketAdministrationIcon";
import { hasEntryRequirement } from "../../TicketOption";

interface HandlingProps {
  educationName: string;
  educationBookings: Booking[];
  handle: (booking: Booking) => void;
  close: (booking: Booking) => void;
}

const AdministrationHandlingTicket: React.FunctionComponent<HandlingProps> = ({
  educationName,
  educationBookings,
  handle,
  close
}) => {
  const getEntryRequirement = (tickets: EducationTicketOptionRequirement[]) =>
    tickets.find((ticket: EducationTicketOptionRequirement) => ticket.type === "ENTRY");

  const isTicketFinished = (booking: Booking) => {
    return (
      booking.users.length === 0 ||
      booking.tickets.filter((ticket) => ticket.state === "ORDERED").length === booking.tickets.length
    );
  };

  const closeTicket = (event: any, booking: Booking) => {
    if (event.shiftKey) {
      handle(booking); // SAFE HANDLE ;)
    } else {
      close(booking);
    }
  };

  return (
    <div className="education-ticket-handling">
      <div className="education-ticket-handling-header">
        <div className="axxes-badge department">{educationBookings[0].organisingDepartment}</div>
        <h4>{educationName}</h4>
      </div>

      {educationBookings.map((booking: Booking) => (
        <Card key={booking.id}>
          <CardHeader>
            <h4>{booking.ticketOption}</h4>
          </CardHeader>
          <CardContent>
            <div className="split-content">
              <div className="requirements">
                {booking.tickets.map((ticket: BookingTicket) => (
                  <TicketAdministrationIcon
                    key={ticket.type}
                    value={ticket.type}
                    state={ticket.state}
                    bookBefore={ticket.bookBefore}
                  />
                ))}
              </div>
              {isTicketFinished(booking) ? (
                <span title="Press Shift + click to see handle screen">
                  <Button
                    customClasses="close-ticket-option"
                    accent={true}
                    onClick={(event: Event) => closeTicket(event, booking)}
                  >
                    Close
                  </Button>
                </span>
              ) : (
                <Button accent={true} onClick={handle.bind(this, booking)}>
                  Handle
                </Button>
              )}
            </div>
            {!isTicketFinished(booking) && hasEntryRequirement(booking.tickets.map(({ type }) => type)) ? (
              <div className="book-before">
                {getEntryRequirement(booking.tickets)?.bookBefore ? (
                  <>
                    <b>Book before: </b>
                    {formatDate(getEntryRequirement(booking.tickets)?.bookBefore)}
                  </>
                ) : null}
              </div>
            ) : null}
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default AdministrationHandlingTicket;
