import React from "react";

import "./live.scss";

const Live: React.FunctionComponent = () => {
  return (
    <div className="video__icon">
      <div className="circle--outer" />
      <div className="circle--inner" />
      <p>Live</p>
    </div>
  );
};

export default Live;
