import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

interface Props {
  loading: boolean;
}

const Loader: React.FunctionComponent<Props> = ({ loading = false }) => {
  return loading ? (
    <FontAwesomeIcon
      icon={faCircleNotch}
      className="app-spinner fa-spin"
      style={{
        fontSize: "45px",
        display: "flex",
        marginLeft: "auto",
        marginRight: "auto"
      }}
    />
  ) : null;
};

export default Loader;
