import React, { useEffect, useState } from "react";
import { faGlobeEurope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  url?: string;
  className?: string;
  prevent?: boolean;
}

const Website: React.FunctionComponent<Props> = ({
  url = "",
  className = "",
  prevent = false
}) => {
  const [website, setWebsite] = useState(url);

  useEffect(() => {
    if (
      !url.includes("www.") &&
      !url.includes("http://") &&
      !url.includes("https://")
    ) {
      setWebsite(`www.${url}`);
    } else {
      setWebsite(url);
    }
  }, [url]);

  return (
    <a
      href={website.startsWith("www") ? `//${website}` : website}
      className={className}
      rel="noopener noreferrer"
      target="_blank"
      onClick={(e) => prevent && e?.stopPropagation()}
    >
      <FontAwesomeIcon icon={faGlobeEurope} />
      <span>{website}</span>
    </a>
  );
};

export default Website;
