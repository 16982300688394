import React from "react";

import "react-datepicker/dist/react-datepicker.min.css";

import { useNavigate } from "react-router-dom";
import { Button } from "@axxes/design-system";

const CreateEducation: React.FunctionComponent<{}> = () => {
  const navigate = useNavigate();
  const createEducation = () => navigate("/educations/create");
  return (
    <Button accent={true} onClick={createEducation}>
      <span className="button-icon">+</span>
      <span>Create Education</span>
    </Button>
  );
};

export default CreateEducation;
