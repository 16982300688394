import React from "react";
import { Link } from "react-router-dom";

import "./breadcrumbs.scss";

interface Crumb {
  text: string;
  link: string;
}

interface Props {
  crumbs: Crumb[];
}

const Breadcrumbs: React.FunctionComponent<Props> = ({ crumbs = [] }) => {
  return (
    <div className="breadcrumbs">
      {crumbs
        .filter((x) => !!x)
        .map((crumb, index) => {
          return index === crumbs.length - 1 ? (
            <span className="last" key={`crumb_${index}`}>
              {crumb.text}
            </span>
          ) : (
            <span key={`crumb_${index}`}>
              <Link to={crumb.link} className="link">
                {crumb.text}
              </Link>
              <span className="separator">&gt;</span>
            </span>
          );
        })}
    </div>
  );
};

export default Breadcrumbs;
