import React, { useContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-form";
import UserContext from "../../../context/user";
import Tabs from "../../../components/Tabs";
import ConsultantManagement from "../../../containers/ConsultantManagement";
import Modal from "../../../components/Modal";
import DepartmentsSelector from "../../../components/DepartmentsSelector";
import { Department, DEPARTMENTS, DepartmentType } from "../../../domain/department";

export const HR_DEPARTMENTS = "HR_DEPARTMENTS";

const HRDashboard = () => {
  const { user } = useContext(UserContext);
  const [showDepartmentSelector, toggleDepartmentsSelector] = useState(false);
  const defaultDepartments = JSON.parse(localStorage.getItem(HR_DEPARTMENTS) || "[]");
  const [departments, setDepartments] = useState<DepartmentType[]>(
    defaultDepartments.length
      ? defaultDepartments
      : user
      ? user.departments.filter((dep) => dep !== "HR" && dep !== "ADMIN")
      : []
  );

  const tabs = departments.map((department: DepartmentType) => ({
    tab: DEPARTMENTS.find((dep: Department) => dep.value === department)?.label,
    value: DEPARTMENTS.find((dep: Department) => dep.value === department)?.value,
    deleteable: !(user && user.departments.find((dep: DepartmentType) => dep === department)),
    body: <ConsultantManagement department={department} />
  }));

  const deleteTab = (department: DepartmentType) => {
    const newDepartments = departments.filter((dep: DepartmentType) => dep !== department);
    localStorage.setItem(HR_DEPARTMENTS, JSON.stringify(newDepartments));
    setDepartments(newDepartments);
  };

  const { Form, values } = useForm();

  return (
    <div className="page-padding">
      <Helmet>
        <title>FEP: Consultant Management</title>
      </Helmet>
      <Tabs tabs={tabs} addTab={() => toggleDepartmentsSelector(true)} onDelete={deleteTab} />
      <Modal
        open={showDepartmentSelector}
        onClose={() => toggleDepartmentsSelector(false)}
        onSubmit={() => {
          const newDepartments = [...departments, ...values.departments];
          localStorage.setItem(HR_DEPARTMENTS, JSON.stringify(newDepartments));
          setDepartments(newDepartments);
          toggleDepartmentsSelector(false);
        }}
        completeDisabled={!values.departments?.length}
      >
        <Form>
          <DepartmentsSelector
            label="Select additional departments"
            mapper={(value) => value.value}
            exclusions={departments}
          />
        </Form>
      </Modal>
    </div>
  );
};

export default HRDashboard;
