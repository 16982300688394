import { flatten, groupBy, sortBy } from "lodash";
import {
  Education,
  EducationPricing,
  EducationTicketOptionRequirementType
} from "./education";
import User from "./user";

export type BookingState =
  | "HIDDEN"
  | "IN_APPROVAL"
  | "TICKET_HANDLING"
  | "DONE";

export interface Bookings {
  PENDING_APPROVAL: Booking[];
  TICKET_HANDLING: Booking[];
  DONE: Booking[];
}

export interface BookingUser {
  id: string;
  name: string;
  photoUrl?: string;
  approved: boolean;
  department: string;
}

export interface Booking {
  id: string;
  extraInfo: string;
  state: BookingState;
  tickets: BookingTicket[];
  educationId: string;
  educationName: string;
  location?: string;
  start?: Date;
  end?: Date;
  educationYear: number;
  ticketOption: string;
  organisingDepartment: string;
  users: BookingUser[];
}

const bookingTypeLabels = {
  OTHER: "Other",
  ACCOMMODATION: "Hotel",
  ENTRY: "Tickets",
  VENUE: "Venue",
  TRANSPORT: "Transport"
};

export const getBookingTypeLabel = (
  type: EducationTicketOptionRequirementType
) => {
  return bookingTypeLabels[type];
};

export type BookingTicketState = "UNORDERED" | "ORDERING" | "ORDERED";

export interface BookingTicket {
  type: EducationTicketOptionRequirementType;
  state: BookingTicketState;
  bookBefore: Date;
}

export interface TicketOption {
  name: string;
  registrationDeadline: Date;
  price: EducationPricing;
  state: string;
  users: User[];
  education: Education;
}

export const groupByTicketOption = (bookings: Booking[]) => {
  return groupByField(bookings, "ticketOption");
};

export const groupByEducation = (bookings: Booking[]) => {
  return groupByField(bookings, "educationName");
};

export const flattenUsers = (bookings: Booking[]): BookingUser[] => {
  return flatten(bookings.map((booking: Booking) => booking.users));
};

const groupByField = (bookings: Booking[], grouping: string) => {
  const value = Object.entries(groupBy(bookings, grouping));

  return sortBy(value, ["1.organisingDepartment", "1.educationName"]);
};
