import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
  faBriefcase,
  faDumbbell,
  faProjectDiagram
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-form";
import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { AsyncTypeahead } from "../../components/Form";
import PageHeader from "../../components/PageHeader";
import UserContext from "../../context/user";
import { getDepartmentLabel } from "../../domain/department";
import { getFunctionCategoryLabel } from "../../domain/function-category";
import { searchTags } from "../../services/tag";

import "./settings.scss";
import { Button } from "@axxes/design-system";
import { updatePreferences } from "../../services/preferences";
import messageService from "../../services/message.service";

const Settings: React.FunctionComponent = () => {
  const { user, updateUserContext } = useContext(UserContext);
  const [interests, setInterests] = useState<string[]>(
    user?.preferences.preferredTags || []
  );
  const [isDirty, setIsDirty] = useState(false);
  const { Form } = useForm();

  const updateInterests = (value: any) => {
    setInterests(value);
    setIsDirty(true);
  };

  const savePreferences = async () => {
    try {
      await updatePreferences(user?.uuid, interests);
      setIsDirty(false);
      const updatedUser: any = {
        ...user,
        preferences: {
          preferredTags: interests
        }
      };
      updateUserContext(updatedUser);
      messageService.success("Successfully updated your account preferences");
    } catch (e) {
      messageService.error(
        `Something went wrong while updating your preferences`
      );
    }
  };

  return (
    <div className="page-padding settings-page">
      <Helmet>
        <title>FEP: Settings</title>
      </Helmet>
      <PageHeader>
        <></>
      </PageHeader>
      <main>
        <aside>
          <h2>About</h2>

          <div className="extra-user-info">
            <div className="user-info-block roles">
              <span className="icon">
                <FontAwesomeIcon icon={faBriefcase} />
              </span>
              <div className="values">
                {user?.roles.map((role) => (
                  <span className="value">{role}</span>
                ))}
              </div>
            </div>
            <div className="user-info-block departments">
              <span className="icon">
                <FontAwesomeIcon icon={faProjectDiagram} />
              </span>
              <div className="values">
                {user?.departments.map((dep) => (
                  <span className="value">{getDepartmentLabel(dep)}</span>
                ))}
              </div>
            </div>
            <div className="user-info-block">
              <span className="icon">
                <FontAwesomeIcon icon={faDumbbell} />
              </span>
              <a>{getFunctionCategoryLabel(user?.functionCategory)}</a>
            </div>
            <div className="user-info-block">
              <span className="icon">
                <FontAwesomeIcon icon={faEnvelope} />
              </span>
              <a className="values">{user?.email || "-"}</a>
            </div>
          </div>
        </aside>
        <article>
          <h2>Settings</h2>
          <div className="content">
            <Form>
              <AsyncTypeahead
                label="Interests"
                name="tags"
                onSearch={searchTags}
                multiple={true}
                clearButton={true}
                defaultSelected={interests}
                allowNew={false}
                placeholder="Interests"
                onChangeHandler={updateInterests}
              />
              <p>
                These interests will be used to specify the educations you
                receive during the <b>weekly update mails</b>.
              </p>
            </Form>
            <Button accent={true} disabled={!isDirty} onClick={savePreferences}>
              Save
            </Button>
          </div>
        </article>
      </main>
    </div>
  );
};

export default Settings;
