import React from "react";
import User from "../domain/user";

export interface UserContextType {
  user?: User;
  updateUserContext: (user: User) => void;
}

const UserContext = React.createContext<UserContextType>({
  updateUserContext: (user: User) => {}
});

export default UserContext;
