import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faStar as solid } from "@fortawesome/free-solid-svg-icons";
import { faStar as empty } from "@fortawesome/free-regular-svg-icons";

interface Props {
  starred?: boolean;
  onChange?: (value: boolean) => void;
}

import "./star.scss";

const Star: React.FunctionComponent<Props> = ({
  starred = false,
  onChange = (val: boolean) => val
}) => {
  const toggle = () => {
    return onChange(!starred);
  };
  return (
    <span className={`axxes-star ${starred ? "starred" : ""}`} onClick={toggle}>
      <FontAwesomeIcon icon={solid} className="solid" title="star" />
      <FontAwesomeIcon icon={empty} className="empty" title="unstar" />
    </span>
  );
};

export default Star;
