import http from "./http";

const BASE_URL = "/users";

const updatePreferences = (userId = "", preferredTags: string[]) => {
  return http.put(`${BASE_URL}/${userId}/preferences`, {
    preferredTags
  });
};

export { updatePreferences };
