import { AxiosResponse } from "axios";
import http from "./http";

const BASE_URL = "/tags";

const searchTags = (query: string): Promise<string[]> => {
  return http
    .get<string[]>(`${BASE_URL}/search?part=${query}`)
    .then(({ data }: AxiosResponse<string[]>) => data);
};

export { searchTags };
