import toastr from "toastr";

const TOAST_DURATION = 5000;

toastr.options = {
  positionClass: "toast-top-right",
  hideDuration: 300,
  timeOut: TOAST_DURATION,
  extendedTimeOut: 6000
};

class MessageService {
  toastr;

  constructor() {
    this.toastr = toastr;
  }

  success(message: string, overrides?: ToastrOptions) {
    this.toastr.success(message, undefined, overrides);
  }

  error(message: string, overrides?: ToastrOptions) {
    this.toastr.error(message, undefined, overrides);
  }

  warning(message: string, overrides?: ToastrOptions) {
    this.toastr.warning(message, undefined, overrides);
  }

  info(message: string) {
    this.toastr.info(message);
  }
}

export default new MessageService();
