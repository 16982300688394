import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import Routes from "./components/Routes";
import {
  getCookie,
  invalidateUser,
  refreshToken,
  REFRESH_TOKEN_COOKIE
} from "./services/user";

const App = () => {
  useEffect(() => {
    const redirectUrl = localStorage.getItem("fep-redirect-url");
    if (redirectUrl) {
      localStorage.removeItem("fep-redirect-url");
      location.href = redirectUrl;
    }
    if (process.env.NODE_ENV === "production") {
      setInterval(() => {
        if (getCookie(REFRESH_TOKEN_COOKIE)) {
          refreshToken().catch(() => {
            invalidateUser();
            localStorage.removeItem("fep-redirect-url");
            location.href = "/login";
          });
        }
      }, 3300000);
    }
  }, []);

  return (
    <Router>
      <div className="App">
        <Routes />
      </div>
    </Router>
  );
};

export default App;
