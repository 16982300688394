import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faEyeSlash,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import formatDate from "../../utils/date";

import Location from "../Location";

import { Education, EDUCATION_TYPES } from "../../domain/education";

import AxxesPointLogo from "../AxxesPointLogo";
import "./education-detail.scss";
import { getDepartment } from "../DepartmentsSelector";
import Website from "../Website";
import axxes from "../../assets/images/axxes_black.svg";
import Star from "../Star";
import UserContext from "../../context/user";
import Markdown from "../Markdown";

interface Props {
  education: Education;
  enrolled?: boolean;
  enableStar?: boolean;
  onStar?: (value: boolean) => void;
}

const EducationDetail: React.FunctionComponent<Props> = ({
  education,
  enrolled = false,
  enableStar = false,
  onStar = (val: boolean) => val
}) => {
  const { user } = useContext(UserContext);

  const bookmarked = (): boolean => {
    if (user && education) {
      return !!user.bookmarks.find((bm) => bm.educationId === education.uuid);
    }
    return false;
  };

  return (
    <div className="education-detail">
      <div className="axxes-card">
        <div className="axxes-card__header">
          <div className="education-detail__header">
            <div className="education-detail__header-content">
              <img src={education.imageUrl || axxes} alt="Logo" />
              <div className="title-bookmark">
                <h1>
                  {education.name} {education.year}
                  {education.state === "UNPUBLISHED" ? (
                    <FontAwesomeIcon
                      icon={faEyeSlash}
                      title="This education is not published"
                    />
                  ) : null}
                  {enrolled ? (
                    <FontAwesomeIcon
                      className="enrolled-status"
                      icon={faCheckSquare}
                      title="You are enrolled for this education"
                    />
                  ) : null}
                </h1>
                {enableStar ? (
                  <Star onChange={onStar} starred={bookmarked()} />
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="axxes-card-content">
          <div className="education-extra-info">
            {education.tutor ? (
              <i className="education-tutor"> By {education.tutor}</i>
            ) : null}
            {education.maxAttendees ? (
              <span className="education-attendees">
                <FontAwesomeIcon icon={faUser} />{" "}
                {education.attendees ? `${education.attendees}/` : ""}
                {education.maxAttendees}
              </span>
            ) : null}
          </div>
          {education.location ? (
            <div>
              <Location location={education.location} />
            </div>
          ) : null}
          {education.website ? (
            <div>
              <Website url={education.website} />
            </div>
          ) : null}
          {education.requirements?.length ? (
            <div className="education-requirements">
              <b>Prerequisites</b>
              {education.requirements.map((requirement, index) => (
                <div key={`${index}_requirement`} className="requirement">
                  {requirement}
                </div>
              ))}
            </div>
          ) : null}
          <div className="education-detail-tags">
            <div className="education-detail-tags__content">
              <div className="education-detail__header-departments">
                <h4>Department</h4>
                {(education.departments || []).map((department, index) => (
                  <span
                    className="axxes-badge department"
                    key={`${department}_${index}`}
                  >
                    {getDepartment(department)?.label}
                  </span>
                ))}
                {(education.departments || []).length === 0 && "-"}
              </div>
              <div className="education-detail__header-tags">
                <h4>Tags</h4>
                {(education.tags || []).map((tag, index) => (
                  <span className="axxes-badge" key={`${tag}_${index}`}>
                    {tag}
                  </span>
                ))}
                {(education.tags || []).length === 0 && "-"}
              </div>
              <div className="education-detail__header-course">
                <h4>Course type</h4>
                <span className={`education-type ${education.educationType}`}>
                  {EDUCATION_TYPES[education.educationType]}
                </span>
              </div>
            </div>
          </div>
          {education.ticketOptions?.length ? (
            <div className="education-detail-variants">
              <h4>Ticket options</h4>

              {education.ticketOptions.map((ticketOption) => (
                <div className="variant" key={ticketOption.name}>
                  <div className="content">
                    <h4>{ticketOption.name}</h4>
                    <div>
                      {ticketOption.start
                        ? formatDate(ticketOption.start) +
                          (ticketOption.start !== ticketOption.end &&
                          ticketOption.end
                            ? " - " + formatDate(ticketOption.end)
                            : "-")
                        : null}
                      <b>
                        {ticketOption.start ? " " : ""}
                        {ticketOption.price.halfDays
                          ? `(${ticketOption.price.halfDays} Half days)`
                          : ""}
                      </b>
                    </div>

                    {ticketOption.registrationDeadline ? (
                      <div className="latest-enrollment-variant">
                        Registration Deadline{" "}
                        <b>{formatDate(ticketOption.registrationDeadline)}</b>
                      </div>
                    ) : null}
                  </div>
                  <div className="variant__credits">
                    <b style={{ marginRight: "5px" }}>
                      {ticketOption.price.overruledApPrice ||
                        ticketOption.price.credits}
                    </b>
                    <AxxesPointLogo height="small" />
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="education-detail-variants">
              <i>No Ticket options available</i>
            </div>
          )}
          <div className="education-detail-description">
            <h4>Extra Info</h4>
            <Markdown>{education.description}</Markdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationDetail;
