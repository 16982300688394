import React, { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import "./login.scss";

import logo from "../../logo.svg";
import axxes from "../../axxes.svg";

const DevelopmentLogin: React.FunctionComponent<{}> = () => {
  const [userId, setUserId] = useState("");
  const navigate = useNavigate();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    localStorage.setItem("dev_userId", userId);
    navigate("/dashboard");
  };

  return (
    <form className="form login-form page-padding" onSubmit={handleSubmit}>
      <div className="form__input-group">
        <label htmlFor="userId">
          User ID
          <select
            defaultValue=""
            id="userId"
            className="form-control"
            onChange={(event) => setUserId(event.target.value)}
          >
            <option value="" disabled>
              Select user
            </option>
            <option value="javacoach">java coach</option>
            <option value="javaconsultant">java consultant</option>
            <option value="dotnetconsultant">.NET consultant</option>
            <option value="hrperson">HR Person</option>
            <option value="admin">Admin</option>
          </select>
        </label>
      </div>
      <button type="submit" className="axxes-button --color-accent">
        login
      </button>
    </form>
  );
};

const DefaultLogin: React.FC<{}> = () => {
  if (process.env.NODE_ENV === "production") {
    location.href = "./api/oauth2/authorization/azure";
    return null;
  }
  return (
    <div className="login-page page">
      <Helmet>
        <title>FEP Login</title>
      </Helmet>
      <div className="login-container">
        <div className="login-container__block login-container__title">
          <img src={logo} alt="fep" />
          <div>
            <h1>Flex Education Plan</h1>
            <h3>Create your own career!</h3>
          </div>
        </div>
        <div className="login-container__block login-container__login">
          <div>
            Powered by
            <img src={axxes} alt="Axxes" className="axxes-logo" />
            <DevelopmentLogin />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultLogin;
