import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import Loader from "../../../components/Loader";
import { Education } from "../../../domain/education";
import { WaitinglistUser } from "../../../domain/user";
import compareValue from "../../../utils/compare";

import "./waiting-list.scss";
import { getEducation } from "../../../services/education";
import { useParams } from "react-router-dom";

const sortConsultants = (consultants: WaitinglistUser[]): WaitinglistUser[] => {
  const sortedConsultants = [...consultants];
  sortedConsultants.sort((a, b) =>
    compareValue(a.name.toLowerCase(), a.name.toLowerCase())
  );
  return sortedConsultants;
};

const EducationWaitingList: React.FunctionComponent<{}> = () => {
  const educationId = `${useParams().educationId}`;
  const [loading, setLoading] = useState(false);
  const [education, setEducation] = useState<Education>();

  async function fetchEducation() {
    setLoading(true);
    try {
      const response = await getEducation(educationId);
      setEducation(response);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchEducation();
  }, [educationId]);

  return (
    <div className="page education-waiting-list page-padding">
      <Helmet>
        <title>FEP: {education?.name || ""} enrollments</title>
      </Helmet>
      <div className="page-container">
        <Loader loading={loading} />
        {!loading && education ? (
          <div className="education-waiting-content">
            <div className="education-waiting-header">
              <h1>{education.name}</h1>
            </div>
            <p>
              Below you can find all users who are on the waiting list for:
              <b>{education.name}</b>.
            </p>
            <p>
              Total Users waiting: <b>{education.waitingList.users.length}</b>
            </p>
            <div className="education-waitinglist-container">
              {sortConsultants(education.waitingList.users).map(
                (user, index: number) => (
                  <div className="waiting-user" key={`user_${index}`}>
                    <div className="content">
                      <h4>{user.name}</h4>
                    </div>
                  </div>
                )
              )}
              {education.waitingList.users.length === 0 ? (
                <i>No users yet subscribed for the waiting list</i>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EducationWaitingList;
