import React, { useContext } from "react";

import { hasRole, ROLES } from "../../services/user";
import UserContext from "../../context/user";
import ConsultantDashboard from "./consultant";
import NotFound from "../404";
import Administration from "../Administration";
import HRDashboard from "./hr";

const Dashboard: React.FunctionComponent = (): any => {
  const { user } = useContext(UserContext);

  if (hasRole(user, [ROLES.COACH, ROLES.CONSULTANT])) {
    return <ConsultantDashboard />;
  }
  if (hasRole(user, [ROLES.HR])) {
    return <HRDashboard />;
  }
  if (hasRole(user, [ROLES.ADMIN])) {
    return <Administration />;
  }
  return <NotFound />;
};

export default Dashboard;
