import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

import "./location.scss";

interface Props {
  location: string;
}

const LocationMarker: React.FunctionComponent<Props> = ({ location }) => {
  return location ? (
    <span className="location">
      <FontAwesomeIcon icon={faMapMarkerAlt} />
      {location}
    </span>
  ) : null;
};

export default LocationMarker;
