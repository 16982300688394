import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useRef } from "react";

import "./option-menu.scss";

interface Option {
  label: any;
  action: () => void;
}

interface Props {
  options: Option[];
  children?: any;
}

const OptionMenu: React.FunctionComponent<Props> = ({ options, children }) => {
  const [open, toggle] = useState(false);
  const wrapper = useRef(null);

  return (
    <span className="option-menu" ref={wrapper}>
      <button
        className="axxes-button--subtle"
        type="button"
        onClick={toggle.bind(this, !open)}
        onBlur={() => {
          setTimeout(() => {
            toggle(false);
          }, 200);
        }}
      >
        {children ? children : <FontAwesomeIcon icon={faEllipsisV} />}
      </button>
      {open && (
        <div className="options">
          {options
            .filter((option) => !!option)
            .map((option: Option, index: number) => (
              <div
                key={`option_${index}`}
                className="option"
                onClick={() => {
                  toggle(false);
                  option.action();
                }}
              >
                {option.label}
              </div>
            ))}
        </div>
      )}
    </span>
  );
};

export default OptionMenu;
